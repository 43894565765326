/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {AxiosError, AxiosResponse} from "axios"
import ApiService from "../../../../services/ApiService"
import {SSOTeacherStaff} from "./components/SSOTeacherStaffTypes";

export default class SSOTeacherStaffApi {
    static getAllByDistrictSsoId(ssoSystemId: string): Promise<SSOTeacherStaff[]> {
        const url: string = `${ApiService.API_URL}sso-teacher-staff/list-all-by-district-sso-id/${ssoSystemId}`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: AxiosError) => {
                    reject(err)
                }
            )
        })
    }

    static addPermissionById(permission: string, id: string): Promise<boolean> {
        const url: string = `${ApiService.API_URL}sso-teacher-staff/add-permission/${permission}/${id}`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: AxiosError) => {
                    reject(err)
                }
            )
        })
    }

    static removePermissionById(permission: string, id: string): Promise<boolean> {
        const url: string = `${ApiService.API_URL}sso-teacher-staff/remove-permission/${permission}/${id}`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: AxiosError) => {
                    reject(err)
                }
            )
        })
    }

}
