import {observable} from "mobx"
import ApiService from "../../../services/ApiService"

export interface ContentAreaApiResponse {
    contentAreas: ContentArea[],
    error: boolean,
    errorMessage: string | null
}
export interface ContentArea {
    uuid: string,
    name: string,
    contentAreaId: number
    longDisplayName: string,
    shortDisplayName: string,
    created: Date
    avantTestCodeAbbreviation: string,
    uiContentAreaDisplayName: string,
    uiLanguageDisplayName: string,
    updated:Date
}
export class ContentAreaStore {

    @observable contentAreas: ContentArea[] = []

    @observable loading: boolean = false

    fetchContentAreas = async () => {
        this.loading = true
        const response = await ApiService.getContentAreas()
        this.contentAreas = response.contentAreas
        this.loading = false
    }

    convertContentAreaIdToLanguageName = (contentAreaId: number): string => {
        if (this.loading) {
            return ""
        }

        return this.contentAreas.find(contentArea => contentArea.contentAreaId === contentAreaId)!.uiLanguageDisplayName
    }

}

export const contentAreaStore = new ContentAreaStore()
