/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

/**
 * This code is based on the examples in the MUI X Data Grid Pro documentation found here: https://v6.mui.com/x/react-data-grid/editing/
 * **/

import React, {useEffect} from "react"
import {Box, Button} from "@mui/material"
import {
    DataGridPro,
    GridColDef,
    GridToolbar,
    GridRowId,
    GridRowModesModel,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowModes,
    GridActionsCellItem,
} from "@mui/x-data-grid-pro"
import DevToolsApiService from "../DevToolServices/DevToolsApiService"
import CreateSamlIdpConfigModal from "./CreateSamlIdpConfigModal"
import {Tooltip} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import { messageStore } from "../../../../app/common/messages/MessageStore"
import ConfirmationModal from "../../../ConfirmationModal/ConfirmationModal"
export interface SamlIdpConfigRow {
    isNew: boolean, 
    id: string,
    entityID: string,
    metadataURL: string | null,
    metaDataXml: string | null,
    active: boolean,
    ssoId: string,
}

const SamlIdpConfigTool: React.FC = () => {

    const [rows, setRows] = React.useState<SamlIdpConfigRow[]>([])
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const [openCreateModal, setOpenCreateModal] = React.useState<boolean>(false)
    const [isDeleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [deletingRowId, setDeletingRowId] = React.useState<GridRowId | null>(null);

    useEffect(() => {
        DevToolsApiService.getSamlIdpConfig().then((data) => {
            setRows(data)
        })
    }, [])

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true
        }
    }
    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}})
    }
    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}})
    }

    const addNewSamlIdp = () => {
        setOpenCreateModal(true)
    }

    const closeAddNewSamlIdpModal = (newRow: SamlIdpConfigRow | null) => {
        if (newRow !== null) {
            const updatedRows = [...rows, newRow]
            setRows(updatedRows)
        }
        setOpenCreateModal(false)
    }

    const handleDeleteClick = (id: GridRowId) => async () => {
        setDeletingRowId(id);
        setDeleteDialogOpen(true);
    }

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true}
        })
        const editedRow = rows.find((row) => row.id === id)
        if (editedRow !== null && editedRow !== undefined && editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id))
        }
    }

    const handleConfirmDelete = (confirm: boolean) => {
        if (confirm && deletingRowId !== null) {
            DevToolsApiService.deleteSamlIdpConfig(deletingRowId.toString())
                .then(() => {
                    setRows(rows.filter((row) => row.id !== deletingRowId));
                    messageStore.setInfoMessage('Entry deleted successfully');
                })
                .catch((error) => {
                    messageStore.setErrorMessage('Failed to delete entry');
                })
                .finally(() => {
                    setDeleteDialogOpen(false);
                    setDeletingRowId(null);
                });
        } else {
            setDeleteDialogOpen(false);
            setDeletingRowId(null);
        }
    };
    const processRowUpdate = (newRow: SamlIdpConfigRow, oldRow: SamlIdpConfigRow | undefined) => {
        if (!oldRow) return Promise.resolve(newRow);
        setRows(rows.map((row) => (row.id === newRow.id ? { ...newRow, isNew: false } : row)));
        return DevToolsApiService.editSamlIdpConfig(newRow)
            .then(() => {
                messageStore.setInfoMessage(`Row edited successfully`);
                return newRow;
            })
            .catch((error) => {
                setRows(rows.map(row => (row.id === newRow.id ? { ...oldRow } : row)));
                throw error; 
            });
    }
    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel)
    }


    const columns: GridColDef[] = [
        {
            field: 'samlServiceId',
            headerName: 'ID',
            type: 'string',
            width: 100,
            hideable: false,
            editable: false
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            sortable: false,
            hideable: false,
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id] && rowModesModel[id].mode === GridRowModes.Edit
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            label={"Save"}
                            icon={<SaveIcon />}
                            sx={{color: "primary.main"}}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            label={"Cancel"}
                            icon={<CancelIcon />}
                            sx={{color: "primary.main"}}
                            onClick={handleCancelClick(id)}
                        />,
                    ]
                }
                return [
                    <GridActionsCellItem
                        label={"Edit"}
                        icon={
                            <Tooltip title="Edit" placement="top">
                                <EditIcon />
                            </Tooltip>
                        }
                        sx={{color: "primary.main"}}
                        onClick={handleEditClick(id)}
                    />,
                    <GridActionsCellItem
                        label={"Delete"}
                        icon={<DeleteIcon />}
                        sx={{color: "primary.main"}}
                        onClick={handleDeleteClick(id)}
                    />,
                ]
            }
        },
        {
            field: 'entityID',
            headerName: 'SAML Service ID',
            type: 'string',
            width: 200,
            editable: true
        },
        {
            field: 'ssoEntityId',
            headerName: 'SSO System ID',
            type: 'string',
            width: 200,
            editable: false
        },
        {
            field: 'active',
            headerName: 'Active',
            type: 'boolean',
            width: 100,
            editable: true
        },
        {
            field: 'metaDataUrl',
            headerName: 'Metadata URL',
            width: 200,
            editable: true
        },
        {
            field: 'metaDataXml',
            headerName: 'Metadata XML',
            width: 200,
            editable: true
        }

    ]

    return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                    <h1>SAML IDP Configuration Tool</h1>
                    <Button variant={"contained"} color={"primary"} onClick={addNewSamlIdp}>Add SAML IDP</Button>
                </div>
                <Box
                    sx={{
                        height: 500,
                        width: '100%',
                        '& .actions': {
                            color: 'text.secondary',
                        },
                        '& .textPrimary': {
                            color: 'text.primary',
                        },
                    }}
                >
                    <CreateSamlIdpConfigModal dialogOpen={openCreateModal} closeDialog={closeAddNewSamlIdpModal}/>
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        editMode={"row"}
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        columnVisibilityModel={{
                            id: false
                        }}
                        slots={{
                            toolbar: GridToolbar
                        }}
                        slotProps={{
                            toolbar: { setRows, setRowModesModel },
                        }}
                    />
                </Box>
                <ConfirmationModal
            showModal={isDeleteDialogOpen}
            modalTitle="Confirm Delete"
            yesClicked={() => handleConfirmDelete(true)}
            noClickedOrClose={() => handleConfirmDelete(false)}
        />
            </div>
        )
}

export default SamlIdpConfigTool