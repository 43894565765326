import ApiService from "../../../services/ApiService";
import * as React from "react";
import MaterialTable, {Column} from "material-table";
import {useRef, useState} from "react";
import {messageStore} from "../../../app/common/messages/MessageStore";
import { contentAreaStore } from "../../../app/common/contentArea/ContentAreaStore";


function buildSort(orderByField: string | unknown, orderDirection: string): string {
    let sortString = ""
    if (orderByField) {
        sortString = `&sort=${orderByField},${orderDirection}`
    }
    return sortString
}

function buildPredicate(filters: any): string {
    const predicateArray: string[] = []
    filters.forEach((filter: any) => {
        if (filter.column.field === "name") {
            predicateArray.push(`name=contains(${filter.value})`)
        }
        if (filter.column.field === "friendlyname") {
            predicateArray.push(`friendlyname=contains(${filter.value})`)
        }
    })
    if (predicateArray.length === 0) {
        return ""
    }
    return `&${predicateArray.join("&")}`
}

async function fetchP360Messages(query: any) {
    const sort = (query.orderBy) ? buildSort(query.orderBy.field, query.orderDirection) : ""
    const predicate = (query.filters) ? buildPredicate(query.filters) : ""

    const response = await ApiService.get(`${ApiService.API_URL}rest/panels?page=${query.page}&size=${query.pageSize}${sort}${predicate}`)

    const wallMessageData = response.data._embedded.panels
    return {
        data: wallMessageData,
        page: response.data.page.number,
        totalCount: response.data.page.totalElements
    }
}

export const PanelAdminBase: React.FC = () => {
    const tableRef = React.createRef()
    const isCanceled = useRef(false)

    const patchRow = (patchLink: string , data: {}) => {
        ApiService.patch(patchLink, data)
            .then(_ => {
                if (!isCanceled.current) {
                    // @ts-ignore
                    if (tableRef.current) { tableRef.current.onQueryChange() }
                    messageStore.setInfoMessage("Updated Row")
                }
            })
            .catch(e => {if (!isCanceled.current) {messageStore.setErrorMessage(e.toString())}})
    }

    let initialColumns: Column<any>[]
    initialColumns = [
        {
            title: "Title", field: "name", sorting: true, searchable: true, removable: false,
        },
        {
            title: "Test Engine",
            field: "testengine",
            hidden: true,
            sorting: false,
            searchable: true,
            removable: true,
        },
        {
            title: "Language",
            field: "contentareaid",
            sorting: true,
            searchable: true,
            filtering: false,
            removable: false,
            render: (rowData: any) => contentAreaStore.convertContentAreaIdToLanguageName(rowData.contentareaid),
        },
        {title: "Create Date", field: "createdate", hidden: true, sorting: false, searchable: false, removable: true,},
        {
            title: "Last Touched",
            field: "lasttouched",
            type: "datetime",
            hidden: true,
            sorting: false,
            searchable: false,
            filtering: false,
            removable: true,
            editable: "never"
        },
        {
            title: "Product Id",
            field: "productid",
            hidden: true,
            sorting: false,
            searchable: false,
            removable: true,
        },
        {title: "isActive", field: "active", type: "boolean", sorting: false, searchable: false, filtering: false, removable: true,},
        {title: "Self-Evaluation", field: "selfEvaluation", type: "boolean", sorting: false, searchable: false, filtering: false, removable: true,},
        {title: "FriendlyName", field: "friendlyname", sorting: true, searchable: true, removable: true,},
        {title: "Skill", field: "skill", sorting: true, searchable: true, removable: true,},
        {title: "Phase", field: "phase", sorting: false, searchable: false, removable: true,},
        {title: "updatedWrSp", field: "updatedWrSp", type: "boolean", hidden: true, sorting: false, searchable: false, filtering: false, removable: true,},
    ]
    const [columns] = useState(initialColumns)

    return (
        <>
            <MaterialTable
                tableRef={tableRef}
                title="Panel Graphs"
                columns={columns}
                data={fetchP360Messages}
                options={{
                    filtering: true,
                    search: true,
                    showTitle: true,
                    toolbar: true,
                    padding: "dense",
                    pageSize: 100,
                    pageSizeOptions: [100, 200, 500, 1000],
                    exportButton: true,
                    columnsButton: true,
                    debounceInterval: 500,
                    exportFileName: "Panel Graphs",
                    tableLayout: "auto",
                }}
                cellEditable={{
                    onCellEditApproved: (newValue: any, oldValue: any, rowData: any, columnDef: any) => {
                        return new Promise((resolve, reject) => {
                            const updateField = {}
                            updateField[columnDef.field] = newValue
                            patchRow(rowData._links.self.href, updateField)
                        })
                    }
                }}

            />
        </>
    )
}

export const PanelAdmin = React.memo(PanelAdminBase)
