import {
    Checkbox,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel
} from "@material-ui/core"
import {MuiThemeProvider} from "@material-ui/core/styles"
import {TableCellProps} from "@material-ui/core/TableCell"
import {Assessment, Edit} from "@material-ui/icons"
import {camelCase as lodashCamelCase, orderBy as lodashOrderBy, startCase as lodashStartCase} from "lodash"
import {observable} from "mobx"
import {observer} from "mobx-react"
import * as React from "react"
import {Fragment, MouseEvent, ReactElement} from "react"
import {AvantColors} from "../../../../styles/AvantColors"
import {SmallBodyText} from "../../../../styles/AvantTypography"
import {theme} from "../../../../styles/MuiThemes"
import {
    LoginDTO,
    P360ExamData,
    PanelLevel,
    PanelSkillEnum,
    PanelSkillOrder,
    SortDirection
} from "../../../../types/types"
import {
    PLACE_PRODUCT_ID,
    ProctorTypes,
    READY_PRODUCT_ID,
    SHL_CONTENT_AREA_ID,
    WORLDSPEAK_PRODUCT_ID
} from "../../../../util/Constants"
import LanguageUtils from "../../../../util/LanguageUtils"
import {TimeUtil} from "../../../../util/TimeUtil"
import {productStore} from "../../../common/products/ProductStore"
import {
    AvantBadgeEarlyExit,
    AvantBadgeNotRatable,
    AvantBadgeNotStarted,
    AvantBadgeScoredByEducator,
    AvantBadgeScoringInProgress,
    AvantBadgeSectionInProgress
} from "../../../generic-components/AvantBadge"
import {AvantVerticalDivider} from "../../../generic-components/AvantVerticalDivider"
import {UnstyledLink} from "../../../generic-components/UnstyledLink"
import {ReportFilters} from "../../common/models/ReportFilters"
import {reportsStore} from "../../common/stores/ReportStore"
import {ReportSubPaths} from "../../ReportSubPaths"
import {MAX_DISPLAYABLE_TEST_DURATION, ReportUtils} from "../../ReportUtils"
import {ReportTakeRow} from "../models/ReportTakeRow"
import {TableDataResults} from "../models/TableDataResults"
import {sidebarStore} from "../stores/SideBarStore"
import {TestTakerSidebarContainer} from "./test-taker-sidebar/TestTakerSidebar"
import {GroupType} from "../../../common/group/models/GroupMaster"
import {findPLACEIndividualReport} from "../../PlaceFamily/PLACEReportPage"
import {GroupInfo} from "../../../common/group/models/GroupInfo"
import TestTakerEdit, {TestTakerEditData} from "../../../adminClientSupportTool/TestTakerEdit"
import {placeSkillToFriendlyName, shlSkillToFriendlyName} from "../../PlaceFamily/PLACEIndividualReportPage"
import { contentAreaStore } from "../../../common/contentArea/ContentAreaStore"

interface HeaderRow {
    id: keyof ReportTakeRow
    label: string
    align?: "left"
    width?: number
}

interface EnhancedTableProps {
    numSelected: number
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ReportTakeRow) => void
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
    order: SortDirection
    orderBy: string
    rowCount: number
    headers: HeaderRow[]
    leftHeader: boolean
    printing?: boolean
    isTestTakerEditingAllowed: boolean
}

const staticTableHeaderRows: HeaderRow[] = [
    {id: "lastName", label: "Last Name", align: "left"},
    {id: "firstName", label: "First Name", align: "left"}
]

const EnhancedTableHead = (props: EnhancedTableProps) => {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headers, leftHeader, printing, isTestTakerEditingAllowed} = props
    const createSortHandler = (property: keyof ReportTakeRow) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
    }

    return (
        <MuiThemeProvider theme={theme}>
            <TableHead>
                <TableRow>
                    {leftHeader && !printing && (
                        <>
                            {
                                productStore.loginProduct!!.userType === GroupType.CUSTOMER &&
                                <TestTakerTableCell
                                    sortDirection={orderBy === "location" ? order : false}
                                    align={"left"}
                                >
                                    <TableSortLabel
                                        active={orderBy === "location"}
                                        direction={order}
                                        onClick={createSortHandler("location")}
                                        href={""}
                                        style={{fontSize: "1.5rem"}}
                                    >
                                        Location
                                    </TableSortLabel>
                                </TestTakerTableCell>
                            }
                            <TestTakerTableCell style={{paddingLeft: theme.spacing(2)}}>
                                <Checkbox
                                    checked={numSelected === rowCount}
                                    onChange={onSelectAllClick}
                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                    inputProps={{"aria-labelledby": "Select all test-takers"}}
                                    color={"primary"}

                                />
                            </TestTakerTableCell>
                            <TestTakerTableCell style={{textAlign: "center"}}>
                                Report
                            </TestTakerTableCell>
                            {
                            isTestTakerEditingAllowed &&
                            <TestTakerTableCell style={{textAlign: "center"}}>
                                Edit
                            </TestTakerTableCell>
                            }
                        </>
                    )}
                    {headers.map((row) => {
                        return (
                            <TestTakerTableCell
                                key={row.id}
                                sortDirection={orderBy === row.id ? order : false}
                                align={row.align}
                                >
                                <TableSortLabel
                                    active={orderBy === row.id}
                                    direction={order}
                                    onClick={createSortHandler(row.id)}
                                    href={""}
                                    style={{fontSize: "1.5rem"}}
                                >
                                    {row.label}
                                </TableSortLabel>
                            </TestTakerTableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
        </MuiThemeProvider>
    )
}

interface TestTakerSortableTableProps {
    filters: ReportFilters
    data: TableDataResults
    printing?: boolean
    isMultiLanguage?: boolean
    groupInfo?: GroupInfo
    isTestTakerEditingAllowed: boolean
}

interface TestTakerSortableTableState {
    takeEditView: boolean
    currentTakeId?: number
    availableSkills: PanelSkill[]
}

export interface PanelSkill {
    symbol: string,
    name: string
}

export interface PanelGraphSkillsAndPackages {
    panelGraphPackage: string[],
    panelSkills: PanelSkill[]
}

@observer
export class TestTakerSortableTable extends React.Component<TestTakerSortableTableProps, TestTakerSortableTableState> {

    @observable
    order: SortDirection = "asc"

    @observable
    orderBy: keyof ReportTakeRow = "lastName"

    @observable
    selected: number[] = []

    @observable
    rows: ReportTakeRow[]

    @observable
    headerRow: HeaderRow[] = [{id: "resumeKey", label: "Login Name"}]

    wrapperRef: React.RefObject<HTMLInputElement>
    leftRef: React.RefObject<HTMLInputElement>
    rightRef: React.RefObject<HTMLInputElement>

    constructor(props: TestTakerSortableTableProps) {
        super(props)
        // references to left and right hover shadows of the results table
        this.leftRef = React.createRef()
        this.rightRef = React.createRef()
        this.wrapperRef = React.createRef()
        this.rows = props.data.reportTakeRows

        this.state = {
            takeEditView: false,
            currentTakeId: undefined,
            availableSkills: this.props.data.availableSkills
                .slice()
                .sort((a, b) => {
                        return PanelSkillOrder.indexOf(a.symbol as PanelSkillEnum) - PanelSkillOrder.indexOf(b.symbol as PanelSkillEnum)
                    }
                )
        }
    }

    isPlace = (): boolean => {
        return productStore.loginProduct != null && (
            productStore.loginProduct.productId === Number(PLACE_PRODUCT_ID)
            || productStore.loginProduct.productId === Number(READY_PRODUCT_ID)
            || productStore.loginProduct.productId === Number(WORLDSPEAK_PRODUCT_ID)
        )
    }

    buildHeaderRow = () => {
        const isPlaceFamily = this.isPlace()
        const {isMultiLanguage, groupInfo} = this.props

        if (groupInfo && groupInfo.loginList.some((row: LoginDTO) => row.proctorType === ProctorTypes.P360.valueOf())) {
            this.headerRow.push(
                {id: "examData", label: "Scheduled"}
            )
        }

        // Add language to the header row between the test takers names and resumekey if the class is multi-language,
        if (isMultiLanguage) {
            this.headerRow.unshift(
                {id: "language", label: "Language"}
            )
        // Add language to the right after the resumeKey if the class is PLACE
        } else if (isPlaceFamily) {
            this.headerRow.push(
                {id: "language", label: "Language"},
                {id: "candoAverage", label: "Self Evaluation"}
            )
        }

        // Add their respective headers to the header row
        this.state.availableSkills.forEach((skill: PanelSkill) => {
            const id = lodashCamelCase(skill.name) + "Score" as HeaderRow["id"]
            // Capitalize label
            let label = lodashStartCase(skill.name.toLowerCase())
            // Set label as `Lexical` for vocabulary
            if (skill.symbol === PanelSkillEnum.VOCABULARY) {
                label = "Lexical"
            }

            // Don't add speaking for SHL B and C
            const firstRow = this.rows[0]
            if (
                skill.symbol === PanelSkillEnum.SPEAKING &&
                (firstRow.product_package === "shl-b" || firstRow.product_package === "shl-c")
            ) {
                return
            } else {
                this.headerRow.push({id: id, label: label})
            }

            const hasScaledReadingScores = this.rows.some(row => row.readingScaledScore)
            const hasScaledListeningScores = this.rows.some(row => row.listeningScaledScore)

            // Add scaled reading after reading
            if (skill.symbol === PanelSkillEnum.READING && hasScaledReadingScores) {
                this.headerRow.push({id: "readingScaledScore", label: "Scaled Reading"})
            }
            // Add scaled listening after listening
            if (skill.symbol === PanelSkillEnum.LISTENING && hasScaledListeningScores) {
                this.headerRow.push({id: "listeningScaledScore", label: "Scaled Listening"})
            }
        })

        // Finish the header row
        this.headerRow.push(
            {id: "compositeScore", label: isPlaceFamily ? "Avant Score": "Composite Score"},
            {id: "startDate", label: "Start Date"},
            {id: "finishDate", label: "Finish Date"},
            {id: "testDuration", label: "Length"}
        )
    }

    handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.checked) {
            this.selected = this.rows.map((n) => n.takeId)
        } else {
            this.selected = []
        }
        reportsStore.selectedTakeIds = this.selected
    }

    sortRows = () => {
        // this.rows.slice() is because Mobx has some funky behavior around handling arrays, and the .slice ensures
        // the array is actually an array as per Nathan Westlake
        this.rows = lodashOrderBy(this.rows.slice(), [row => {
            let value = row[this.orderBy]
            if (typeof value === "string") {
                value = value.toLowerCase()
                // TODO: Add logic for sorting composite scores for place as they are strings to support * levels
            } else if (!value) {
                value = 0
            }
            return value
        }, "lastName", "firstName"], [this.order, "asc"])
    }

    handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ReportTakeRow) => {
        const isDesc = this.orderBy === property && this.order === "desc"
        this.order = isDesc ? "asc" : "desc"
        this.orderBy = property
        this.sortRows()
    }

    isSelected = (takeId: number) => this.selected.indexOf(takeId) !== -1


    handleScroll = (e: React.UIEvent<HTMLElement>) => {
            const element = e.currentTarget
            const left = this.leftRef.current!
            const right = this.rightRef.current!
            // left gradient opacity
            if (element.scrollLeft < 100) {
                left.style.opacity = (element.scrollLeft / 400).toString()
            } else {
                left.style.opacity = "0.25"
            }
            // right gradient opacity
            if ((element.scrollWidth - element.clientWidth - element.scrollLeft) < 100) {
                right.style.opacity = ((element.scrollWidth - element.clientWidth - element.scrollLeft) / 400).toString()
            } else {
                right.style.opacity = "0.25"
            }

    }

    handleClick = (event: React.MouseEvent<unknown>, takeId: number) => {
        const selectedIndex = this.selected.indexOf(takeId)
        let newSelected: number[] = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.selected, takeId)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.selected.slice(1))
        } else if (selectedIndex === this.selected.length - 1) {
            newSelected = newSelected.concat(this.selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.selected.slice(0, selectedIndex),
                this.selected.slice(selectedIndex + 1)
            )
        }
        this.selected = newSelected
        reportsStore.selectedTakeIds = this.selected
    }

    getBadgeOrScore = (
        scaledScoreCalc: boolean,
        skillValNullable?: string,
        scaledValNullable?: string,
        isPlaceC?: boolean,
        isSHL?: boolean
    ) => {
        const skillVal = skillValNullable == null ? "" : skillValNullable
        const scaledVal = scaledValNullable == null ? "" : scaledValNullable
        if ((skillVal === PanelLevel.PANEL_NOT_STARTED || skillVal === "") && scaledVal === "") {
            if (isSHL) {
                return <AvantBadgeEarlyExit/>
            } else {
                return <AvantBadgeNotStarted/>
            }
        } else if (skillVal === PanelLevel.PANEL_COMPLETE && isPlaceC) {
            return <AvantBadgeScoredByEducator/>
        } else if (skillVal === PanelLevel.PANEL_COMPLETE && scaledVal === "") {
            return <AvantBadgeScoringInProgress/>
        } else if (skillVal === PanelLevel.PANEL_STARTED && scaledVal === "") {
            return <AvantBadgeSectionInProgress/>
        } else if (skillVal === PanelLevel.PANEL_NOT_RATABLE && scaledVal === "") {
            return <AvantBadgeNotRatable/>
        } else if (scaledVal === "" && scaledScoreCalc) {
            return ""
        } else if (scaledVal !== "") {
            return parseInt(scaledVal)
        } else {
            return skillVal
        }

    }

    openSidebarWithId = (
        takeId: number,
        openP360: boolean,
        skill?: PanelSkillEnum,
        selfEval?: boolean,
        P360Data?: P360ExamData[]
    ) => {
        return () => {
            sidebarStore.takeId = takeId
            sidebarStore.openPanel = skill
            sidebarStore.openSelfEval = selfEval
            sidebarStore.openP360 = openP360
            sidebarStore.P360Data = P360Data
        }
    }

    rowDurationString = (row: ReportTakeRow) => {
        if (row.testDuration) {
            const durStr = (row.testDuration > MAX_DISPLAYABLE_TEST_DURATION) ?
                `${MAX_DISPLAYABLE_TEST_DURATION}+`
                :
                `${row.testDuration}`

            return `${durStr} mins`
        } else {
            return ""
        }
    }

    // Returns stringified data that will be passed down to
    // individual reports via the react link's state
    additionalReportData = (row: ReportTakeRow): string => {
        const productId = (productStore.loginProduct) ? productStore.loginProduct.productId : null
        switch (productId) {
            case PLACE_PRODUCT_ID.value():
                return findPLACEIndividualReport(row.takeId)
            default:
                return JSON.stringify(this.props.groupInfo)
        }
    }

    componentDidUpdate() {
        const element = this.wrapperRef.current!
        if ((element.scrollWidth - element.clientWidth - element.scrollLeft) === 0) {
            this.rightRef.current!.style.opacity = "0.00"
        } else {
            this.rightRef.current!.style.opacity = "0.25"
        }
    }


    // TODO: Handle this as it will not work with place
    componentDidMount(): void {
        if (reportsStore.latestClass === this.props.filters.groupId) {
            this.selected = reportsStore.selectedTakeIds
        } else {
            reportsStore.selectedTakeIds = []
            reportsStore.latestClass = this.props.filters.groupId
        }

        this.buildHeaderRow()
        this.sortRows()
    }

    handleRowUpdate = (saveResponse: TestTakerEditData): void => {
        // Get the index of the row that matches by takeId from the response.
        const rowIndex = this.rows.findIndex(row => row.takeId !== undefined && row.takeId === saveResponse.takeId)

        // Update the firstName, lastName, and resumeKey (loginName) with the corresponding values from the response.
        this.rows[rowIndex].firstName = (typeof(saveResponse.firstName) === 'string' ? saveResponse.firstName : '')
        this.rows[rowIndex].lastName  = (typeof(saveResponse.lastName)  === 'string' ? saveResponse.lastName  : '')
        this.rows[rowIndex].resumeKey = (typeof(saveResponse.loginName) === 'string' ? saveResponse.loginName : '')
    }

    renderScheduleDates = (examDataList: P360ExamData[], isFinished: boolean): ReactElement => {
        const now = Date.now()
        const asteriskForMultipleScheduledSessions = examDataList.length > 1 ? '*' : ''
        const tooltipForMultipleScheduledSessions = examDataList.length > 1 ? 'Click to see all scheduled sessions' : ''
        let latestScheduledSessionInPast: Date | null = null
        let earliestScheduledSessionInFuture: Date | null = null
        let scheduledDateRendered: ReactElement = <></>

        // We'll iterate through the scheduled proctor sessions to isolate the latest scheduled sessions in the past and the earliest in the future.
        examDataList.map((examData: P360ExamData) => {
            // If the scheduled proctor session is in the past...
            if (Number(examData.scheduledAt) < now) {
                // If the scheduled proctor session is later than the latest past session we've isolated so far...
                if (latestScheduledSessionInPast === null || Number(examData.scheduledAt) > Number(latestScheduledSessionInPast)) {
                    // Isolate it as the latest so far.
                    latestScheduledSessionInPast = examData.scheduledAt
                }
            } else {
                // If the scheduled proctor session is earlier than the earliest future session we've isolated so far.
                if (earliestScheduledSessionInFuture === null || Number(examData.scheduledAt) < Number(earliestScheduledSessionInFuture)) {
                    earliestScheduledSessionInFuture = examData.scheduledAt
                }
            }
        })

        if (isFinished) {
            // If we do not have a past session to display (which is unexpected, for a completed test instance)...
            if (latestScheduledSessionInPast === null) {
                // Render the earliest future session in a gray color.
                scheduledDateRendered = <span style={{ color: 'silver' }} title={tooltipForMultipleScheduledSessions}>{TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(Number(earliestScheduledSessionInFuture))}{asteriskForMultipleScheduledSessions}</span>
            }
            else {
                // Render the latest past session in a gray color.
                scheduledDateRendered = <span style={{ color: 'silver' }} title={tooltipForMultipleScheduledSessions}>{TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(Number(latestScheduledSessionInPast))}{asteriskForMultipleScheduledSessions}</span>
            }
        }
        else {
            // If we do not have a future session to display...
            if (earliestScheduledSessionInFuture === null) {
                // Render the latest past session in red.
                scheduledDateRendered = <span style={{ color: 'red' }} title={tooltipForMultipleScheduledSessions}>{TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(Number(latestScheduledSessionInPast))}{asteriskForMultipleScheduledSessions}</span>
            }
            else {
                // Render the earliest future session in the default color.
                scheduledDateRendered = <span title={tooltipForMultipleScheduledSessions}>{TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(Number(earliestScheduledSessionInFuture))}{asteriskForMultipleScheduledSessions}</span>
            }
        }

        return scheduledDateRendered
    }

    // given a report take row (with no schedules) return No if the proctor type is p360 and return an empty string if not
    getProctorType = (row: ReportTakeRow): string => {
        if (row.proctortype === ProctorTypes.P360.valueOf()) {
            return "No"
        }
        return ""
    }

    render() {
        const productId = (productStore.loginProduct) ? productStore.loginProduct.productId : null
        if (!productId) { return }

        const headers = this.headerRow
        const {filters, printing, isMultiLanguage} = this.props
        const placeVariations: string[] = ["plb", "plc"]
        const shlVariations: string[] = ["shl-b", "shl-c"]
        const isPlaceFamily = this.isPlace()
        let isPlaceC = false
        const hasScaledReadingScores = this.rows.some(row => row.readingScaledScore)
        const hasScaledListeningScores = this.rows.some(row => row.listeningScaledScore)
        const leftTableWidth = printing ? 280 : 424
        const useScrollEffectStyles: React.CSSProperties = {
                width: 25,
                height: "calc(100% - 140px)",
                position: "absolute",
                transition: "0.1s",
                pointerEvents: "none",
        }

        const openTestTakerEdit = (event: MouseEvent, takeId: number) => {
            this.setState({currentTakeId: takeId})
            this.setState({takeEditView: true})
        }

        const closeTestTakerEdit = () => {
            this.setState({takeEditView: false})
            this.setState({currentTakeId: undefined})
        }

        return (
            <>
                <div style={{display: "flex", overflowX: "hidden"}}>
                    <Table size={"small"} style={{width: leftTableWidth}}>
                        <EnhancedTableHead
                            numSelected={this.selected.length}
                            onRequestSort={this.handleRequestSort}
                            onSelectAllClick={this.handleSelectAllClick}
                            order={this.order}
                            orderBy={this.orderBy}
                            rowCount={this.rows.length}
                            headers={staticTableHeaderRows}
                            leftHeader={true}
                            printing={printing}
                            isTestTakerEditingAllowed={this.props.isTestTakerEditingAllowed}
                        />
                        <TableBody>
                            {
                                this.rows.map((row: ReportTakeRow, index: number) => {
                                    isPlaceC = row.product_package === "plc"
                                    const isItemSelected = this.isSelected(row.takeId)
                                    const labelId = `enhanced-table-checkbox-${index}`
                                    return (
                                        <TableRow
                                            hover={true}
                                            onClick={(event) => this.handleClick(event, row.takeId)}
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.takeId}
                                            selected={isItemSelected}
                                            style={{
                                                backgroundColor: (index % 2 === 0) ? AvantColors.REPORT_BACKGROUND_GRAY_DARK : "#FFFFFF"
                                            }}
                                        >
                                            {!printing && (
                                                <>
                                                    {
                                                        productStore.loginProduct!!.userType === GroupType.CUSTOMER &&
                                                        <TestTakerTableCell>
                                                            {row.location}
                                                        </TestTakerTableCell>
                                                    }
                                                    <TestTakerTableCell style={{paddingLeft: theme.spacing(2)}}>
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            inputProps={{"aria-labelledby": labelId}}
                                                            color={"primary"}
                                                        />
                                                    </TestTakerTableCell>
                                                    <TestTakerTableCell>
                                                        <UnstyledLink
                                                            to={{
                                                                pathname: ReportSubPaths.individual(row.takeId, filters),
                                                                state: {report: this.additionalReportData(row)}
                                                            }}
                                                            target={isPlaceFamily ? '_blank' : ''}
                                                        >
                                                            <IconButton
                                                                aria-label="Student Summary"
                                                            >
                                                                <Assessment/>
                                                            </IconButton>
                                                        </UnstyledLink>
                                                    </TestTakerTableCell>
                                                    {
                                                    this.props.isTestTakerEditingAllowed &&
                                                    <TestTakerTableCell>
                                                        <IconButton
                                                            aria-label="Edit Student"
                                                            onClick={((e) => openTestTakerEdit(e, row.takeId))}
                                                        >
                                                            <Edit/>
                                                        </IconButton>
                                                    </TestTakerTableCell>
                                                    }
                                                </>
                                            )}
                                            <TestTakerTableCell
                                                onClick={this.openSidebarWithId(row.takeId , row.proctortype === ProctorTypes.P360.valueOf(), undefined, false, row.examData)}
                                                centerContent={false}
                                                style={{textAlign: "left"}}
                                            >
                                                {row.lastName}
                                            </TestTakerTableCell>
                                            <TestTakerTableCell>
                                                {row.firstName}
                                            </TestTakerTableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                    <AvantVerticalDivider/>
                    <div style={{overflow: "auto",overflowY:"hidden"}}>
                    <div ref={this.wrapperRef} onScroll={this.handleScroll} style={{height:"10px"}} >

                        <div
                            ref={this.leftRef}
                            style={{
                                ...useScrollEffectStyles,
                                backgroundImage: "linear-gradient(90deg, rgba(0,0,0,0.43) 0%, rgba(255,255,255,0.00) 100%)",
                                opacity: 0
                            }}
                        />
                        <div
                            ref={this.rightRef}
                            style={{
                                right: 0,
                                ...useScrollEffectStyles,
                                backgroundImage: "linear-gradient(270deg, rgba(0,0,0,0.43) 0%, rgba(255,255,255,0.00) 100%)",
                                borderRadius: "0 0 6px 0",
                                opacity: 0.25
                            }}
                        />

                        <Table size={"small"}>

                            <EnhancedTableHead
                                numSelected={this.selected.length}
                                onRequestSort={this.handleRequestSort}
                                onSelectAllClick={this.handleSelectAllClick}
                                order={this.order}
                                orderBy={this.orderBy}
                                rowCount={this.rows.length}
                                headers={headers}
                                leftHeader={false}
                                isTestTakerEditingAllowed={this.props.isTestTakerEditingAllowed}
                            />

                            <TableBody>
                                {
                                    this.rows.map((row: ReportTakeRow, index: number) => {
                                        const language = LanguageUtils.removeTilde(row.language)
                                        const isItemSelected = this.isSelected(row.takeId)
                                        const contentAreaId = productStore.loginProduct ? productStore.loginProduct.contentAreaId : null

                                        return (
                                            <TableRow
                                                hover={true}
                                                onClick={(event) => this.handleClick(event, row.takeId)}
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={`${row.takeId}-${index}`} // We map over the rows once before this and use row.takeId as the key there, so this one needs to be a little different
                                                selected={isItemSelected}
                                                style={{
                                                    backgroundColor: (index % 2 === 0) ? AvantColors.REPORT_BACKGROUND_GRAY_DARK : "#FFFFFF"
                                                }}
                                            >
                                                {
                                                    isMultiLanguage &&
                                                    <TestTakerTableCell centerContent={true}>
                                                        {getLanguageString(language)}
                                                    </TestTakerTableCell>
                                                }
                                                <TestTakerTableCell
                                                    noWrap={true}
                                                >
                                                    {row.resumeKey}
                                                </TestTakerTableCell>

                                                { // Scheduled column
                                                    this.headerRow.find((row: HeaderRow) => row.id === "examData") &&
                                                    <TestTakerTableCell
                                                        noWrap={true}
                                                        onClick={this.openSidebarWithId(row.takeId, row.proctortype === ProctorTypes.P360.valueOf(), undefined, false, row.examData)}
                                                        >
                                                        {
                                                            row.examData.length > 0 ? this.renderScheduleDates(row.examData, typeof(row.finishDate) !== 'undefined' && row.finishDate !== null) : this.getProctorType(row)
                                                        }
                                                    </TestTakerTableCell>
                                                }
                                                {
                                                    isPlaceFamily &&
                                                    <>
                                                        <TestTakerTableCell centerContent={true}>
                                                            {contentAreaStore.convertContentAreaIdToLanguageName(JSON.parse(language))}
                                                        </TestTakerTableCell>
                                                        <TestTakerTableCell
                                                            centerContent={true}
                                                            onClick={this.openSidebarWithId(row.takeId, row.proctortype === ProctorTypes.P360.valueOf(), undefined, true, row.examData)}
                                                        >
                                                            {row.candoAverage}
                                                        </TestTakerTableCell>
                                                    </>
                                                }
                                                {
                                                    // Here we render the scores
                                                    this.state.availableSkills.map((skill: PanelSkill) => {
                                                        const scoreId = lodashCamelCase(skill.name) + "Score" as HeaderRow["id"]
                                                        const score = row[scoreId] ? row[scoreId]!!.toString() : undefined
                                                        // Assigning a basic badge/score for this takepanel
                                                        let badgeOrScore = this.getBadgeOrScore(false, score, "")
                                                        // Checking for special cases with the badge/score
                                                        // Writing specific badge/score
                                                        if (skill.symbol === PanelSkillEnum.WRITING) {
                                                            badgeOrScore = this.getBadgeOrScore(
                                                                false,
                                                                ReportUtils.setWritingStatusForHandwritten(row.writingScore),
                                                                "",
                                                                (isPlaceFamily && (row.product_package === "plc" || row.product_package === "shl-c"))
                                                            )
                                                        }
                                                        // Speaking specific badge/score
                                                        if (skill.symbol === PanelSkillEnum.SPEAKING) {
                                                            if (isPlaceFamily && placeVariations.includes(row.product_package!)) {
                                                                badgeOrScore = "n/a"
                                                            }
                                                        }
                                                        // PLACE/SHL cases
                                                        if (isPlaceFamily) {
                                                            if (contentAreaId === SHL_CONTENT_AREA_ID.value()) {
                                                                // SHL B and C do not show speaking
                                                                if (skill.symbol === PanelSkillEnum.SPEAKING && shlVariations.includes(row.product_package!)) {
                                                                    return
                                                                }
                                                                // Assigning the Early Exit (EE) badge on SHL takepanels if:
                                                                //  - The take has a finish date
                                                                //  - The takepanel does not have a score
                                                                if (!score && row.finishDate) {
                                                                    badgeOrScore = this.getBadgeOrScore(false, score, "", false, true)
                                                                }

                                                                // If a PLACE skill is in the SHL test.
                                                                if (placeSkillToFriendlyName.get(skill.symbol)) {
                                                                    badgeOrScore = "-"
                                                                }
                                                            } else {
                                                                // If a SHL skill is in the PLACE test.
                                                                if (shlSkillToFriendlyName.get(skill.symbol)) {
                                                                    badgeOrScore = "-"
                                                                }
                                                            }

                                                        }

                                                        return (
                                                            <Fragment key={`${row.takeId}-${skill.name}`}>
                                                                <TestTakerTableCell
                                                                    centerContent={true}
                                                                    onClick={this.openSidebarWithId(row.takeId, row.proctortype === ProctorTypes.P360.valueOf(), skill.symbol as PanelSkillEnum, false, row.examData)}
                                                                >
                                                                    {badgeOrScore}
                                                                </TestTakerTableCell>
                                                                {
                                                                    skill.symbol === PanelSkillEnum.READING && hasScaledReadingScores &&
                                                                        <TestTakerTableCell centerContent={true}>
                                                                            {row.readingScaledScore ?
                                                                                this.getBadgeOrScore(true, row.readingScore, row.readingScaledScore) :
                                                                                null
                                                                            }
                                                                        </TestTakerTableCell>
                                                                }
                                                                {
                                                                    skill.symbol === PanelSkillEnum.LISTENING && hasScaledListeningScores &&
                                                                        <TestTakerTableCell centerContent={true}>
                                                                            {row.listeningScaledScore ?
                                                                                this.getBadgeOrScore(true, row.listeningScore, row.listeningScaledScore) :
                                                                                null
                                                                            }
                                                                        </TestTakerTableCell>
                                                                }
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                                <TestTakerTableCell
                                                    centerContent={true}
                                                >
                                                    {row.compositeScore !== 0 && row.compositeScore != null ? row.compositeScore : ""}
                                                </TestTakerTableCell>
                                                <TestTakerTableCell>
                                                    {TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(row.startDate)}
                                                </TestTakerTableCell>
                                                <TestTakerTableCell>
                                                    {TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(row.finishDate)}
                                                </TestTakerTableCell>
                                                <TestTakerTableCell style={{minWidth:"110px"}}>
                                                    {this.rowDurationString(row)}
                                                </TestTakerTableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </div>
                    </div>
                </div>
                {sidebarStore.open && (
                    <TestTakerSidebarContainer
                        takeId={sidebarStore.takeId!}
                        isPlaceC={isPlaceC}
                        P360Data={sidebarStore.P360Data}
                        isP360={sidebarStore.openP360}
                    />
                )}

                {
                this.state.takeEditView &&
                    <TestTakerEdit
                        isAdmin={false}
                        takeId={this.state.currentTakeId}
                        onClose={closeTestTakerEdit}
                        onSuccessfulUpdate={this.handleRowUpdate}
                    />
                }

            </>
        )
    }
}

const getLanguageString = (language: any): string => {
    // This function assumes that it will get one of two things:
    // - The language name as a string.
    // - The numeric index of the language name in CONTENT_AREA_ID_TO_LANGUAGE_NAME
    // Anything else is unexpected input.
    if (isNumeric(language)) {
        // In case language is a numeric string, cast it as a number.
        language = language * 1

        return contentAreaStore.convertContentAreaIdToLanguageName(JSON.parse(language))
    } else {
        return language
    }
}

const isNumeric = (testVal: any): boolean => {
    // From https://stackoverflow.com/a/9716488
    return !isNaN(parseFloat(testVal)) && isFinite(testVal)
}

const useClickableStyles = makeStyles({
    clickableText: {
        "& *": {
            width: "fit-content",
            transition: "all 0.2s",
            textAlign: "center",
            fontWeight: "bold"
        },
        "&:hover *": {
            color: AvantColors.avantDarkBlue,
            textDecoration: "underline",
            transform: "scale(1.3)"
        }
    }
})

const TestTakerTableCell = (props: TableCellProps & { noWrap?: boolean, centerContent?: boolean }) => {
    const {style, children, noWrap, centerContent, onClick, ...rest} = props

    const clickableStyles = useClickableStyles()
    let contents = children
    if (typeof children === "string" || typeof children === "number") {
        contents = (
            <SmallBodyText
                noWrap={noWrap}
                style={{maxWidth: 120, overflow: "hidden", textOverflow: "ellipsis"}}
            >
                {children}
            </SmallBodyText>
        )
    }
    return (
        <TableCell
            padding={"none"}
            style={{
                height: 52,
                cursor: onClick ? "pointer" : "",
                padding: theme.spacing(0, 1),
                whiteSpace: "nowrap",
                ...style
            }}
            {...rest}
        >
            <div
                style={{
                    display: centerContent ? "flex" : undefined,
                    justifyContent: "center",
                    paddingRight: centerContent ? theme.spacing(2) : undefined
                }}
                className={onClick ? clickableStyles.clickableText : undefined}
                onClick={onClick}
            >
                {contents}
            </div>
        </TableCell>
    )
}
