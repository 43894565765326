import MaterialTable from "material-table"
import * as React from "react"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {addTakeDispatches, TakeDispatches} from "../../../components/App/App"
import ApiService from "../../../services/ApiService"
import {
    LastPage,
    LoginProductContentAreaData,
    SsoStudentClass,
    SsoTeacherClass, TestState
} from "../../../types/types"
import {authStore} from "../../common/authentication/AuthStore"
import {messageStore} from "../../common/messages/MessageStore"
import {productStore} from "../../common/products/ProductStore"
import {ErrorText, ErrorTitle, H2Text, H4Text} from "../../../styles/AvantTypography"
import {Button} from "@mui/material"
import {theme} from "../../../styles/MuiThemes"
import productPackageStore from "../../common/ProductPackage/ProductPackageStore";
import { contentAreaStore } from "../../common/contentArea/ContentAreaStore"
import {AxiosError} from "axios";
import {axiosErrorHumanReadable} from "../../../util/ErrorUtil";

const mapDispatchToProps = (dispatch: Dispatch): TakeDispatches => {
    return addTakeDispatches({}, dispatch)
}

export interface ListOfSsoClassesOrWelcomeCenterAccess {
    classes: SsoStudentClass[],
    welcomeCenterAccess: boolean
}

interface SSOAuthorizedStudentProps extends RouteComponentProps<{}>, TakeDispatches {}

interface SSOAuthorizedStudentState {
    classList: SsoStudentClass[],
    initialized: boolean,
    setupOpen: boolean,
    rosterOpen: boolean,
    loginId: number | null,
    groupId: number,
    package: string,
    allowHandwritten: boolean,
    canNotLoadClasses: boolean,
    isWelcomeCenter: boolean,
    error: string | null,
}

class SSOAuthorizedStudentBase extends React.Component<SSOAuthorizedStudentProps, SSOAuthorizedStudentState, {}> {
    tableRef = React.createRef<MaterialTable<SsoStudentClass>>() //

    constructor(props: SSOAuthorizedStudentProps) {
        super(props)
        this.state = {
            classList: [],
            initialized: false,
            setupOpen: false,
            rosterOpen: false,
            loginId: null,
            groupId: 0,
            package: "",
            allowHandwritten: false,
            canNotLoadClasses: false,
            isWelcomeCenter: false,
            error: null,
        }
    }

    componentDidMount(): void {
        this.loadClassList()
    }

    loadClassList = (): void => {
        ApiService.getSSOStudentClassList()
            .then(response => {
                // if (response.length) {
                if (response.classes.length) {
                    this.setState({classList: response.classes, isWelcomeCenter: response.welcomeCenterAccess})
                } else {
                    // try and force Clever to load the data and/or buy us time for the  first sync call to complete
                    ApiService.getSSOUpdateData().then (_ => {
                        // try to load the classes one last time
                        ApiService.getSSOStudentClassList()
                            .then(response2 => {
                                if (response2.classes.length) {
                                    this.setState({classList: response2.classes, canNotLoadClasses: false, isWelcomeCenter: response2.welcomeCenterAccess})
                                } else {
                                    this.setState({canNotLoadClasses: true, isWelcomeCenter: response2.welcomeCenterAccess})
                                }
                            })
                    }).catch(
                        _ => {
                            this.setState({canNotLoadClasses: true})
                        }
                    )
                }
            })
    }

    popupSettings = (event: any, rowdata: any): void  => {
        if (rowdata.groupId == null) {
            messageStore.setErrorMessage("Test not setup correctly - no group. Contact Support.")
        } else if (rowdata.productName == null) {
            messageStore.setErrorMessage("Test not setup correctly - no package. Contact Support.")
        } else {
            this.setState({
                setupOpen: true,
                groupId: rowdata.groupId,
                package: rowdata.productName,
                allowHandwritten: rowdata.handwritten ? true : false
            })
        }
    }
    closeTestSettings = () => {
        this.setState({setupOpen: false})
        this.loadClassList()
    }

    startTest = (event: any, row: any): void => {
        const rowData = row as SsoTeacherClass
        const userName = (authStore.auth && authStore.auth.ssoUserId) ? authStore.auth.ssoUserId : ""
        const loginProduct: LoginProductContentAreaData = {
            loginId: rowData.logins.id,
            userType: "S",
            productId: rowData.logins.productId,
            productName: rowData.logins.productName,
            contentAreaId: rowData.logins.contentareaid,
            contentAreaName: contentAreaStore.convertContentAreaIdToLanguageName(rowData.logins.contentareaid),
            rostered: true,
            userName: userName
        }

        // Setup state for the report
        productStore.setLoginProduct(loginProduct)

        const lastPage: LastPage = {
            url: "/profile-login-name",
            takeCode: rowData.logins.testCode
        }
        productStore.driver!!.helper.refreshTest(this.props, lastPage, loginProduct)
            .then ( (response:TestState) =>
                productStore.driver!!.helper.refreshTestCallback(response, this.props)
            )
            .catch( (error:AxiosError) => {
                let message = axiosErrorHumanReadable(error)
                if (message === '400: Missing Custom Section Take') {
                    this.setState({canNotLoadClasses: true,error:"CUSTOM_SECTION_MISSING"})
                } else {
                    this.setState({canNotLoadClasses: true,error:"OTHER"})
                }
            })
    }

    setStatus(status: string | null): string {
        switch (status) {
            case ("NEEDS_DISTRICT_SETUP") :
                return "This class has not been setup by your district please contact your school or district admin."
            case ("DISTRICT_SETUP_NEED_CLASSROOM_SETUP") :
                return "Please setup this test by select the gear icon for this test."
            case ("TESTING_READY") :
                return "Ready to start the test!"
            default:
                return ""
        }
    }
    isTestEnabled(rowData: SsoStudentClass): boolean {
        if (rowData.logins && (rowData.logins.allowTest || rowData.adHocTestGroup)) { return false }
        return true
    }

    render() {
        const classList = this.state.classList  ? this.state.classList : []

        return (
            <>

            {this.state.classList.length === 0 && this.state.canNotLoadClasses === false &&
                (
                    <div>
                        <div className="login__login-container" style={{display: "inline-block"}}>
                            <div className="login__login-container__top">
                                <h2 style={{textAlign: "center"}}>Loading Classes</h2>
                                <p>
                                    We are attempting to load your classes from {authStore && authStore.auth && authStore.auth.ssoType==="ClassLink"?"ClassLink":"Clever"}. 
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
            {this.state.classList.length === 0 && this.state.canNotLoadClasses === true && (!authStore || authStore.auth && authStore.auth.ssoType !== "NYCDOE") &&
                (
                    <div>
                        <div className="login__login-container" style={{display: "inline-block"}}>
                            <div className="login__login-container__top">
                                <h2 style={{textAlign: "center"}}>Loading Classes</h2>
                                <p>
                                    We could not find any classes that have tests at this time.  You can refresh the page
                                    to try and check for classes again or contact your teacher for help.
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
            {this.state.classList.length === 0 && this.state.canNotLoadClasses === true && authStore && authStore.auth && authStore.auth.ssoType === "NYCDOE" &&
                (
                    <div>
                        <div className="login__login-container" style={{display: "inline-block"}}>
                            <div className="login__login-container__top">
                                <H2Text style={{textAlign: "center"}}>New York City Department of Education</H2Text>
                                <H4Text style={{textAlign: "center"}}>Student not Rostered for Assessment in STARS System</H4Text>
                                <p>
                                    We did not receive an assessment roster code from the Stars system.  Please contact
                                    your teacher or school administrator to ensure you are rostered for the assessment.
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
            {this.state.canNotLoadClasses === true &&this.state.error === 'CUSTOM_SECTION_MISSING' &&
                (
                    <div>
                        <div className="login__login-container" style={{display: "inline-block"}}>
                            <div className="login__login-container__top">
                                <ErrorTitle  style={{textAlign: "center"}}>Error Loading Test</ErrorTitle>
                                <ErrorText>
                                    We have found a problem with your test.  Please contact your teacher or school administrator
                                    and have them re-roster you into this assessment. {this.state.loginId}
                                    y{this.state.classList.length}y
                                </ErrorText>
                            </div>
                        </div>
                    </div>
                )
            }
            {this.state.canNotLoadClasses === true &&this.state.error === 'OTHER' &&
                (
                    <div>
                        <div className="login__login-container" style={{display: "inline-block"}}>
                            <div className="login__login-container__top">
                                <ErrorTitle  style={{textAlign: "center"}}>Error Loading Test</ErrorTitle>
                                <ErrorText>
                                    We did not receive an assessment roster code from the Stars system.  Please contact
                                    your teacher or school administrator to ensure you are rostered for the assessment.
                                </ErrorText>
                            </div>
                        </div>
                    </div>
                )
            }


            {this.state.classList.length > 0 &&

                <MaterialTable
                    tableRef={this.tableRef}
                    title="Class List"
                    columns={[
                        {title: "Class", field: "name"},
                        {
                            title: "Testing Language",
                            render: rowData => (rowData.logins && rowData.logins.contentareaid) ? contentAreaStore.convertContentAreaIdToLanguageName(rowData.logins.contentareaid) : ""
                        },
                        {title: "Test Code", field: "logins.testCode"},
                        {
                            title: "Product",
                            render: rowData => rowData.productName ? productPackageStore.getProductPackageName(rowData.productName) : ""
                        },
                    ]}
                    options={{thirdSortClick: false}}
                    data={classList}
                    actions={[
                        rowData => ({
                            icon: "ballot",
                            tooltip: "Take Test",
                            onClick: this.startTest,
                            hidden: this.isTestEnabled(rowData)
                        }),
                    ]}
                />
                }
                { this.state.isWelcomeCenter &&
                    <div style={{
                            display: "flex",
                            marginTop:
                            theme.spacing(2),
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Button
                            variant={"contained"}
                            onClick={() => this.props.history.push("/sso/saml/welcome-center")}
                        >
                            Go To Welcome Center
                        </Button>
                    </div>
                }
            </>
        )
    }
}

export default connect(
    null,
    mapDispatchToProps
)(SSOAuthorizedStudentBase)
