import {observer} from "mobx-react"
import moment from "moment"
import React from "react"
import {
    Box,
    Container,
    Grid,
    Typography,
    Select,
    MenuItem,
    FormControl,
    TextField,
    Alert,
    AlertTitle
} from "@mui/material"
import AccountCircleRounded from "@mui/icons-material/AccountCircleRounded"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {isNullOrUndefined} from "util"
import {productStore} from "../../app/common/products/ProductStore"
import {postProfileSuccess} from "../../redux/app/actions"
import ApiService from "../../services/ApiService"
import HelperService from "../../services/HelperService"
import {ApiPanelGraph, LastPage, Profile, State, Take, TestState} from "../../types/types"
import {
    CHILDREN_PRIVACY,
    PRODUCT_PRIVACY,
    SUPPORT_MESSAGE
} from "../../util/Constants"
import {TakeId} from "../../validation/ValidPrimaryKey"
import {addTakeDispatches, TakeDispatches} from "../App/App"
import Button from "../Button/Button"
import { contentAreaStore } from "../../app/common/contentArea/ContentAreaStore"
import {H2Text} from "../../styles/AvantTypography"

interface IFormErrors {
    firstName: string[]
    lastName: string[]
    testTakerId: string[]
    grade: string[]
    confirmTestTakerId: string[]
}

interface IStateToProps {
    take: Take | null
    takeCode: string
    panelGraph: ApiPanelGraph
}

function mapStateToProps(state: State): IStateToProps {
    return {
        take: state.app.take as Take,
        takeCode: state.app.takeCode,
        panelGraph: state.app.panelGraph!
    }
}

interface IDispatchToProps extends TakeDispatches {
    profileSuccessDispatch: (profile: Profile) => void
}

function mapDispatchToProps(dispatch: Dispatch): IDispatchToProps {
    const dispatchs = {
        profileSuccessDispatch: (profile: Profile) => {
            dispatch(postProfileSuccess(profile))
        }
    }
    return addTakeDispatches(dispatchs, dispatch)
}

export type TComponentProps = IStateToProps & IDispatchToProps & RouteComponentProps<any>

interface IComponentState {
    error: boolean | string
    profile: Profile
    confirmTestTakerId: string
    take: Take | null
    languageName: string
    formErrors: IFormErrors
    formError: string | null
    loaded: boolean
}

@observer
class Stamp4SeProfileFormContainer extends React.Component<TComponentProps, IComponentState> {
    private grades = [
        "",
        "K",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "16+"
    ]

    // This needs to exist outside the state system. This is to determine if the required fields were already filled.
    // If they're not we'll let them fill them.
    private isReadOnly: boolean = false

    constructor(props: TComponentProps) {
        super(props)
        this.state = {
            error: false,
            profile: {
                firstName:
                    props.take && props.take.profile && props.take.profile.firstName
                        ? props.take.profile.firstName
                        : "",
                lastName:
                    props.take && props.take.profile && props.take.profile.lastName ? props.take.profile.lastName : "",
                testTakerId:
                    props.take && props.take.profile && props.take.profile.testTakerId
                        ? props.take.profile.testTakerId
                        : "",
                grade:
                    props.take && props.take.profile && props.take.profile.grade
                        ? props.take.profile.grade
                        : this.grades[0],
                schoolName:
                    props.take && props.take.profile && props.take.profile.schoolName
                        ? props.take.profile.schoolName
                        : "",
                testGroupName:
                    props.take && props.take.profile && props.take.profile.testGroupName
                        ? props.take.profile.testGroupName
                        : ""
            },
            confirmTestTakerId:
                props.take && props.take.profile && props.take.profile.testTakerId
                    ? props.take.profile.testTakerId
                    : "",
            languageName: "",
            formErrors: {
                firstName: [],
                lastName: [],
                testTakerId: [],
                grade: [],
                confirmTestTakerId: []
            },
            formError: null,
            take: this.props.take,
            loaded: false
        }

        this.isReadOnly = productStore.isReadOnlyProfile(
            this.state.profile.firstName,
            this.state.profile.lastName,
            this.state.profile.testTakerId,
            this.state.confirmTestTakerId
        )
    }

    async componentDidMount() {
        HelperService.enableTextSelection()
        await this.getData()

        if (
            productStore.loginProduct!!.rostered &&
            this.props.take &&
            this.props.take.profile &&
            this.props.take.profile.testTakerId
        ) {
            this.setState({confirmTestTakerId: this.props.take.profile.testTakerId})
        }
    }

    async componentDidUpdate() {
        if (this.props.take !== this.state.take) {
            this.setState({take: this.props.take})
        }
        if (this.props.take && this.props.take.profile && this.props.take.profile !== this.state.profile) {
            this.setState({profile: this.props.take.profile})
        }
        await this.getData()
    }

    getData = async () => {
        const driver = productStore.driver
        const product = productStore.loginProduct
        const {takeCode, panelGraph} = this.props
        const {loaded} = this.state
        if (driver && !loaded) {
            this.setState({loaded: true})
            document.title = `${driver.config.PRODUCT_TITLE} | Profile`
            let lastPage: LastPage | null = HelperService.getLastPageFromLs()
            if (lastPage) {
                const testState: TestState = await driver.helper.refreshTest(this.props, lastPage, product!)
                if (!testState.take) {
                    throw Error("testState.take is null or undefined")
                }
                if (!testState.take.profile) {
                    throw Error("testState.take.profile is null or undefined")
                }
                const languageId: number = this.props.panelGraph.contentAreaId
                const languageName: string = contentAreaStore.convertContentAreaIdToLanguageName(languageId)

                this.isReadOnly = productStore.isReadOnlyProfile(
                    testState.take.profile.firstName,
                    testState.take.profile.lastName,
                    testState.take.profile.testTakerId,
                    testState.take.profile.testTakerId
                )
                if (this.isReadOnly) {
                    this.setState({
                        confirmTestTakerId:
                            testState.take && testState.take.profile && testState.take.profile.testTakerId !== undefined
                                ? testState.take.profile.testTakerId
                                : ""
                    })
                }

                this.setState({take: testState.take, profile: testState.take.profile, languageName})
            } else {
                lastPage = {
                    url: "/stamp4se-profile",
                    takeCode,
                    panelGraphId: panelGraph.id
                }
                localStorage.setItem("lastPage", JSON.stringify(lastPage))
                const languageId: number = this.props.panelGraph.contentAreaId
                const languageName: string = contentAreaStore.convertContentAreaIdToLanguageName(languageId)
                this.setState({languageName})
            }
        }
    }

    handleFirstNameChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.firstName = e.target.value
        this.setState({profile})
    }

    handleLastNameChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.lastName = e.target.value
        this.setState({profile})
    }

    handleTestTakerIdChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.testTakerId = e.target.value
        this.setState({profile})
    }

    handleConfirmTestTakerIdChange = (e: any) => {
        this.setState({confirmTestTakerId: e.target.value})
    }

    handleGradeChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.grade = e.target.value
        this.setState({profile})
    }

    validateFirstName = (profile: Profile): string[] => {
        const firstName = profile.firstName
        if (isNullOrUndefined(firstName) || firstName.length === 0) {
            return ["Please enter your first name."]
        }
        return []
    }

    validateLastName = (profile: Profile): string[] => {
        const lastName = profile.lastName
        if (isNullOrUndefined(lastName) || lastName.length === 0) {
            return ["Please enter your last name."]
        }
        return []
    }

    validateTestTakerId = (profile: Profile): string[] => {
        const studentId = profile.testTakerId
        if (isNullOrUndefined(studentId) || studentId.length === 0) {
            return ["Please enter your test taker id."]
        }
        return []
    }

    validateConfirmTestTakerId = (profile: Profile): string[] => {
        const confirmTestTakerId = this.state.confirmTestTakerId
        const testTakerId = profile.testTakerId
        if (isNullOrUndefined(confirmTestTakerId) || confirmTestTakerId.length === 0) {
            return ["Please confirm your test taker id."]
        }
        if (confirmTestTakerId !== testTakerId) {
            return ['“Test Taker ID” and "Confirm Test Taker ID” fields must match.']
        }
        return []
    }

    validate = (profile: Profile): {hasErrors: boolean; formErrors: IFormErrors} => {
        const formErrors: IFormErrors = {
            firstName: this.validateFirstName(profile),
            lastName: this.validateLastName(profile),
            testTakerId: this.validateTestTakerId(profile),
            confirmTestTakerId: this.validateConfirmTestTakerId(profile),
            grade: []
        }

        let hasErrors: boolean = false
        Object.keys(formErrors).forEach((key: string) => {
            const value: string[] = formErrors[key]
            if (value && value.length > 0) {
                hasErrors = true
            }
        })

        return {
            hasErrors,
            formErrors
        }
    }

    handleSubmit = () => {
        this.setState({
            formError: null,
            formErrors: {
                firstName: [],
                lastName: [],
                testTakerId: [],
                grade: [],
                confirmTestTakerId: []
            }
        })
        if (this.props.take === null) {
            this.setState({
                error: SUPPORT_MESSAGE
            })
            return
        }

        const errors = this.validate(this.state.profile)
        if (errors.hasErrors) {
            this.setState({
                formError: "There are errors on the form.",
                formErrors: errors.formErrors
            })
        } else {
            const profile = this.state.profile
            if (moment(this.state.profile.dateOfBirth).isSameOrAfter(moment())) {
                profile.dateOfBirth = undefined
            }
            ApiService.postProfile(
                new TakeId(this.props.take.id),
                profile,
                productStore.driver!!.config.APP_PRODUCT_NAME.toLowerCase()
            )
                .then(() => {
                    this.props.profileSuccessDispatch(this.state.profile)
                    this.props.history.push("/dashboard")
                })
                .catch(() => {
                    this.setState({
                        error: SUPPORT_MESSAGE
                    })
                })
        }
    }
    renderRequiredInformation = (): JSX.Element => {
        return (
            <Box
                component="fieldset"
                sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "16px",
                    mb: 4
                }}
            >
                <Typography
                    variant="subtitle1"
                    component="legend"
                    sx={{
                        fontWeight: 700,
                        color: "#333",
                        mb: 2,
                        fontSize: "21px !important",
                        width: "auto",
                        padding: "4px"
                    }}
                >
                    Required Fields
                </Typography>
                <Grid container spacing={2}>
                    {/* First Name */}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <label htmlFor="fname">
                                <Typography
                                    variant="body2"
                                    sx={{fontWeight: 600, mb: 0.5, color: "#333", fontSize: "18px !important"}}
                                >
                                    First Name*
                                </Typography>
                            </label>
                            <TextField
                                fullWidth
                                placeholder="Enter your first name"
                                value={this.state.profile.firstName}
                                data-tst-id="first-name"
                                onChange={this.handleFirstNameChange}
                                inputProps={{
                                    readOnly: this.isReadOnly,
                                    style: {
                                        fontSize: "16px",
                                        border: "2px solid #BBBBBB",
                                        boxShadow: "inset 0px 4px 6px #00000014"
                                    }
                                }}
                                id="fname"
                                variant="outlined"
                                error={this.state.formErrors.firstName.length > 0}
                                helperText={this.state.formErrors.firstName.join(" ")}
                                FormHelperTextProps={{
                                    sx: {textTransform: "none"}
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Last Name */}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <label htmlFor="lname">
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: 600,
                                        mb: 0.5,
                                        color: "#333",
                                        fontSize: "18px !important"
                                    }}
                                >
                                    Last Name*
                                </Typography>
                            </label>
                            <TextField
                                fullWidth
                                placeholder="Enter your last name"
                                value={this.state.profile.lastName}
                                data-tst-id="last-name"
                                onChange={this.handleLastNameChange}
                                inputProps={{
                                    readOnly: this.isReadOnly,
                                    style: {
                                        fontSize: "16px",
                                        border: "2px solid #BBBBBB",
                                        boxShadow: "inset 0px 4px 6px #00000014"
                                    }
                                }}
                                id="lname"
                                variant="outlined"
                                error={this.state.formErrors.lastName.length > 0}
                                helperText={this.state.formErrors.lastName.join(" ")}
                                FormHelperTextProps={{
                                    sx: {textTransform: "none"}
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Test Taker ID */}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <label htmlFor="test-id">
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: 600,
                                        mb: 0.5,
                                        color: "#333",
                                        fontSize: "18px !important"
                                    }}
                                >
                                    Test Taker ID*
                                </Typography>
                            </label>
                            <TextField
                                fullWidth
                                placeholder="Unique number or email assigned by your school/organization"
                                value={this.state.profile.testTakerId}
                                data-tst-id="student-id"
                                onChange={this.handleTestTakerIdChange}
                                inputProps={{
                                    readOnly: this.isReadOnly,
                                    style: {
                                        fontSize: "16px",
                                        border: "2px solid #BBBBBB",
                                        boxShadow: "inset 0px 4px 6px #00000014"
                                    }
                                }}
                                id="test-id"
                                variant="outlined"
                                error={this.state.formErrors.testTakerId.length > 0}
                                helperText={this.state.formErrors.testTakerId.join(" ")}
                                FormHelperTextProps={{
                                    sx: {textTransform: "none"}
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Confirm Test Taker ID */}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <label htmlFor="taker-id">
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: 600,
                                        mb: 0.5,
                                        color: "#333",
                                        fontSize: "18px !important"
                                    }}
                                >
                                    Confirm Test Taker ID*
                                </Typography>
                            </label>
                            <TextField
                                fullWidth
                                placeholder="Unique number or email assigned by your school/organization"
                                value={this.state.confirmTestTakerId}
                                data-tst-id="confirm-student-id"
                                onChange={this.handleConfirmTestTakerIdChange}
                                inputProps={{
                                    readOnly: this.isReadOnly,
                                    style: {
                                        fontSize: "16px",
                                        border: "2px solid #BBBBBB",
                                        boxShadow: "inset 0px 4px 6px #00000014"
                                    }
                                }}
                                id="taker-id"
                                variant="outlined"
                                error={this.state.formErrors.confirmTestTakerId.length > 0}
                                helperText={this.state.formErrors.confirmTestTakerId.join(" ")}
                                FormHelperTextProps={{
                                    sx: {textTransform: "none"}
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    renderOptionalInformation = (): JSX.Element => {
        return (
            <Box
                component="fieldset"
                sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "16px",
                    mb: 4
                }}
            >
                <Typography
                    component="legend"
                    variant="subtitle1"
                    sx={{
                        fontWeight: 700,
                        color: "#333",
                        mb: 2,
                        fontSize: "21px !important",
                        width: "auto",
                        padding: "4px"
                    }}
                >
                    Optional Information
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <Typography
                                variant="body2"
                                sx={{fontWeight: 600, mb: 0.5, color: "#333", fontSize: "18px !important"}}
                            >
                                Grade
                            </Typography>
                            <Select
                                value={this.state.profile.grade}
                                onChange={this.handleGradeChange}
                                displayEmpty
                                data-tst-id="grade"
                                sx={{
                                    fontSize: "16px",
                                    border: "2px solid #BBBBBB",
                                    borderRadius: "0px !important",
                                    boxShadow: "inset 0px 4px 6px #00000014"
                                }}
                                inputProps={{
                                    readOnly: this.isReadOnly && this.state.profile.grade != null
                                }}
                                disabled={this.isReadOnly && this.state.profile.grade != null}
                            >
                                {this.grades.map((grade: string) => (
                                    <MenuItem key={grade} value={grade}>
                                        {grade || "Select Grade"}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    render() {
        return (
            <div className="profile-form-container" style={{marginBottom: "10px"}}>
                {this.state.error && (
                    <Alert severity="error" onClose={() => this.setState({error: false})} sx={{mb: 2}}>
                        <AlertTitle>Error</AlertTitle>
                        {this.state.error}
                    </Alert>
                )}
                <Container
                    sx={{
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                        padding: "32px",
                        maxWidth: "1140px !important"
                    }}
                >
                    {/* Header */}
                    {this.state.take && (
                        <div>
                            <Box sx={{display: "flex", alignItems: "center", mb: 2, marginLeft: "-9px"}}>
                                <AccountCircleRounded
                                    sx={{
                                        marginRight: "2px !important",
                                        height: "42px !important",
                                        width: "42px !important",
                                        color: "#333"
                                    }}
                                />
                                <H2Text style={{color: "#000", fontSize: "44px"}}>Create Profile</H2Text>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    mb: 3
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    style={{
                                        fontSize: "18px"
                                    }}
                                >
                                    To resume the test later, use the Login Name:{" "}
                                    <strong style={{color: "#333"}}>{this.props.takeCode}</strong>
                                </Typography>
                                <Typography variant="body2" sx={{color: "black", fontWeight: 700, fontSize: "21px"}}>
                                    *required
                                </Typography>
                            </Box>
                            {/* Required Fields Section */}
                            {this.renderRequiredInformation()}
                            {/* optional Fields Section */}
                            {this.renderOptionalInformation()}

                            {/* Submit Button */}
                            <Box sx={{display: "flex", mt: 2}}>
                                <Button
                                    onClick={this.handleSubmit}
                                    className="avant-button--default avant-button--primary"
                                    testId="submit"
                                    style={{
                                        fontSize: "21px",
                                        width: "112px",
                                        height: "40px",
                                        backgroundColor: "#115FAC"
                                    }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </div>
                    )}
                    <Box sx={{marginTop: "50px"}}>
                        <hr
                            style={{
                                border: "1px solid #ddd",
                                width: "calc(100% + 50px)",
                                margin: "5px -25px"
                            }}
                        />

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "16px",
                                marginTop: "10px",
                                marginBottom: "-16px"
                            }}
                        >
                            <a
                                href={CHILDREN_PRIVACY}
                                style={{
                                    textDecoration: "underline",
                                    textDecorationThickness: "2px", // Thickness of the underline
                                    textUnderlineOffset: "5px",
                                    color: "#000000",
                                    fontWeight: 300,
                                    fontSize: "21px"
                                }}
                            >
                                Children's Privacy Notice
                            </a>
                            <span style={{fontSize: "21px"}}> | </span>
                            <a
                                href={PRODUCT_PRIVACY}
                                style={{
                                    textDecoration: "underline",
                                    textDecorationThickness: "2px", // Thickness of the underline
                                    textUnderlineOffset: "5px",
                                    color: "#000000",
                                    fontWeight: 300,
                                    fontSize: "21px"
                                }}
                            >
                                Product Privacy Policy
                            </a>
                        </Box>
                    </Box>
                </Container>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stamp4SeProfileFormContainer)
