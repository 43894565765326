import * as Sentry from "@sentry/browser"
import React, {createRef, RefObject} from "react"
import {Alert} from "react-bootstrap"
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal"
import InstructionPanel from "../../../components/InstructionPanel/InstructionPanel"
import {ItemHistoryDialogContainer} from "../../../components/itemHistoryDialog/ItemHistoryDialog"
import ItemMenu from "../../../components/ItemMenu/ItemMenu"
import ResponsePanel from "../../../components/ResponsePanel/ResponsePanel"
import StatusBar from "../../../components/StatusBar/StatusBar"
import HelperService from "../../../services/HelperService"
import {
    Answer, ApiPanelGraph,
    IItem,
    ItemFormatEnum,
    LoginProductContentAreaData,
    NavButtonOrderEnum,
    NavDirectionEnum,
    PutTwilioSQSRequest,
    RecorderEnum,
    RecordWarningEnum,
    TestEngineOptions,
    THandleNavigation,
    TLocalAnswer,
    TSeconds,
    VideoContent
} from "../../../types/types"
import {
    ADVANCE_PRODUCT_ID,
    WEBRTC_RESPONSE
} from "../../../util/Constants"
// import {to} from "../../../util/elvis"
import {log} from "../../../util/Logging"
import {Section} from "../../section/models/Section"
import {AuthUser} from "../authentication/models/AuthUser"
import {ProductDriver} from "../products/ProductStore"
import {ItemContent} from "./models/ItemContent"
import { voiceRecorderStore } from './VoiceRecorderStore'
import LanguageUtils from "../../../util/LanguageUtils";
import {to} from "../../../util/elvis";
import { contentAreaStore } from "../contentArea/ContentAreaStore"

interface ItemProps {
    item: IItem
    takeId?: number
    panelId?: number
    panelGraph?: ApiPanelGraph | null
    takePanelId?: number // ??? Why does instructions need these: for the AudioPlayer component
    localAnswer: TLocalAnswer | null
    prevAnswer: Answer | null
    correctAnswer: ItemContent | null
    handleNavigation: THandleNavigation
    handleOutOfTime: () => void
    config: TestEngineOptions
    startTime: Date | null
    allowedTime: TSeconds | null
    isFirstItem: boolean
    isLastItem: boolean
    isRtlLayout: boolean
    autoSaving: boolean
    showChoices: boolean
    authUser: AuthUser
    driver: ProductDriver
    recordingWarningSeen: RecordWarningEnum
    progressInSection: number
    itemIndex: number
    section: Section
    product: LoginProductContentAreaData
    handleLocalAnswerChange: (localAnswer: TLocalAnswer) => void
    handleEditCorrectAnswer: (newCorrectAnswer: ItemContent, oldCorrectAnswer: ItemContent) => Promise<void>
    handleEditItemContent: (itemContentId: number, content: string, itemId?: number) => Promise<void>
    handleUpdateItemFile: (itemContentId: number, content: string | VideoContent) => void
    setSeenRecordingWarning: (state: RecordWarningEnum) => void
    handleNavigationByIndex: (index: number) => void
    isEmbedded?: boolean
    hideSpinner: boolean
}

interface ItemState {
    error: boolean | string
    answer: TLocalAnswer
    correctAnswer: ItemContent | null
    showChoices: boolean
    buttonOrder: NavButtonOrderEnum
    autoSaving: boolean
    isAdmin: boolean
    editingRadioCorrectAnswer: boolean
    editableCorrectAnswer: ItemContent | null
    oldEditableCorrectAnswer: ItemContent | null
    correctAnswers: string[]
    recordingSidsToSave: string
    showNoRecordingModal: boolean
    recorderSupported: RecorderEnum
    languageName: string
    showConfirmationModal: boolean
    confirmationModalTitle: string
}

export default class Item extends React.Component<ItemProps, ItemState> {
    instructionPanel: RefObject<InstructionPanel>
    fillInTheBlankAnswers: string[] = []
    fillInTheBlankCount: number = -1
    instructionContent: HTMLDivElement | undefined
    responseContent: HTMLDivElement | undefined

    constructor(props: ItemProps) {
        super(props)
        const buttonOrder = this.getNavButtonOrder(this.props.isRtlLayout)
        const webRTCResponse: PutTwilioSQSRequest = {
            itemID: 0,
            panelID: 0,
            recordingsToDelete: "",
            recordingsToSave: "",
            takeID: 0
        }

        if (this.props.item.format === ItemFormatEnum.SPEAKING_ORAL && this.props.localAnswer !== null) {
            HelperService.parseWebRTCResponse(this.props.localAnswer as string, webRTCResponse)
        }

        this.state = {
            error: false,
            answer: this.props.item.format === ItemFormatEnum.SPEAKING_ORAL ? this.props.item.flvRecordingUrl! : this.props.localAnswer,
            correctAnswer: this.props.correctAnswer,
            showChoices: true,
            buttonOrder,
            autoSaving: this.props.autoSaving,
            isAdmin: this.props.authUser.userType === "A",
            editingRadioCorrectAnswer: false,
            editableCorrectAnswer: null,
            oldEditableCorrectAnswer: null,
            correctAnswers: this.props.item.correctAnswers,
            recordingSidsToSave: webRTCResponse.recordingsToSave,
            showNoRecordingModal: false,
            recorderSupported: RecorderEnum.UNKNOWN,
            languageName: "",
            showConfirmationModal: false,
            confirmationModalTitle: ""
        }

        Sentry.configureScope((scope) => {
            scope.setTag("itemId", `${this.props.item.id}`)
            scope.setTag("itemFormat", `${this.props.item.format}`)
            scope.setTag("itemBinName", `${this.props.item.binName}`)
            scope.setTag("panelId", `${this.props.panelId}`)
            scope.setTag("takePanelId", `${this.props.takePanelId}`)
        })

        this.instructionPanel = createRef()
    }

    componentDidUpdate(prevProps: Readonly<ItemProps>, prevState: Readonly<ItemState>, snapshot?: any) {
        const buttonOrder = this.getNavButtonOrder(this.props.isRtlLayout)
        const answer = this.props.localAnswer

        if (answer !== this.state.answer) {
            if (this.props.item.format === ItemFormatEnum.SPEAKING_ORAL && !(answer instanceof Blob)) {
                console.error("This code should never be reached as Flash and Twilio have been removed.")
            } else {
                this.setState({answer, buttonOrder})
            }

            const {current} = this.instructionPanel
            if (current && current.instructions.current) {
                current.instructions.current.setPassageSelectAnswer((answer as string) || "")
            }
        }

        if (this.props.item.format === ItemFormatEnum.FILL_IN_THE_BLANK) {
            const contents: string[] = (this.props.item.choices[0].content as string).split(/<>/)
            if (this.fillInTheBlankAnswers.length !== contents.length - 1) {
                for (let i = 0; i < contents.length - 1; i++) {
                    this.fillInTheBlankAnswers.push("")
                }
            }
        }

        if (this.props.item.training && !this.props.config.submitOnNavigate) {
            const showChoices: boolean = prevProps.showChoices
            if (showChoices !== this.state.showChoices) {
                this.setState({showChoices})
            }
        }

        if (this.props.item.correctAnswers !== prevProps.item.correctAnswers) {
            this.setState({correctAnswers: this.props.item.correctAnswers})
        }

        const correctAnswer = this.props.correctAnswer
        if (correctAnswer !== this.state.correctAnswer) {
            this.setState({correctAnswer})
        }

        if (this.props.autoSaving !== prevProps.autoSaving) {
            this.setState({
                autoSaving: this.props.autoSaving
            })
        }

        // These products grab the language from the item instead of the product
        const itemBasedLangProducts = [ADVANCE_PRODUCT_ID.value()]
        const isItemBasedLang: boolean = itemBasedLangProducts.indexOf(this.props.product.productId) !== -1

        const languageId = isItemBasedLang ? parseInt(this.props.item.languageId, 10) : this.props.product.contentAreaId

        if (languageId == null) {
            throw Error(`Failed to find the item's ${this.props.item.id} language id
            or product's ${this.props.product.productId} content area id`)
        }

        let language = LanguageUtils.removeTilde(contentAreaStore.convertContentAreaIdToLanguageName(languageId))
        language = LanguageUtils.removeMonolingual(language)

        const languageName = to<string>(
            language,
            new Error(`ContentAreaID ${languageId} is not in CONTENT_AREA_ID_TO_LANGUAGE_NAME`)
        )
        if (languageName !== this.state.languageName) {
            this.setState({languageName})
        }

    }

    setInstructionContent = (instructionContent: HTMLDivElement) => {
        this.instructionContent = instructionContent
    }

    setResponseContent = (responseContent: HTMLDivElement) => {
        this.responseContent = responseContent
    }

    handleNext = () => {
        window.scrollTo(0, 0)
        const instructionPanel = document.getElementById("instruction-panel")
        if (instructionPanel !== null) {
            instructionPanel.scrollTop = 0
        }
        const responsePanel = document.getElementById("response-panel")
        if (responsePanel !== null) {
            responsePanel.scrollTop = 0
        }
        if (this.props.item.format === ItemFormatEnum.SPEAKING_ORAL) {
            if (
                !(this.state.answer && this.state.answer instanceof Blob) &&
                this.state.recorderSupported === RecorderEnum.TWILIO &&
                this.state.recordingSidsToSave.length === 0
            ) {
                this.setState({showNoRecordingModal: true})
            } else {
                voiceRecorderStore.stopPlayBack()
                voiceRecorderStore.playbackState = "not"

                const modalTitle = "Are you finished recording your response?"
                // this.setState({confirmationModalString: "recording"})
                this.setState({confirmationModalTitle: modalTitle})
                this.setState({showConfirmationModal: true})
                // this.props.handleNavigation(NavDirectionEnum.NEXT, this.state.buttonOrder)
            }
        } else if (this.props.item.format === ItemFormatEnum.BLANK) {
            this.submitBlankItem().then(() => {
                this.props.handleNavigation(NavDirectionEnum.NEXT, this.state.buttonOrder)
            })
        } else if (this.props.item.format === ItemFormatEnum.WRITING) {
            const modalTitle = "Are you finished writing your response?"
            // this.setState({confirmationModalString: "writing"})
            this.setState({confirmationModalTitle: modalTitle})
            this.setState({showConfirmationModal: true})
        } else if (this.props.item.format === ItemFormatEnum.VIDEO_RECORDING) {
            const modalTitle = "Are you finished recording your response?"
            // this.setState({confirmationModalString: "writing"})
            this.setState({confirmationModalTitle: modalTitle})
            this.setState({showConfirmationModal: true})
        } else {
            this.props.handleNavigation(NavDirectionEnum.NEXT, this.state.buttonOrder)
        }

    }

    confirmationModalClose = () => {
        this.setState({showConfirmationModal: false})
    }

    confirmationModalContinue = () => {
        this.setState({showConfirmationModal: false})
        this.props.handleNavigation(NavDirectionEnum.NEXT, this.state.buttonOrder)
    }

    handleBack = () => {
        if (this.props.item.format === ItemFormatEnum.BLANK) {
            this.submitBlankItem().then(() => {
                this.props.handleNavigation(NavDirectionEnum.BACK, this.state.buttonOrder)
            })
        } else {
            this.props.handleNavigation(NavDirectionEnum.BACK, this.state.buttonOrder)
        }
    }

    setRecorderSupported = (recorder: RecorderEnum) => {
        this.setState({recorderSupported: recorder})

        if (recorder === RecorderEnum.TWILIO) {
            this.setState({answer: this.props.localAnswer})
        }
    }

    setRecordingSidsToSave = (recordingSids: string) => {
        this.setState({recordingSidsToSave: recordingSids})
        log.debug("Item.tsx setRecordingSidsToSave  " + recordingSids)
        if (recordingSids.length === 0) {
            this.props.handleLocalAnswerChange(null)
        } else if (recordingSids.endsWith(".flv")) {
            this.props.handleLocalAnswerChange(recordingSids)
        } else {
            this.props.handleLocalAnswerChange(WEBRTC_RESPONSE + "_" + this.state.recordingSidsToSave)
        }
    }

    handleOutOfTime = () => {
        this.props.handleOutOfTime()
    }

    submitBlankItem = (): Promise<void> => {
        return new Promise<void>((resolve) => {
            this.props.handleLocalAnswerChange("")
            resolve()
        })
    }

    setFillInTheBlankCount = (count: number) => {
        this.fillInTheBlankCount = count
    }

    setFillInTheBlankAnswers = (fillInTheBlankAnswers: string[]) => {
        this.fillInTheBlankAnswers = fillInTheBlankAnswers
    }

    getNavButtonOrder = (isRtlLayout: boolean): NavButtonOrderEnum => {
        let buttonOrder: NavButtonOrderEnum = NavButtonOrderEnum.LEFT_TO_RIGHT
        if (isRtlLayout) {
            buttonOrder = NavButtonOrderEnum.RIGHT_TO_LEFT
        }
        return buttonOrder
    }

    isAnswered = (): boolean => {
        const {item} = this.props

        // Blanks are always answered
        // This MUST come first, otherwise blanks will always
        // be unanswered :)
        if (item.format === ItemFormatEnum.BLANK) {
            return true
        }

        // If there is no answer then we definitely haven't answered the item
        const answer = this.props.localAnswer
        if (!answer) {
            return false
        }

        // It's a speaking item, so make sure it fits the valid formats
        if (item.format === ItemFormatEnum.SPEAKING_ORAL) {
            // This is the Opus recorder
            if (answer instanceof Blob) {
                return true
            } else if ((answer as string).includes(".flv")) {
                return true
            } else {
                return this.state.recordingSidsToSave.length > 0
            }
        }

        // Check box expects the answer to be an array of integers
        if (item.format === ItemFormatEnum.CHECKBOX) {
            const disableRequiredAnswers = !!this.props.config.disableRequiredAnswers
            return this.props.driver.itemContainerHelper.isValidCbAnswer(
                answer as number[],
                item,
                disableRequiredAnswers
            )
        }

        // Fill-in-the-blank expects the code to have a list of strings
        if (item.format === ItemFormatEnum.FILL_IN_THE_BLANK) {
            let fillInBlankIsAnswered = true
            let fbAnswers: string[] = answer ? (answer as string[]) : []
            if (fbAnswers.length === 1 && fbAnswers[0] === "") {
                fbAnswers = []
            }

            fbAnswers.forEach((element) => {
                if (element === undefined || element.length === 0) {
                    fillInBlankIsAnswered = false
                }
            })
            fillInBlankIsAnswered = fillInBlankIsAnswered && fbAnswers.length === this.fillInTheBlankCount
            return fillInBlankIsAnswered
        }

        // Writing  items cannot have empty string as answer.
        // This must happen LAST because empty string means
        // different things to different item formats.
        return answer !== ""
    }

    render() {
        const item: IItem = this.props.item

        const hasInstructions =
            item.itemContent1 !== undefined ||
            item.itemContent2 !== undefined ||
            item.itemContent3 !== undefined ||
            item.itemContent4 !== undefined

        const className: string = "item-" + item.format.toLowerCase()
        const hasResponsePanel: boolean = this.props.item.format !== ItemFormatEnum.BLANK

        const itemIsAnswered: boolean = this.isAnswered()

        const disabledItemMenu: boolean = this.props.section
            ? this.props.section.config.disableItemMenuInteraction === true
            : false

        const itemIndex = this.props.itemIndex
        const itemCount = this.props.section.items.length
        const isFirstItem: boolean = itemIndex === 0
        const isLastItem: boolean = itemIndex === itemCount - 1

        return (
            <div
                data-tst-id="item"
                data-item-id={item.id}
                data-item-format={item.format}
                data-item-name={item.name}
                data-item-bin={item.binName}
                data-item-language={item.languageId}
                data-item-is-first={isFirstItem}
                data-item-is-last={isFirstItem}
                className={"item " + className} key={item.id}>

                {this.state.error && (

                    <div className="alerts">
                        <Alert variant="danger" onClose={() => this.setState({error: false})}>
                            {this.state.error}
                        </Alert>
                    </div>

                )}

                <ConfirmationModal
                    showModal={this.state.showConfirmationModal}
                    modalTitle={this.state.confirmationModalTitle}
                    noClickedOrClose={this.confirmationModalClose}
                    yesClicked={this.confirmationModalContinue}
                />

                <div className={this.props.isRtlLayout ? "item-content rtl" : "item-content"}>
                    {this.props.config.showItemMenu && (
                        <div className="item-menu">
                            {disabledItemMenu && <div className="item-menu__disabled"/>}
                            <ItemMenu
                                section={this.props.section}
                                itemIndex={this.props.itemIndex}
                                localAnswer={this.props.localAnswer}
                                handleNavigationByIndex={this.props.handleNavigationByIndex}
                                driver={this.props.driver}
                            />
                        </div>
                    )}

                    {hasInstructions && (
                        <div id="content"
                             className={
                                 !this.props.config.showItemMenu
                                     ? "instruction-panel"
                                     : "instruction-panel instruction-panel--item-menu"
                             }
                        >
                            {(this.props.product.productId === ADVANCE_PRODUCT_ID.value()
                                && this.state.isAdmin) &&

                            <div className="advance-admin-styles">
                                Item Name: {this.props.item.name ? this.props.item.name : "Name not found"}
                                <ItemHistoryDialogContainer
                                    itemId={this.props.item.id}
                                    itemName={this.props.item.name ? this.props.item.name : "Name not found"}
                                />

                            </div>

                            }
                            <InstructionPanel
                                item={item}
                                takePanelId={this.props.takePanelId}
                                config={this.props.config}
                                driver={this.props.driver}
                                isRtlLayout={this.props.isRtlLayout}
                                authUser={this.props.authUser}
                                product={this.props.product}
                                handleEditItemContent={this.props.handleEditItemContent}
                                handleUpdateItemFile={this.props.handleUpdateItemFile}
                                ref={this.instructionPanel}
                                handleAnswerChange={this.props.handleLocalAnswerChange}
                                setInstructionContent={this.setInstructionContent}
                            />
                        </div>
                    )}
                    {hasResponsePanel && (
                        <div className="response-panel">
                            <ResponsePanel
                                item={this.props.item}
                                takeId={this.props.takeId}
                                panelId={this.props.panelId}
                                isRtlLayout={this.props.isRtlLayout}
                                authUser={this.props.authUser}
                                driver={this.props.driver}
                                languageName={this.state.languageName}
                                config={this.props.config}
                                showChoices={this.state.showChoices}
                                isAdmin={this.state.isAdmin}
                                answer={this.state.answer}
                                correctAnswer={this.state.correctAnswer}
                                prevAnswer={this.props.prevAnswer}
                                autoSaving={this.props.autoSaving}
                                fillInTheBlankAnswers={this.fillInTheBlankAnswers}
                                correctAnswers={this.state.correctAnswers}
                                recordingWarningSeen={this.props.recordingWarningSeen}
                                recorderSupported={this.state.recorderSupported}
                                recordingSidsToSave={this.state.recordingSidsToSave}
                                product={this.props.product}
                                handleUpdateItemFile={this.props.handleUpdateItemFile}
                                handleLocalAnswerChange={this.props.handleLocalAnswerChange}
                                handleEditCorrectAnswer={this.props.handleEditCorrectAnswer}
                                setFillInTheBlankAnswers={this.setFillInTheBlankAnswers}
                                setFillInTheBlankCount={this.setFillInTheBlankCount}
                                setRecorderSupported={this.setRecorderSupported}
                                setRecordingSidsToSave={this.setRecordingSidsToSave}
                                setSeenRecordingWarning={this.props.setSeenRecordingWarning}
                                setResponseContent={this.setResponseContent}
                                voiceRecorder={this.props.section.info.voiceRecorder}
                            />
                        </div>
                    )}
                </div>

                {!this.props.isEmbedded && (
                    <StatusBar
                        outOfTimeCallback={this.handleOutOfTime}
                        config={this.props.config}
                        driver={this.props.driver}
                        progressInSection={this.props.progressInSection}
                        handleNext={this.handleNext}
                        handleBack={this.handleBack}
                        answered={itemIsAnswered}
                        testName={this.props && this.props.config && this.props?.config?.testName ?  this.props.config.testName : ""}
                        product={this.props.product}
                        isFirstItem={isFirstItem}
                        isLastItem={isLastItem}
                        section={this.props.section}
                        isRtl={this.props.isRtlLayout}
                        panelGraph={this.props.panelGraph}
                    />
                )}
            </div>
        )
    }
}
