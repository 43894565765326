import React, {FC} from "react"
import {Box, Typography, List, ListItem, ListItemIcon, ListItemText, Button} from "@mui/material"
import {TEST_RULES_IMAGES} from "../../util/Constants"
interface AgreementContentProps {
    testName: string
    agreeToEULA: () => void
}
const TestRuleData = [
    {
        src: TEST_RULES_IMAGES.OUTSIDE,
        alt: "Test Window - Do not click outside",
        primary: "1. Do not click outside the test window.",
        secondary: "This is tracked and will log you out of the test."
    },
    {
        src: TEST_RULES_IMAGES.BOOK,
        alt: "Support Materials - Do not use aids",
        primary: "2. Do not use any support materials",
        secondary:
            "(e.g., dictionaries, textbooks, cell phones, apps, messaging tools, or translation aids such as Google Translate)."
    },
    {
        src: TEST_RULES_IMAGES.NOTAKINGNOTES,
        alt: "Notes - Do not take notes",
        primary: "3. Do not take notes",
        secondary: "(paper-based or electronic). Paper, pens, pencils, styluses, etc. are not allowed."
    },
    {
        src: TEST_RULES_IMAGES.NONOTES,
        alt: "Expressive Responses - Do not use notes",
        primary: "4. Do not read/use",
        secondary: "any notes or support materials for your speaking/expressive responses."
    },
    {
        src: TEST_RULES_IMAGES.COMMUNICATE,
        alt: "Communication - Do not communicate",
        primary: "5. Do not communicate with anyone",
        secondary: "except the proctor during the test."
    },
    {
        src: TEST_RULES_IMAGES.NOTSHARE,
        alt: "Share Test Questions - Do not share",
        primary: "6.Do not share test questions or prompts.",
        secondary: "Do not copy, print, or take screenshots of the test."
    },
    {
        src: TEST_RULES_IMAGES.PROFANE,
        alt: "Inappropriate Language - Avoid",
        primary: "7. Do not use violent, profane, or inappropriate language",
        secondary: "in your responses."
    }
]
export const AgreementContent: FC<AgreementContentProps> = ({testName, agreeToEULA}) => (
    <Box>
        <Typography variant="h1" sx={{fontWeight: "bold", marginBottom: "8px"}}>
            Test Rules
        </Typography>
        <Typography variant="body1" sx={{marginBottom: "8px"}}>
            This test measures language proficiency (e.g., your real-world language skills in spontaneous
            situations). To continue, review these rules and click the “I understand” button below:
        </Typography>

        <List sx={{textAlign: "left", maxWidth: 600, mr: 2, margin:"0"}}>
            {TestRuleData.map((rule, index) => (
                <ListItem key={index}>
                    <ListItemIcon>
                        <img
                            src={rule.src}
                            alt={rule.alt}
                            style={{width: 100, marginLeft: "-20px", marginRight: "20px"}}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body1">
                                <span style={{fontWeight: "bold"}}>{rule.primary}</span> {rule.secondary}
                            </Typography>
                        }
                    />
                </ListItem>
            ))}
        </List>
        <Typography variant="body1"  style={{marginTop:"0px"}}>
            Violating these rules may invalidate your results.
        </Typography>
        <Button
            variant="contained"
            color="primary"
            sx={{
                mt: 3,
                width: "100%",
                textTransform: "none",
                fontSize: 16,
                ":hover": {
                    backgroundColor: "primary.dark"
                }
            }}
            onClick={agreeToEULA}
            aria-label="Agree to EULA and proceed"
        >
            I understand
        </Button>
    </Box>
)
