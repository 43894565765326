import React from "react"
import {authStore} from "../../app/common/authentication/AuthStore"
import HelperService from "../../services/HelperService"
import {avantHistory} from "../App/App"
import {Dialog, DialogContent, Divider} from "@material-ui/core"
import {RoutePaths} from "../../app/routes/Routes"
import {observer} from "mobx-react"
import {AgreementContent} from "./TestTakerAgreementContent"

interface TestTakerAgreementModalProps {
    testName: string
    agree: () => void
}
@observer
class TestTakerAgreementModal extends React.Component<TestTakerAgreementModalProps, {}> {
    constructor(props: TestTakerAgreementModalProps) {
        super(props)
    }
    // async UNSAFE_componentWillMount() {
    //     authStore.didTheyAgree()
    // }

    async componentDidUpdate() {
        await authStore.didTheyAgree()
    }

    agreeToEULA = () => {
        this.props.agree()
    }
    disagree = () => {
        const authUser = authStore && authStore.auth ? authStore.auth : null
        const isSSOLogin = authUser && authUser.ssoId ? true : false

        if (isSSOLogin) avantHistory.push(RoutePaths.SSO_STUDENT)
        else HelperService.logout(undefined)
    }
    renderDialogContent = (testName: string): JSX.Element => {
        return <AgreementContent testName={testName} agreeToEULA={this.agreeToEULA} />
    }
    render() {
        return (
            <Dialog
                open={!authStore.agreedToEULA}
                aria-labelledby="form-dialog-title"
                PaperProps={{
                    style: {
                        width: "39.2em",
                        maxHeight:"100%",
                        boxShadow: "0px 9px 46px 8px rgb(0 0 0 / 12%)"
                    }
                }}
            >
                <Divider style={{width: "100%"}} />
                <DialogContent style={{ padding: "24px 32px" }}>{this.renderDialogContent(this.props.testName)}</DialogContent>
            </Dialog>
        )
    }
}
export default TestTakerAgreementModal
