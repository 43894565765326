/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import React from "react"
import Dropzone from "react-dropzone"
import {ItemContent} from "../../app/common/item/models/ItemContent"
import {ProductDriver} from "../../app/common/products/ProductStore"
import ApiService from "../../services/ApiService"
import HelperService from "../../services/HelperService"
import {ApiUpdateItemFileResponse, IItem, ItemFormatEnum, LoginProductContentAreaData, VideoContent} from "../../types/types"
import {JEENIE_PRODUCT_ID, RTL_CONTENT_AREA_IDS} from "../../util/Constants"
import {ItemContentId} from "../../validation/ValidPrimaryKey"
import AudioPlayer from "../AudioPlayer/AudioPlayer"
import Button from "../Button/Button"

interface InstructionAudioProps {
    className: string
    item: IItem
    itemContent: ItemContent
    takePanelId: number
    audio?: File
    isAdmin: boolean
    driver: ProductDriver
    languageId: number
    languageName: string
    product: LoginProductContentAreaData
    handleUpdateItemFile: (itemContentId: number, content: string | VideoContent) => void
}

interface InstructionAudioState {
    editingAudio: boolean
    audio?: File
}

export const contentAreaIdToListenText = new Map<number, string>([
    [18, "انقر علي الزر أدناه للاستماع إلى السؤال في العربية."],
    [22, "單擊下面的按鈕聽提示"],
    [6, "请点击下面的按钮听中文。"],
    [7, "單擊下面的按鈕聽提示"],
    [3, "Cliquez le bouton ci-dessous pour écouter en français."],
    [4, "Klicke unten drauf, um den Text auf Deutsch anzuhören."],
    [9, "לחץ על הכפתור למטה כדי להאזין להנחיה בעברית"],
    [10, "हिंदी में सुनने के लिए नीचे दिए गए बटन पर क्लिक करें।"],
    [12, "Cliccare sul bottone qui sotto per ascoltare il domanda in italiano."],
    [1, "日本語で聞くには下のボタンをクリックしてください。"],
    [15, "한국어로 들으려면 아래의 버튼을 클릭하세요."],
    [39, "Kliknij poniższy przycisk, aby wysłuchać monitu po polsku."],
    [21, "Нажмите на кнопку ниже, чтобы услышать вопросы на русском языке."],
    [2, "Haz clic en el botón de abajo para escuchar en español."],
    [33, "Clique no botão abaixo para escutar em português."],
    [104, "በአማርኛ ለመስማት ከዚህ በታች ያለውን ቁልፍ ይጫኑ።"],
    [102, "Սեղմեք ներքևի կոճակը՝ հայերեն լսելու համար:"],
    [106, "Click the button below to listen in Chin (Hakha)."],
    [111, "Kopwene tiki ena féún pwáchen aa nómw faan ren ómw kopwe tongeni rongorong ngeni nón kapasen Chuuk."],
    [107, "Klikněte na tlačítko níže pro poslech v češtině."],
    [46, "I-click ang button sa ibaba para makinig sa Filipino (Tagalog)"],
    [101, "Klike sou bouton anba a pou koute an Kreyòl."],
    [116, "E kaomi ʻia ke pihi o lalo iho nei e hoʻolohe ai i ka ʻōlelo Hawaiʻi."],
    [105, "Nias rau lub pob haud qab no thiaj li mloog tau ua lus Hmoob."],
    [100, "Iklikmo ti pipindutan iti baba tapno mangngegam iti Ilokano."],
    [115, "ಕನ್ನಡದಲ್ಲಿ ಕೇಳಲು ಕೆಳಗಿನ ಬಟನ್ ಅನ್ನು ಒತ್ತಿ"],
    [109, " मराठीमध्ये ऐकण्यासाठी खालील बटणावर क्लिक करा"],
    [110, "Jiped button n̗e bwe kwōn ron̄jake kōmmel̗el̗e kan̗e ilo kajin M̗ajel̗."],
    [103, "Oomi le fa’amau o lo’o i lalo ina ia faalogologo ai i le Gagana Samoa."],
    [93, "Guji badhanka hoose si aad u dhageysato"],
    [94, "Kan wali in qoraaye hab si dhaqsa eh"],
    [35, "அடுத்து வரும் கேள்வியைத் தமிழில் கேட்க கீழ்வரும் வலைப்பக்க பொத்தானை அழுத்துங்கள் (கிளிக் செய்யுங்கள்)."],
    [108, "తెలుగులో జాబితా చేయడానికి క్రింది బటన్‌పై క్లిక్ చేయండి."],
    [8, "Türkçe ifadeyi dinlemek için aşağıdaki düğmeyi tıklayın."],
    [14, "اردو میں سننے کے لئے نیچے دیئے گئے بٹن پر کلک کریں۔"],
    [31, "Nhấn vào nút bên dưới để nghe bằng tiếng Việt."],
    [92, "Negaresgu kana negarcaraq, Yugtun qalarcarat niicugniniaraten."],
    [13, "Tẹ orí bọtinì ìsàlẹ̀ yìí láti tẹ́tí sílẹ̀ ní Yoruba."],
    [117, "Zomi kampau na ngaih nadingin anuai-a abawk pen meek in."],
    [5, "Click the button below to listen in English."],
    [113, "Haz clic en el botón de abajo para escuchar en español."],
    [119, "Haz clic en el botón de abajo para escuchar en español."],
    [123, "НАТИСНІТЬ НА КНОПКУ НИЖЧЕ, ЩОБ ПРОСЛУХАТИ ЗАВДАННЯ УКРАЇНСЬКОЮ МОВОЮ"],
    [24, "বাংলায় শোনার জন্যে নিচের বোতাম টেপো"],
    [34, "ਪੰਜਾਬੀ ਵਿੱਚ ਸੁਣਨ ਲਈ ਹੇਠਾਂ ਦਿੱਤੇ ਬਟਨ ’ਤੇ ਕਲਿੱਕ ਕਰੋ"],
    [30, "กดปุ่มด้านล่างเพื่อฟังภาษาไทย"],
    [17, "برای گوش دادن به زبان فارسی روی دکمه زیر کلیک کنید"],
    [133, "नेपालीमा सुन्नको लागि तलको बटनमा क्लिक गर्नुहोस।"],
    [134, "ብትግርኛ ንምስማዕ ኣብ ታሕቲ ዘሎ ቁልፊ ጠውቑ"],
    [131, "په پښتو ژبه اوریدو لپاره په لاندې تڼۍ کلیک وکړئ."],
    [132, "ចំណុច​បូតុងខាងក្រោមដើម្បី​ស្តាប់ជា​ភាសាខ្មែរ"],
])

export default class InstructionAudio extends React.Component<InstructionAudioProps, InstructionAudioState> {

    constructor(props: InstructionAudioProps) {
        super(props)
        this.state = {
            editingAudio: false,
            audio: props.audio
        }
    }

    onDropAudio = (audio: File[]) => {
        this.setState({audio: audio[0]})
    }

    cancelAudioEdit = () => {
        this.setState({editingAudio: false, audio: undefined})
    }

    saveAudio = (itemContentId: number) => {
        const type = "mp3sound"
        const languageId = this.props.languageId.toString()
        if (this.state.audio) {
            ApiService
                .updateItemFile(new ItemContentId(itemContentId), this.state.audio, languageId, type)
                .then((updateItemFileResponse: ApiUpdateItemFileResponse) => {
                    const fileName: string = updateItemFileResponse.fileName
                    const fileUrl = HelperService.getFullMediaUrl(
                        languageId,
                        type,
                        fileName,
                        this.props.driver.config.MEDIA_BASE_URL
                    )
                    this.props.handleUpdateItemFile(itemContentId, fileUrl)
                    this.setState({editingAudio: false})
                })
        }
    }

    editAudio = () => {
        this.setState({editingAudio: true})
    }

    render() {
        const {className, itemContent, takePanelId, item, isAdmin, languageId, languageName, product} = this.props
        const {editingAudio, audio} = this.state

        const audioClassName = editingAudio
            ? `${className} audio-button drop-zone`
            : `${className} audio-button mp3sound-wrapper`
        const isWrOrSp = item.format === ItemFormatEnum.WRITING || item.format === ItemFormatEnum.SPEAKING_ORAL

        let listText = contentAreaIdToListenText.get(languageId)
        if (listText === undefined) {
            listText = `Click the button below to listen in ${languageName}.`
        }

        if (product.productId === JEENIE_PRODUCT_ID.value()) {
            if (languageId === 5) {
                listText = contentAreaIdToListenText.get(6)
            } else if (languageId === 6) {
                listText = "Click the button below to listen in English."
            }
        }

        let textClass = "instruction-audio__text"
        textClass += RTL_CONTENT_AREA_IDS.includes(languageId) ? " instruction-audio__rtl" : ""

        return (
            <div key={itemContent.id} className={audioClassName}>
                {editingAudio ? (
                    <>
                        <Dropzone accept="audio/mp3" onDrop={this.onDropAudio}>
                            <p className="drop-zone-text">Drop a mp3 here or click to select a mp3 to upload</p>
                            {audio && <p className="drop-zone-text">{audio.name}</p>}
                        </Dropzone>
                        <Button
                            className="avant-button--blue-on-white avant-button--small margin-right"
                            onClick={this.cancelAudioEdit}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="avant-button--primary avant-button--small"
                            onClick={() => this.saveAudio(itemContent.id)}
                        >
                            Save
                        </Button>
                    </>
                ) : (
                    <>
                        {isAdmin ? (
                            <Button className="avant-button--primary avant-button--small" onClick={this.editAudio}>
                                Edit
                            </Button>
                        ) : null}
                        {isWrOrSp && <p className={textClass}>{listText}</p>}
                        <AudioPlayer
                            id={itemContent.id}
                            src={itemContent.content as string}
                            // For Items like ListItems in Admin Panel Item Search take panel id will be null, and we don't need to limit # of audio plays
                            maxPlays={ takePanelId == null ? undefined : {
                                maxPlays: 2,
                                takePanelId,
                                itemId: item.id
                            }}
                            buttonClassName="avant-button--audio-player"
                            iconClassName="avant-button--audio-player__icon"
                            audioClassName={audioClassName}
                        />
                    </>
                )}
            </div>
        )
    }
}
