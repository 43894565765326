import * as React from "react"
import {Grid, makeStyles, MenuItem, Paper, Select, Switch} from "@material-ui/core"
import {H2Text, SmallLabelText} from "../../../../styles/AvantTypography"
import { SSOLicenseCount} from "../../../../types/types"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import {ChangeEvent, useEffect} from "react"
import {useState} from "react";
import productPackageStore from "../../../common/ProductPackage/ProductPackageStore";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}))

interface SSOBulkProductReservationProps {
    handleBulkApplyButtonClick: () => void
    handleBulkPackageChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
    handleBulkHandwrittenChange: (event: ChangeEvent<HTMLInputElement>) => void
    isHandwrittenSold: boolean
    isWritingInPackage: (packageVal?: string | number) => boolean
    licenses_sold: SSOLicenseCount[] | null
    selectedPkgValue:string
    selectedHwValue:boolean
    totalCount: number
}

export const SSOBulkProductReservation: React.FC<SSOBulkProductReservationProps> = (props) => {
    const classes = useStyles()

    const [selectedPkgValue, setSelectedPkgValue] = useState(props.selectedPkgValue)

    useEffect(() => {
        productPackageStore.getListOfProductPackages();
    }, [])

    return (
        <Paper elevation={3} className={classes.paper}>
            <H2Text>Bulk Product Reservation</H2Text>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <SmallLabelText>Test Package</SmallLabelText>
                    <Select
                        value={selectedPkgValue}
                        onChange={(e) => {
                            let newValue = String(e.target.value)
                            setSelectedPkgValue(newValue)

                            props.handleBulkPackageChange(e)
                        }}
                    >
                        <MenuItem key={"0"} value={"0"}>Select Test Package</MenuItem>)
                        { props.licenses_sold && (
                            props.licenses_sold
                                // We never want to include a service license (sv-...) here;
                                // the only one expected (but still to be excluded here) is Handwritten (sv-hw):
                                .filter(license => license.productPackage == null || !license.productPackage.startsWith("sv-"))
                                // hw is an outdated code for Handwritten; we don't expect to get it here,
                                // but we don't want to include it if we do:
                                .filter(license => license.productPackage !== "hw")
                                .map ((license) => {
                                    const displayValue = (license.productPackage ? (productPackageStore.productPackages && productPackageStore.productPackages.find((pp) => pp.code === license.productPackage)?.longName || "") : "")
                                    return (
                                        <MenuItem
                                            key={license.productPackage ? license.productPackage : ""}
                                            value={license.productPackage ? license.productPackage : ""}
                                        >
                                            {displayValue}
                                        </MenuItem>
                                    )
                                })
                        )}
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    <SmallLabelText>Allow Handwriting</SmallLabelText>
                    <Switch
                        onChange={(e) => {
                            props.handleBulkHandwrittenChange(e)
                        }}
                        checked={props.selectedHwValue}
                        disabled={props.isHandwrittenSold === false || props.isWritingInPackage(selectedPkgValue) === false}
                        inputProps={{"aria-label": "Use for bulk change for the handwritten setting"}}
                        color={"primary"}
                    />
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Tooltip title={props.totalCount <= 0 ? "Use the checkboxes to select class groups to change." : "Click to apply these settings to the selected class groups."}>
                        <span>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={props.totalCount <= 0}
                                onClick={props.handleBulkApplyButtonClick}
                            >
                                Apply to Selected
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Paper>

    )
}
