import React, {useEffect, useState} from "react"
import ApiService from "../../../../services/ApiService"
import {RouteComponentProps} from "react-router"
import {withRouter} from "react-router-dom"
import {loadingSpinnerStore} from "../../../common/loaders/LoadingSpinnerStore"
import {AxiosResponse} from "axios"
import {Box, Table, TableBody, TableCell, TableRow} from "@material-ui/core"
import ReactToPrint from "react-to-print"
import {AvantPopperButton} from "../../../generic-components/AvantPopperButton"
import {Print} from "@material-ui/icons"
import {AvantExpansionPanel} from "../../../generic-components/AvantExpansionPanel"
import {AvantColors} from "../../../../styles/AvantColors"
import {ReportPrintFooter} from "../../common/components/ReportPrintFooter"
import {LevelBenchmarks} from "../../../../util/Constants"
import {ApiErrorResponse, PanelSkillEnum, PanelSkillFriendlyNameEnum} from "../../../../types/types"
import {Header, HEADER_HEIGHT} from "../../../../components/Header/Header"
import {REPORTS_MAX_WIDTH, reportsPadding} from "../../common/components/ReportsPage"
import {theme} from "../../../../styles/MuiThemes"
import {screenUtil} from "../../../../util/ScreenUtil"
import {ReportPrintBrand} from "../../common/components/ReportPrintBrand"
import {H1Text} from "../../../../styles/AvantTypography"
import {LabelAndText} from "./IndividualReportHeader"
import {messageStore} from "../../../common/messages/MessageStore"
import { contentAreaStore } from "../../../common/contentArea/ContentAreaStore"

interface CredlyIndividualReportData {
    firstname: string
    lastname: string
    content_area_id: number
    composite_score: number
    levels: Map<string, string>
    badge_image: string
}

interface CredlyIndividualReport {
    reportData: CredlyIndividualReportData | null
    includeBrand: boolean
    print?: boolean
}

interface ReportProps {
    takeUuid: string
}

interface Props extends RouteComponentProps<ReportProps> {}

const skillsToFriendlyName: Map<string, string> = new Map([
    [PanelSkillEnum.READING, PanelSkillFriendlyNameEnum.READING],
    [PanelSkillEnum.WRITING, PanelSkillFriendlyNameEnum.WRITING],
    [PanelSkillEnum.LISTENING, PanelSkillFriendlyNameEnum.LISTENING],
    [PanelSkillEnum.SPEAKING, PanelSkillFriendlyNameEnum.SPEAKING],
])

const CredlyIndividuaReportContainer: React.FC<Props> = (props) => {
    const takeUuid = props.match.params.takeUuid
    const [reportData, setReportData] = useState<CredlyIndividualReportData | null>(null)

    useEffect(() => {
        loadingSpinnerStore.hideLoadingSpinner = false
        ApiService.get(`${ApiService.API_URL}credly/individual-report/${takeUuid}`)
            .then((response: AxiosResponse<CredlyIndividualReportData>) => {
                setReportData(response.data)
                loadingSpinnerStore.hideLoadingSpinner = true
            })
            .catch((err: ApiErrorResponse) => {
                messageStore.setErrorMessage(`Error retrieving report data: ${err}`)
            })
    }, [])

    return (
        <>
            <Header isCredly={true}/>
            <Box
                style={{
                    padding: reportsPadding,
                    paddingTop: HEADER_HEIGHT + theme.spacing(4),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: screenUtil.screenWidth > REPORTS_MAX_WIDTH + reportsPadding * 2 ? "center" : undefined,
                }}
            >
                <CredlyIndividualReport reportData={reportData} includeBrand={true} />
            </Box>
        </>
    )
}

const CredlyIndividualReport: React.FC<CredlyIndividualReport> = (props: CredlyIndividualReport) => {
    const {reportData, includeBrand, print} = props

    let componentRef: any
    const setRef = (ref: any) => componentRef = ref

    let reportPrintBrand = <></>
    if (includeBrand) {
        reportPrintBrand = <ReportPrintBrand/>
    }

    if (reportData == null) {
        return <></>
    }

    return (
        <Box width={969} margin={"0 auto"}>
            {print == null && (
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Box width={1} mb={7.4}/>
                    <ReactToPrint
                        trigger={() => <AvantPopperButton title={<Print/>} />}
                        content={() => componentRef}
                        pageStyle={"@media print {margin: 0.5in}"}
                    />
                </Box>
            )}

            <div ref={setRef} style={{display: "flex", flexDirection: "column"}}>
                {reportPrintBrand}
                <Box>
                    <Box
                       display={"flex"}
                       alignItems={"center"}
                       justifyContent={"space-evenly"}
                       width={"100%"}
                   >
                        <Box>
                            <H1Text>{reportData.firstname} {reportData.lastname}</H1Text>
                            <LabelAndText label={"Language"} text={contentAreaStore.convertContentAreaIdToLanguageName(reportData.content_area_id)}/>
                        </Box>
                        <Box display={"flex"} justifyContent={"center"}>
                            <img alt={"The badge that this test taker has earned"} src={reportData.badge_image} width={300} height={300}/>
                        </Box>
                    </Box>
                </Box>
                <AvantExpansionPanel title={"Composite Score"} score={reportData.composite_score.toString()}>
                    <Table>
                        <TableBody>
                            {
                                Object.entries(reportData.levels).map((level, index: number) => {
                                    const symbol = level[0]
                                    const panelLevel = level[1]

                                    return (
                                        <TableRow
                                            style={{backgroundColor: (index + 1) % 2 === 0 ? AvantColors.REPORT_BACKGROUND_GRAY_DARK : ""}}
                                            key={symbol}
                                        >
                                            <TableCell>
                                                {skillsToFriendlyName.get(symbol)}
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                <Box component={"strong"} style={{marginRight: "5rem"}}>{panelLevel} - ACTFL {LevelBenchmarks[panelLevel]}</Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </AvantExpansionPanel>
                <ReportPrintFooter/>
            </div>
        </Box>
    )
}

export default withRouter(CredlyIndividuaReportContainer)
