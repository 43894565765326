import React from "react"
import { Tooltip } from "@material-ui/core"
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';


interface IComponentProps {
    onClick: (ev: React.MouseEvent<HTMLDivElement>) => void;
    addButtonFlag: boolean;
}

export default class AddToRecordingIcon extends React.Component<IComponentProps, {}> {
    addRecord = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
        if (this.props.addButtonFlag !== true) {
            this.props.onClick(e as React.MouseEvent<HTMLDivElement>); 
        }
    }
    render() {
        return (
            <Tooltip title={<span className="tooltip-text">You can only add to the recording once.</span>} placement="top" className={"AddToRecordingIcon__tooltip"}>
                <div className={this.props.addButtonFlag ? "AddToRecordingIconGrey" : "AddToRecordingIcon"} onClick={this.addRecord} tabIndex={0} onKeyPress={this.addRecord}>
                    {this.props.addButtonFlag ? (<div className={"AddToRecordingIcon__icon"}>
                        <AddToPhotosIcon className="AddToRecordingIcon__icon" />
                    </div>) : (<div className={"AddToRecordingIcon__icon"}>
                        <AddToPhotosIcon />
                    </div>)}


                    <span className={"AddToRecordingIcon__text"}>Add</span>
                </div>
            </Tooltip>

        )
    }
}
