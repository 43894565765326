import {ReportTakeRow} from "../../class/models/ReportTakeRow"
import {PlaceTestTakerInstanceReport} from "../../common/components/AvantNewDatePicker/AvantNewDateRangePickerHelpers"
import {PanelBenchmarkConstants, PanelLevel, PanelSkillEnum, PanelSkillOrder} from "../../../../types/types"
import {TimeUtil} from "../../../../util/TimeUtil"
import {GroupData} from "../../individual/components/IndividualReportHeader"
import {GroupType} from "../../../common/group/models/GroupMaster"
import { contentAreaStore } from "../../../common/contentArea/ContentAreaStore"

export interface PLACEIndividualReport {
    cando_1: number | null
    cando_2: number | null
    cando_3: number | null
    cando_4: number | null
    cando_5: number | null
    candoAverage?: string
    email: string | null
    familyLanguageId: number | null,
    familyMembers: string[]
    famSpeaksTestLang: string | null,
    finishDate: string
    firstLanguage: string | null
    firstName?: string
    grade: number | null
    grades: string[]
    groupData: GroupData[]
    language: string
    lastName?: string
    levels: [string, string | number][],
    login: string
    monthsAbroad: string | null,
    score?: string
    takeId: number
    tests: string[],
    years: number | null
    yearsSpoken: string | null,
    product_package?: string,
    total_average?: string,
}

export default class PlaceReportUtils {

    // static findVBalByKeyIfExists(obj: Map<string, (string | number | null)>, desiredKey: string): string {
    static findVBalByKeyIfExists(obj: object, desiredKey: string): string {
        let val = undefined

        if (obj && obj.hasOwnProperty(desiredKey)) {
            val = obj[desiredKey]
        }

        return val != null ? val.toString() : ""
    }

    static formattedTests(tests: string[]):string {
        if(tests.filter(t => {
            return t != ''
        }).length === 0){
            return "None"
        }
        return tests.map((test:string)=> {
                switch(test) {
                    case "test_ap": {
                        return "AP"
                    }
                    case "test_ib": {
                        return "IB"
                    }
                    case "test_satii": {
                        return "SAT II"
                    }
                    case "test_stamp": {
                        return "STAMP"
                    }
                    case "test_opi": {
                        return "OPI"
                    }
                    case "test_wpt": {
                        return "WPT"
                    }
                    case "test_opic": {
                        return "Opic"
                    }
                    default: {
                        return
                    }
                }
            }
        ).join(", ")
    }

    static convertPlaceInstanceReportToReportTakeRow(placeReport: PlaceTestTakerInstanceReport[]): ReportTakeRow[] {

        const result: ReportTakeRow[] = []

        placeReport.forEach(placeInstance => {

            const diLevel = this.findVBalByKeyIfExists(placeInstance.levels_raw, "DI")
            const grLevel = this.findVBalByKeyIfExists(placeInstance.levels_raw, "GR")
            const lsLevel = this.findVBalByKeyIfExists(placeInstance.levels_raw, "LS")
            // const lsScaledScore = this.findVBalByKeyIfExists(placeInstance.scaledscore, "LS")
            const rdLevel = this.findVBalByKeyIfExists(placeInstance.levels_raw, "RD")
            // const rdScaledScore = this.findVBalByKeyIfExists(placeInstance.scaledscore, "RD")
            const scLevel = this.findVBalByKeyIfExists(placeInstance.levels_raw, "SC")
            const spLevel = this.findVBalByKeyIfExists(placeInstance.levels_raw, "SP")
            const trLevel = this.findVBalByKeyIfExists(placeInstance.levels_raw, "TR")
            const vcLevel = this.findVBalByKeyIfExists(placeInstance.levels_raw, "VC")
            const voLevel = this.findVBalByKeyIfExists(placeInstance.levels_raw, "VO")
            const wrLevel = this.findVBalByKeyIfExists(placeInstance.levels_raw, "WR")
            const locationGroup = placeInstance.group_data.find(group => group.type === GroupType.LOCATION)

            const inst: ReportTakeRow = {
                location: locationGroup ? locationGroup.name : "",
                firstName: placeInstance.firstname != null ? placeInstance.firstname : "No First Name",
                lastName: placeInstance.lastname != null ? placeInstance.lastname : "No Last Name",
                language: placeInstance.contentareaid.toString(),
                resumeKey: placeInstance.code,
                grammarScore: grLevel === "" ? undefined : grLevel,
                readingScore: rdLevel === "" ? undefined : rdLevel,
                // readingScaledScore: rdScaledScore === "" ? "" : rdScaledScore,
                writingScore: wrLevel === "" ? undefined : wrLevel,
                listeningScore: lsLevel === "" ? undefined : lsLevel,
                // listeningScaledScore: lsScaledScore === "" ? undefined : lsScaledScore,
                speakingScore: spLevel === "" ? undefined : spLevel,
                sentenceCompletionScore: scLevel === "" ? undefined : scLevel,
                translationScore: trLevel === "" ? undefined : trLevel,
                verbConjugationScore: vcLevel === "" ? undefined : vcLevel,
                vocabularyScore: voLevel === "" ? undefined : voLevel,
                dictationScore: diLevel === "" ? undefined : diLevel,
                compositeScore: placeInstance.compositescore,
                startDate: placeInstance.assessment_start_time,
                finishDate: placeInstance.assessment_finish_time != null ? placeInstance.assessment_finish_time : undefined,
                testDuration: placeInstance.duration != null ? Math.ceil(placeInstance.duration) : undefined,
                takeId: placeInstance.takeid,
                handwritten: false,
                candoAverage: (placeInstance.profile != null && placeInstance.profile.candoAverage != null) ? placeInstance.profile.candoAverage : undefined,
                product_package: placeInstance.product_package != null ? placeInstance.product_package : undefined,
                examData: []
            }

            result.push(inst)

        })

        return result
    }

    static getPanelBenchmark(panelLevel: string): string {
        if (panelLevel === PanelLevel.PANEL_STARTED) {
            return PanelBenchmarkConstants.PANEL_IN_PROGRESS
        }
        if (panelLevel === PanelLevel.PANEL_NOT_RATABLE) {
            return PanelBenchmarkConstants.PANEL_NOT_RATABLE
        }
        if (panelLevel === PanelLevel.PANEL_COMPLETE) {
            return PanelBenchmarkConstants.PANEL_TEST_COMPLETE
        }

        return panelLevel
    }

    static buildPlaceIndividualReport(data: PlaceTestTakerInstanceReport): PLACEIndividualReport {
        const placePanelSkills = [
            PanelSkillEnum.READING,
            PanelSkillEnum.GRAMMAR,
            PanelSkillEnum.WRITING,
            PanelSkillEnum.SPEAKING
        ]
        const shlPanelSkills = [
            PanelSkillEnum.VOCABULARY,
            PanelSkillEnum.DICTATION,
            PanelSkillEnum.TRANSLATION,
            PanelSkillEnum.SENTENCE_COMPLETION,
            PanelSkillEnum.VERB_CONJUGATION,
            PanelSkillEnum.WRITING,
            PanelSkillEnum.SPEAKING
        ]

        const rawLevels = {}
        if (data.levels_raw) {
            Object.entries(data.levels_raw).forEach(level => {
                rawLevels[level[0]] = level[1]
            })
        }

        const reportLevels: [string, string | number][] = []
        // The data only includes takepanels that have actually been tested,
        // so we're adding all of the skills associated with PLACE family tests.
        if (data.product_package && data.product_package.includes("pl")) {
            placePanelSkills.forEach((skill: PanelSkillEnum) => {
                let level: string = rawLevels[skill] ? this.getPanelBenchmark(rawLevels[skill]) : PanelBenchmarkConstants.PANEL_NOT_STARTED
                // PLACE B and C do not show Speaking in the individual reports
                if (skill == PanelSkillEnum.SPEAKING && (data.product_package == "plb" || data.product_package == "plc")) {
                    return
                }
                // WRITING is RBE for PLACE C and SHL C
                if (skill == PanelSkillEnum.WRITING && data.product_package == "plc") {
                    console.log('place c')
                    level = PanelBenchmarkConstants.PANEL_RATED_BY_EDUCATOR
                }

                reportLevels.push([skill, level])
            })
        }

        if (data.product_package && data.product_package.includes("shl")) {
            shlPanelSkills.forEach((skill: PanelSkillEnum) => {
                let level: string = rawLevels[skill] && this.getPanelBenchmark(rawLevels[skill])

                if (!level) {
                    level = PanelBenchmarkConstants.PANEL_NOT_STARTED
                    if (data.assessment_finish_time) {
                        level = PanelBenchmarkConstants.PANEL_EARLY_EXIT
                    }
                }

                // SHL B and C do not show Speaking in the individual reports
                if (skill == PanelSkillEnum.SPEAKING && (data.product_package == "shl-b" || data.product_package == "shl-c")) {
                    return
                }

                // WRITING is RBE for PLACE C and SHL C
                if (skill == PanelSkillEnum.WRITING && data.product_package == "shl-c") {
                    level = PanelBenchmarkConstants.PANEL_RATED_BY_EDUCATOR
                }

                reportLevels.push([skill, level])
            })
        }

        reportLevels.sort((a,b) => {
            return PanelSkillOrder.indexOf(a[0] as PanelSkillEnum) - PanelSkillOrder.indexOf(b[0] as PanelSkillEnum)
        })

        return {
            cando_1: data.profile ? data.profile.cando_1 : null,
            cando_2: data.profile ? data.profile.cando_2 : null,
            cando_3: data.profile ? data.profile.cando_3 : null,
            cando_4: data.profile ? data.profile.cando_4 : null,
            cando_5: data.profile ? data.profile.cando_5 : null,
            candoAverage: data.profile && (data.profile.candoAverage || data.profile.candoAverage === 0) ? data.profile.candoAverage.toString() : undefined,
            email: data.profile ? data.profile.testTakerEmail : null,
            familyLanguageId: data.profile ? data.profile.familyLanguageId : null,
            familyMembers: data.profile ? data.profile.familymembers : [],
            famSpeaksTestLang: data.profile ? data.profile.famSpeaksTestLang : null,
            finishDate: data.assessment_finish_time ?
                TimeUtil.formatMilliToMonthDayYearLong(data.assessment_finish_time) :
                "",
            firstLanguage: data.profile ? data.profile.firstLang : null,
            firstName: data.firstname,
            grade: data.profile ? data.profile.grade : null,
            grades: data.profile ? data.profile.grades : [],
            groupData: data.group_data,
            language: contentAreaStore.convertContentAreaIdToLanguageName(data.contentareaid),
            lastName: data.lastname,
            levels: reportLevels,
            login: data.code,
            monthsAbroad: data.profile ? data.profile.monthsAbroad : null,
            score: data.compositescore ? data.compositescore : undefined,
            takeId: data.takeid,
            tests: data.profile ? data.profile.tests : [],
            years: data.profile ? data.profile.years : null,
            yearsSpoken: data.profile ? data.profile.yearsSpoken : null,
            product_package: data.product_package != null ? data.product_package : undefined,
            total_average: data.total_average,
        }
    }
}
