import {MenuItem, Select} from "@material-ui/core"
import * as React from "react"
import { SSOLicenseCount, SsoTeacherClass} from "../../../../types/types"
import {ChangeEvent} from "react"
import productPackageStore from "../../../common/ProductPackage/ProductPackageStore";

interface SSOBulkActionProps {
    rowData: SsoTeacherClass,
    tableId: number,
    selectedPackage: string | null,
    handleChange: (e:ChangeEvent<{name?:string, value:unknown}>, rowData: any, index: number) => void
    licenses_sold: SSOLicenseCount[] | null

}

export const LicenseSelect: React.FC<SSOBulkActionProps> = (props) => {
    // @ts-ignore
    return (
        <Select id="testingLanguage" value={(props.selectedPackage) ? props.selectedPackage : 0} onChange={(e) => props.handleChange(e, props.rowData, props.tableId)}>
            <MenuItem key={0} value={0}>Select Test Package</MenuItem>)
            { props.licenses_sold && (
                props.licenses_sold
                    // We never want to include a service license (sv-...) here;
                    // the only one expected (but still to be excluded here) is Handwritten (sv-hw):
                    .filter(license => license.productPackage == null || !license.productPackage.startsWith("sv-"))
                    // hw is an outdated code for Handwritten; we don't expect to get it here,
                    // but we don't want to include it if we do:
                    .filter(license => license.productPackage !== "hw")
                    .map ((license) => {
                        const displayValue = (license.productPackage ? (productPackageStore.productPackages && productPackageStore.productPackages.find((pp) => pp.code === license.productPackage)?.longName) : "Product package not found")
                        return (
                            <MenuItem
                                key={license.productPackage ? license.productPackage : ""}
                                value={license.productPackage ? license.productPackage : ""}
                            >
                                {displayValue}
                            </MenuItem>
                        )
                    })
            )}
        </Select>
    )
}
