/*
 * ******************************************************
 *  * Copyright (C) 2010-Present Avant Assessment
 *  * All Rights Reserved
 *  ******************************************************
 */

import React, {useCallback, useEffect} from "react"
import {
    DataGridPro,
    GridCellParams,
    GridColDef,
    GridFilterModel,
    GridPaginationModel,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridRowSelectionModel,
    GridSortModel,
    GridToolbar,
    // useGridApiRef
} from "@mui/x-data-grid-pro"
import {ContentArea, contentAreaStore} from "../../../../app/common/contentArea/ContentAreaStore"
import {observer} from "mobx-react"
import {AdminLoginTableData, MuiXPage} from "../../../../types/rest/LoginTypes"
import ApiService from "../../../../services/ApiService"
import moment from 'moment'
import {
    Select, MenuItem, SelectChangeEvent, Button
} from "@mui/material"
import {makeStyles} from "@mui/styles"
import LoginTableNewUtilities from "./LoginTableNewUtilities"
import {UserType} from "../../../../app/common/authentication/models/UserType"
import {
    TeacherPermissionsObject,
    PermissionsInterface,
    TestTakerPermissionsObject
} from "../LoginTool"
import {Stack} from "@mui/system"
import {themeV5} from "../../../../styles/MuiThemes"
import {RendersSingleClickBooleanPermissions} from "../Components/RenderSingleClickBoolean4Dg"

export interface NewQueryParams {
    username?: string
    proctorType?: string
    rostered?: boolean
    handwritten?: boolean
    active?: boolean
    allowTest?: boolean
    contentAreaId?: number
    pageData: MuiXPage
    sort: string
    userType?: string
    filterModel?: GridFilterModel
}

const useStyles = makeStyles((theme) => ({
    customSelectContainer: {
        width: '100%', // Ensure the container takes full width
        height: '80%', // Ensure the container takes full height
        display: 'flex',
        alignItems: 'center',
        margin: 0,
    },
    customSelect: {
        width: '100%', // Ensure the Select takes full width
    },
    inputLabel: {
        flex: 1,
        // justifyContent: "center",
        flexDirection: 'column',
        justifyContent: 'space-between',
    }
}))


interface LoginTableNewProps {
    setSelectedIds: (selectedIds: AdminLoginTableData[]) => void
}

export const LoginTableNew: React.FC<LoginTableNewProps> = observer((props) => {

    // @ts-ignore
    const classes = useStyles()

    // const apiGridRef = useGridApiRef()

    const [queryParams, setQueryParams] = React.useState<NewQueryParams>(
        {
            username: undefined,
            proctorType: undefined,
            rostered: undefined,
            handwritten: undefined,
            active: undefined,
            allowTest: undefined,
            contentAreaId: undefined,
            pageData: {
                pageNumber: 0,
                size: 10,
                totalPages: 0,
                totalElements: 0
            },
            sort: "username,asc",
            userType: "S",
            filterModel: undefined
        }
    )

    const [tableData, setTableData] = React.useState<AdminLoginTableData[]>([])

    const [dataLoading, setDataLoading] = React.useState<boolean>(false)

    const [contentAreasLoading, setContentAreasLoading] = React.useState<boolean>(true)

    const [rowCount, setRowCount] = React.useState<number>(0)

    const [selectedLanguageFilter, setSelectedLanguageFilter] = React.useState<ContentArea | undefined>(undefined)

    useEffect(() => {

        console.log("Uses Effect Fired")
        setContentAreasLoading(contentAreaStore.loading)
        fetchData(queryParams)

    }, [])

    const fetchData = (query: NewQueryParams) => {
        setDataLoading(true)
        ApiService.getAdminToolLoginTableData(query).then((data) => {
            setTableData(data.tableData)
            setRowCount(data.totalElements)
            setDataLoading(false)
        })
    }

    const onSortChange = (newSortModel: GridSortModel) => {
        console.log("newSortModel", newSortModel)
        if (newSortModel.length === 0) {
            return
        }
        const curQueryParams: NewQueryParams = {...queryParams, sort: `${newSortModel[0].field},${newSortModel[0].sort}`}
        setQueryParams({...queryParams, sort: `${newSortModel[0].field},${newSortModel[0].sort}`})

        fetchData(curQueryParams)
    }

    const onQueryChange = useCallback((newQueryFilterOptions: GridFilterModel) => {

        let userName: string | undefined = undefined
        let userType: string | undefined = undefined
        let proctorType: boolean | undefined = undefined

        newQueryFilterOptions.items.forEach((item) => {
            if (item.field === "username") {
                userName = item.value as string
            }

            if (item.field === "userType") {
                userType = item.value as string
            }
            if (item.field === "proctorType") {
                proctorType = item.value as boolean
            }
        })
        const curQueryParams = {...queryParams, username: userName, userType: userType, proctorType: proctorType, filterModel: newQueryFilterOptions}
        setQueryParams({...queryParams, username: userName, userType: userType, proctorType: proctorType, filterModel: newQueryFilterOptions})
        fetchData(curQueryParams)

    }, [queryParams, setQueryParams])

    const onPaginationChange = (newPageData: GridPaginationModel) => {
        // console.log("newPageData", newPageData)
        setQueryParams({...queryParams, pageData: {...queryParams.pageData, pageNumber: newPageData.page, size: newPageData.pageSize}})
        const curQueryParams: NewQueryParams = {...queryParams, pageData: {...queryParams.pageData, pageNumber: newPageData.page, size: newPageData.pageSize}}
        fetchData(curQueryParams)
    }

    const onLanguageFilterChange = (newLanguageId: SelectChangeEvent<number>) => {
        const contentArea = contentAreaStore.contentAreas.find((contentArea: ContentArea) => contentArea.contentAreaId === newLanguageId.target.value)
        setSelectedLanguageFilter(contentArea)
        setQueryParams({...queryParams, contentAreaId: contentArea?.contentAreaId})
        const curQueryParams: NewQueryParams = {...queryParams, contentAreaId: contentArea?.contentAreaId}
        fetchData(curQueryParams)
    }

    const onSelectedRowChange = (selectedLoginIds: GridRowSelectionModel) => {
        console.log("newSelection", selectedLoginIds)
        const selectedTableData: AdminLoginTableData[] = tableData.filter((tableData: AdminLoginTableData) => {
            return selectedLoginIds.indexOf(tableData.loginid) > -1
        })

        console.log("selectedTableData", selectedTableData)
        props.setSelectedIds(selectedTableData)
    }

    const getEncryptedPassword = (rowData: GridCellParams) => {
        const rowIndex: number = tableData.indexOf(rowData.row)
        const uuid: string = rowData.row.loginUuid
        LoginTableNewUtilities.getEncryptedPassword(uuid).then((resp) => {
                console.log("Need to update render here: ", resp)
                if (rowIndex > -1) {
                    const newTableData = [...tableData]
                    newTableData[rowIndex].encPass = resp
                    setTableData(newTableData)
                } else {
                    console.log("Row Index not found")
                }
            }
        )
    }

    const createPermissionsViewAndEdit = (params: GridRenderCellParams | GridRenderEditCellParams) => {
        const isTeacher = params.row.userType === UserType.T

        const perms = isTeacher ? TeacherPermissionsObject : TestTakerPermissionsObject

        return (
            <Stack direction={"row"} spacing={themeV5.spacing(1)} alignItems={"center"}>
                {
                    perms.map((permission: PermissionsInterface, index: number) => {
                        return (
                            <RendersSingleClickBooleanPermissions
                                key={`SetPermissionSelect-${permission.permissionEnumValue}-${params.row.loginid}-${index}`}
                                params={params}
                                propLabel={permission.permissionDisplayText}
                                index={index}
                                permissionEnum={permission}
                            />
                        )
                    })
                }
            </Stack>
        )
    }

    const updateRowData = (rowData: AdminLoginTableData) => {
        console.log("Updated Row Data", rowData)
    }

    const onRowUpdateError = (params: any) => {
        console.log("onRowUpdateError", params)
    }

    // @ts-ignore
    const rowPermissionChange = (event: React.ChangeEvent<HTMLInputElement>, rowData: GridRenderCellParams, permission: string) => {
        console.log("event.target.checked", event.target.checked)
        console.log("rowData", rowData)
        console.log("permission", permission)
        const updateTableDate = [...tableData]
        const rowIndex = updateTableDate.findIndex((row) => row.loginid === rowData.row.loginid)
        console.log("rowIndex", rowIndex)
        if (rowIndex > -1) {
            updateTableDate[rowIndex].permissions = event.target.checked ? [...rowData.row.permissions, permission] : rowData.row.permissions.filter((perm: string) => perm !== permission)
            // rowData.api.
            console.log("Changed row perms: ", updateTableDate[rowIndex].permissions)
            setTableData(updateTableDate)
        }
    }

    const columns: GridColDef[] = [
        {
            headerName: "Login ID",
            field: "loginid",
            hideable: true,
            minWidth: 155,
            disableColumnMenu: true,
            filterable: false
        },
        {
            headerName: "User Name/Test Code",
            field: "username",
            minWidth: 155,
            hideable: false,
            disableColumnMenu: true,
            disableReorder: true,
        },
        {
            headerName: "Language",
            field: "contentArea",
            minWidth: 155,
            align: "center",
            hideable: true,
            disableColumnMenu: true,
            disableReorder: true,
            sortable: false,
            type: "singleSelect",
            renderHeaderFilter: (params) => {
                // console.log("params", params)
                return (
                    <Select
                        fullWidth={true}
                        label={"Language VAL"}
                        variant={"standard"}
                        value={selectedLanguageFilter ? selectedLanguageFilter.contentAreaId : ""}
                        onChange={onLanguageFilterChange}
                    >
                        <MenuItem key={`-1`} value={undefined}>Clear</MenuItem>
                        {(contentAreaStore.contentAreas !== undefined && contentAreaStore.contentAreas.length > 0) && contentAreaStore.contentAreas.map((contentArea: ContentArea, index) => {
                            if (contentArea.contentAreaId === null || contentArea.contentAreaId === undefined) {
                                return <MenuItem key={`-1-${index}`} value={""}>Language</MenuItem>
                            } else {
                                return <MenuItem key={`${contentArea.contentAreaId}-${index}`} value={contentArea.contentAreaId}>{contentArea.longDisplayName}</MenuItem>
                            }
                        })}
                    </Select>
                )
            },
            renderCell: (params) => {
                // console.log("params", params)
                const contentArea: ContentArea = params.value as ContentArea
                if ((contentArea.contentAreaId === null || contentArea.contentAreaId === undefined) && (contentAreaStore.contentAreas !== undefined && contentAreaStore.contentAreas.length > 0)) {
                    return <MenuItem key={`-1`} value={''}>Language</MenuItem>
                } else {
                    const curContentArea = contentAreaStore.contentAreas.find((contentAreaFilter: ContentArea) => {
                        return contentArea.contentAreaId === contentAreaFilter.contentAreaId
                    })
                    return <MenuItem key={curContentArea!.contentAreaId.toString()} value={curContentArea!.contentAreaId.toString()}>{curContentArea!.longDisplayName}</MenuItem>
                }
            },

        },
        {
            headerName: "User Type",
            field: "userType",
            hideable: true,
            minWidth: 155,
            align: "center",
            disableColumnMenu: true,
        },
        {
            headerName: "Active",
            field: "isActive",
            type: "boolean" ,
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
            filterable: false,
        },
        {
            headerName: "Password",
            field: "encPass" ,
            hideable: true,
            type: "string",
            minWidth: 155,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    (params.row.encPass === null || params.row.encPass === undefined) ?
                        (
                            <div style={{padding: 8}}>
                                <Button
                                    variant={"contained"}
                                    style={{
                                        margin: 4,
                                        padding: 4,
                                    }}
                                    onClick={() => getEncryptedPassword(params)}
                                >
                                    Get Password
                                </Button>
                            </div>
                        ) : (
                            params.row.encPass
                        )
                )
            }
        },
        {
            headerName: "Rostered",
            field: "rostered" ,
            type: "boolean",
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
            filterable: false
        },
        {
            headerName: "VoiceRecorder",
            field: "voiceRecorder" ,
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
        },
        {
            headerName: "Handwritten",
            field: "handwritten" ,
            type: "boolean",
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
            filterable: false
        },
        {
            headerName: "Allow Test",
            field: "allowTest",
            type: "boolean",
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
            filterable: false
        },
        {
            headerName: "Proctor",
            field: "proctorType",
            type: "string",
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
        },
        {
            headerName: "ECWID SKU",
            field: "ecwidSku",
            type: "string",
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) => {
                const rowData = params.row
                const sku = LoginTableNewUtilities.buildSKU(rowData)
                return sku ? sku : ""
            }
        },
        {
            headerName: "Registration URL",
            field: "proctorLink",
            type: "string",
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return LoginTableNewUtilities.buildRegistraionLink(params.row)
            }
        },
        {
            headerName: "Student DIY URL",
            field: "diyLink",
            type: "string",
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return LoginTableNewUtilities.buildDIYLink(params.row)
            }
        },
        {
            headerName: "Instructor Login",
            field: "instructorLogin",
            type: "string",
            minWidth: 155,
            hideable: true,
            renderCell: (params) => {
                const rowData = params.row
                if (LoginTableNewUtilities.isProctored(rowData.proctorType)) {
                    return <Button variant="contained" color="primary" onClick={() => LoginTableNewUtilities.getVerificientInstructorLink(rowData)}>Login</Button>
                } else {
                    return ""
                }
            },
            disableColumnMenu: true,
        },
        {
            headerName: "Scheduling Link",
            field: "schedulingLink",
            type: "string",
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
            filterable: false
        },
        {
            headerName: "Password Expires",
            field: "passwordexpires",
            type: "date",
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
            filterable: false,
            valueGetter: (value: {passwordExpires: Date | null} | null) => {
                if (value === null || value.passwordExpires === null) {
                    return ""
                }
                const momentDate = moment(value.passwordExpires)

                return momentDate.toDate()
            }
        },
        {
            headerName: "Login Expires",
            field: "loginexpires",
            type: "date",
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
            filterable: false,
            valueGetter: (value: { loginexpires: Date | null } | null) => {
                if (value === null || value.loginexpires === null) {
                    return ""
                }

                const momentDate = moment(value.loginexpires)
                return momentDate.toDate()
            }
        },
        {
            headerName: "Test Open Date",
            field: "testOpenDate",
            type: "date",
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
            filterable: false,
            valueGetter: (value: {testOpenDate: Date | null} | null) => {
                if (value === null || value.testOpenDate === null) {
                    return ""
                }
                const momentDate = moment(value.testOpenDate)

                return momentDate.toDate()
            }
        },
        {
            headerName: "Test Close Date",
            field: "testCloseDate",
            type: "date",
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
            filterable: false,
            valueGetter: (value: {testCloseDate: Date | null} | null) => {
                if (value === null || value.testCloseDate === null) {
                    return ""
                }
                const momentDate = moment(value.testCloseDate)

                return momentDate.toDate()
            }
        },
        {
            headerName: "Default Group",
            field: "defaultGroup" ,
            type: "string",
            minWidth: 155,
            hideable: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return params.row.defaultGroup ? params.row.defaultGroup.name : "NO DEFAULT GROUP LINKED"
            }
        },
        // TODO: fix this when we geet back to working on this tool
        // {
        //     headerName: "Assigned Schedules",
        //     field: "assignedSchedules",
        //     type: "Object",
        //     minWidth: 155,
        //     // flex: 1,
        //     hideable: true,
        //     disableColumnMenu: true,
        //     filterable: false,
        //     renderCell: (params) => {
        //         const schedules: P360AvantSchedule[] = params.row.assignedSchedules
        //         const scheduleList = schedules.length > 0 ? schedules.map((schedule: P360AvantSchedule) => {
        //             return schedule.title
        //         }) : ["NONE"]
        //         return scheduleList.join(", ")
        //     },
        //     sortable: false,
        // },
        {
            headerName: "Permissions",
            field: "permissions",
            minWidth: 350,
            hideable: true,
            filterable: false,
            editable: true,
            disableColumnMenu: true,
            renderEditCell: (params: GridRenderEditCellParams) => {
                return createPermissionsViewAndEdit(params)
            },
            renderCell: (params: GridRenderCellParams) => {
                return createPermissionsViewAndEdit(params)
            }
        },
    ]

    if (contentAreaStore.loading || contentAreasLoading) {
        return <div>Loading...</div>
    }

    return (
        <DataGridPro
            {...queryParams.filterModel}
            columns={columns}
            loading={dataLoading}
            autoHeight={true}
            getRowId={(row) => row.loginid}
            rows={tableData ? tableData : []}
            density="compact"
            disableDensitySelector={true}
            slots={{toolbar: GridToolbar, headerFilterMenu: null,}}
            pagination={true}
            pageSizeOptions={[10, 100, 200, 500, 1000]}
            paginationMode={"server"}
            rowCount={rowCount}
            onPaginationModelChange={onPaginationChange}
            checkboxSelection={true}
            disableRowSelectionOnClick={true}
            onRowSelectionModelChange={onSelectedRowChange}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        loginid: false,
                        username: true,
                        contentArea: true,
                        userType: true,
                        isActive: false,
                        encPassword: false,
                        rostered: true,
                        voiceRecorder: false,
                        handwritten: true,
                        allowTest: false,
                        proctorType: true,
                        ecwidSku: false,
                        proctorLink: false,
                        diyLink: false,
                        instructorLogin: false,
                        schedulingLink: false,
                        passwordexpires: false,
                        loginexpires: true,
                        testOpenDate: false,
                        testCloseDate: false,
                        defaultGroup: false,
                        assignedSchedules: false,
                        permissions: true,
                    }
                },
                pagination: {
                    paginationModel: {
                        pageSize: queryParams.pageData.size,
                    }
                },
                filter: {
                    filterModel: {
                        items: [
                            {
                                field: "userType",
                                operator: "equals",
                                value: queryParams.userType,
                            },
                        ]
                    }
                }
            }}
            filterModel={{
                items: [
                    // {
                    //     field: "username",
                    //     operator: "startsWith",
                    //     value: queryParams.username,
                    // },
                    {
                        field: "userType",
                        operator: "equals",
                        value: queryParams.userType,
                    },
                ]
            }}
            disableColumnFilter={true}     // Makes the column filter icon disappear
            // unstable_headerFilters={true} // Makes it so the selector is on the header instead of the toolbar
            filterMode="server"
            onFilterModelChange={onQueryChange}
            onSortModelChange={onSortChange}
            editMode={"cell"}
            // getRowHeight={(params) => { return 48 }}
            processRowUpdate={(updateRow, originalRow) => {
                console.log("In Process Update updateRow", updateRow)
                console.log("In Process Update originalRow", originalRow)
                updateRowData(updateRow)
            }}
            onProcessRowUpdateError={onRowUpdateError}
        />
    )
})