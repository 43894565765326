import React from "react"
import {Env, EnvUtils} from "../../util/EnvUtils"
import ApiService from "../../services/ApiService";
import {ApiPanelGraph} from "../../types/types";
import {productStore} from "../../app/common/products/ProductStore"
import {UserType} from "../../app/common/authentication/models/UserType"

interface BrandProps {
    appName?: string
    path?:string
}

export class Brand extends React.Component<BrandProps> {
    render() {
        const env = EnvUtils.current()
        const envName = env === Env.PROD ? "" : env
        return (
            <div className="brand">
                <img
                    className="brand__logo"
                    alt=""
                    src="https://cdn.avantassessment.com/resources/common/img/avant-logo.svg"
                    tabIndex={0}
                />
                <span className="brand__name">
                { this.props.path !== '/close-session' ? this.props.appName : ''} {envName}
                </span>
            </div>
        )
    }

    /**
     * Re-brands four-skill tests based on the panel graphs product packages
     */
    static async buildBrandName(defaultBrandName: string | undefined): Promise<string | undefined>
    {
        // Fallback value: The brand name passed to this function.
        let brandName = defaultBrandName
        let userType = productStore.loginProduct ? productStore.loginProduct.userType : null

        if (typeof(brandName) !== "undefined" && brandName.includes("STAMP 4")) {
            // Panel graphs are retrieved with an API call.
            const panelGraphs = await ApiService.fetchPanelGraphs()

            // Check if there is only one panelgraph
            if (panelGraphs.length == 1) {
                const productPackage = panelGraphs[0].productPackage as string
                const skill = this.getSkill(productPackage)
                brandName = `STAMP ${skill && skill.toUpperCase()}`

                if (userType === UserType.T.toString()) {
                    brandName += " Report"
                }
            }

            const productPackages: (string | undefined)[] = []
            panelGraphs.forEach((pg: ApiPanelGraph) => {
                productPackages.push(pg.productPackage)
            })

            // determine if the product packages are the same.
            const firstPackage = productPackages.find(productPackage => productPackage != null) as string
            const packagesMatch = productPackages.every(productPackage => productPackage === firstPackage)

            if (packagesMatch) {
                const skill = this.getSkill(firstPackage) ? this.getSkill(firstPackage) : ""
                brandName = `STAMP ${skill.toUpperCase()}`
            } else {
                // If not, set brand name to just `STAMP Report`
                brandName = "STAMP"
            }

            if (userType === UserType.T.toString()) {
                brandName += " Report"
            }
        }

        return brandName
    }

    static getSkill(productPackage: string): string {
        let skill = productPackage && productPackage.split("-")[0]

        // Changes 4e from the product_package to 4se
        if (/[1-4]e/.test(skill && skill.toLowerCase())) {
            const array = skill.split("")
            array.splice(1, 0, "s")
            skill = array.join("")
        }

        return skill
    }

}
