import {Box} from "@material-ui/core"
import {styled} from "@material-ui/core/styles"
import {observer} from "mobx-react"
import React from "react"
import { withRouter,RouteComponentProps} from "react-router-dom";
import {productStore} from "../../app/common/products/ProductStore"
import {ReportUtils} from "../../app/reports/ReportUtils"
import {Env, EnvUtils} from "../../util/EnvUtils"
import {Brand} from "../Brand/Brand"
import Navigation from "../Navigation/Navigation"
export const HEADER_HEIGHT = 70

interface HeaderProps extends RouteComponentProps<{}> {
    isCredly?: boolean
}

interface HeaderState {
    brandName: string | undefined
}

@observer
class HeaderContainer extends React.Component<HeaderProps, HeaderState> {

    constructor(props: HeaderProps) {
        super(props)
        this.state = {
            brandName: ""
        }
    }

    render() {
        const product = productStore.loginProduct
        // TODO: This is for classic reports banner; remove when done

        let backgroundColor = "#FFFFFF" // white
        if (EnvUtils.current() === Env.DEV || EnvUtils.current() === Env.LOCAL_DEV) {
            backgroundColor = "#e2ffdb" // green
        } else if (EnvUtils.current() === Env.STAGING) {
            backgroundColor = "#dbe5ff" // blue
        }
        const printing = ReportUtils.isPrinting(this.props.location.pathname)
        if (printing) {
            return null
        }
        return (
            <>
               
                <HeaderWrapper
                    style={{
                        position: "fixed",
                        width: "100%",
                        boxShadow: "0 1px 0 0 #CDCDCD",
                        padding: "10px 32px 10px 32px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        zIndex: 999,
                        backgroundColor,
                    }}
                >
                    <a
                        href={"#content"}
                        className="skip-nav"
                        tabIndex={1}
                        style={{display: "none"}}
                    >
                        Skip to content
                    </a>
                    <Brand appName={this.state.brandName} path={this.props.location.pathname}/>
                    {/*TODO: See if this actually works...*/}
                    {product && <Navigation section={null} take={null}/>}
                </HeaderWrapper>

            </>
        )
    }

    componentDidMount = () => {
        const product = productStore.loginProduct

        if (this.props.isCredly) {
            this.setBrandName("STAMP")
        } else {
            this.setBrandName(product ? product.productName : "")
        }
    }

    setBrandName = async (productName: string) => {
        // The brand name may be changed by the Brand component.
        const brandName: string | undefined = await Brand.buildBrandName(productName)

        this.setState({brandName: brandName})
    }

}

const HeaderWrapper = styled(Box)({
    "@media print": {
        justifyContent: "flex-end !important",
        backgroundColor: "transparent !important"
    }
})

export const Header = withRouter(HeaderContainer)