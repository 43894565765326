import {GroupMaster, GroupType} from "../../group/models/GroupMaster"
import {ApiTakePanelSearchResult, Reset} from "../TakeSearchStore"

export interface TakeSearchResult {
    readonly takeId: number
    readonly code: string
    readonly testCode: string
             language: string
    readonly contentAreaId: number
    readonly productName: string
    readonly created: string
    readonly isHandWritten: boolean
    readonly oppCode: string
    readonly oppNum: string
    readonly groups: GroupMaster[],
    readonly firstName?: string
    readonly lastName?: string
    readonly durationMinutes?: number
    readonly takePanels: ApiTakePanelSearchResult[]
    readonly profile: Map<string, string>
    readonly profileCompleted?: string
    readonly dateBillable?: string
    readonly overallStartTime?: string
    readonly overallFinishTime?: string
    readonly productId: number
    readonly grade?: string
    readonly readingLevel?: string
    readonly readingScaledScore?: string
    readonly resets?: Reset[]
    readonly writingLevel?: string
    readonly listeningLevel?: string
    readonly listeningScaledScore?: string
    readonly speakingLevel?: string

    readonly ageStarted?: string
    readonly firstLanguage?: string
    readonly grandparentsSpeak?: string
    readonly fatherSpeak?: string
    readonly motherSpeak?: string
    readonly familyMembersOften?: string
    readonly familyMembersLive?: string
    readonly studiedOther?: string
    readonly studiedWhich?: string
    readonly immersion?: string
    readonly immersionYears?: string
    readonly planToStudy?: string
    readonly otherFirstLanguage?: string
    readonly howOftenSpoken?: string
    readonly familySpeaks?: string
    readonly yearsStudyingOrSpoken?: string
    readonly studiedAbroad?: string
    readonly yearsAbroad?: string
    readonly monthsAbroad?: string
    readonly compositeScore?: number

    readonly selfEvaluation?: string
    readonly selfEvaluationForm?: string
    readonly readingComprehensionLevel?: string
    readonly readingGrammarLevel?: string
    readonly suggestedPlacement?: string
    readonly readingCompDetail?: string
    readonly readingGrammarDetail?: string
    readonly yearsOfStudy?: string
    readonly gradesStudied?: string
    readonly foreignLangAtHome?: string
    readonly proficiencySelfAssessment?: string

    readonly lexical?: string
    readonly lexicalPct?: string
    readonly dictationLevel?: string
    readonly dictationPct?: string
    readonly sentenceCompletionLevel?: string
    readonly sentCompPct?: string
    readonly verbConjugationLevel?: string
    readonly verbConjPct?: string
    readonly level?: string

    readonly translationLevel?: string
    readonly translationPct?: string

    readonly testsTaken?: string
    readonly familyLanguage?: string
    readonly familyMembers?: string
    readonly otherFamilyMembers?: string
    readonly languageConfidence?: string
    readonly externalId?: string
    readonly testTakerEmail?: string
    readonly dateOfBirth?: string
    readonly startTime?: string
    readonly finishTime?: string
    readonly testStatus?: string
    readonly proctorType?: string

    readonly earnedBadgeId?: string
    readonly testPackage?: string
    readonly shortCode?: string

}

// Creating this to support the weird tables in the client support tool
export interface TakeSearchResultWithGroupNames extends TakeSearchResult, TakeSearchResultGroupNames {
}

interface TakeSearchResultGroupNames {
    stateName?: string
    districtName?: string
    schoolName?: string
    classroomName?: string
}

export const findTakeSearchResultGroupNames = (takeSearchResult: { groups: GroupMaster[] }): TakeSearchResultGroupNames => {
    const names: TakeSearchResultGroupNames = {}
    takeSearchResult.groups.forEach((group: GroupMaster) => {
        switch (group.groupType) {
            case GroupType.CLASS:
                names.classroomName = group.name
                break
            case GroupType.DISTRICT:
                names.districtName = group.name
                break
            case GroupType.SCHOOL:
                names.schoolName = group.name
                break
            case GroupType.STATE:
                names.stateName = group.name
                break
            /* There are for worldspeak and place in the billing report */
            case GroupType.LOCATION:
                names.classroomName = group.name
                break
            case GroupType.CUSTOMER:
                names.schoolName = group.name
                break
            default:
                break

        }
    })
    return names
}
