/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/
import {Box, Checkbox, TextField, Tooltip, Typography} from "@material-ui/core"
import React, {useState, useEffect} from "react"
import {SSOTeacherStaff} from "../components/SSOTeacherStaffTypes"
import queryString from "querystring"
import {theme} from "../../../../../styles/MuiThemes"
import {AvantCircularProgress} from "../../../../../app/common/loaders/AvantCircularProgress"
import SSOEntityToolApi from "../../SSOEntityTool/SSOEntityToolApi"
import SSOTeacherStaffApi from "../SSOTeacherStaffApi"
import {messageStore} from "../../../../../app/common/messages/MessageStore"
import {DataGridPro, GridColDef, GridToolbar} from "@mui/x-data-grid-pro"
import { axiosErrorHumanReadable } from "../../../../../util/ErrorUtil"


interface SSOTeacherStaffIState {

}

const SSOTeacherStaffTool: React.FC<SSOTeacherStaffIState> = () => {
	const [ssoTeacherStaffList, setSsoTeacherStaffList] = useState<SSOTeacherStaff[] | null>(null)
	const [isQueryInProgress, setIsQueryInProgress] = useState<boolean>(false)
	const [ssoSystemIdUserInput, setSsoSystemIdUserInput] = useState<string>("")
	const [ssoSystemIdSearchTerm, setSsoSystemIdSearchTerm] = useState<string>("")
	const [ssoEntityName, setSsoEntityName] = useState<string | null>(null)

	// Define the query parameter name that refers to the SSO system ID search term:
	const ssoSystemIdParamName: string = "ssosystemid"

	const RenderCheckbox = ({ permissionName, hasPermission, id }: { permissionName: string, hasPermission: boolean, id: string }) => {
		const [checked, setChecked] = React.useState(hasPermission)

		const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			if (checked) {
				SSOTeacherStaffApi.removePermissionById(permissionName, id)
					.then(response => {
						if (response) {
							setChecked(false)
							messageStore.setInfoMessage(`Removed permission ${permissionName} from SSO Teacher/Staff id ${id}`)
						}
						else {
							messageStore.setErrorMessage(`Unexpected response from backend when attempting to remove permission ${permissionName} from SSO Teacher/Staff id ${id}`)
						}
					})
					.catch(error => {
						messageStore.setErrorMessage("API exception: " + axiosErrorHumanReadable(error))
					})
			}
			else {
				SSOTeacherStaffApi.addPermissionById(permissionName, id)
					.then(response => {
						if (response) {
							setChecked(true)
							messageStore.setInfoMessage(`Added permission ${permissionName} to SSO Teacher/Staff id ${id}`)
						}
						else {
							messageStore.setErrorMessage(`Unexpected response from backend when attempting to add permission ${permissionName} to SSO Teacher/Staff id ${id}`)
						}
					})
					.catch(error => {
						messageStore.setErrorMessage("API exception: " + axiosErrorHumanReadable(error))
					})
			}
		}

		return (
			<>
			<Checkbox
				checked={checked}
				color="primary"
				onChange={handleChange}
			/>
				{permissionName}
			</>
		)
	}

	// Define the grid columns for the DataGridPro component:
	const columns: GridColDef[] = [
		{ field: "id",                  headerName: "id",                            width: 300 },
		{ field: "ssoSystem",           headerName: "ssoSystem",                     width: 100 },
		{ field: "staffType",           headerName: "staffType",                     width: 100 },
		{ field: "districtSsoId",       headerName: "districtSsoId",                 width: 200 },
		{ field: "staffSsoId",          headerName: "staffSsoId",                    width: 200 },
		{ field: "firstName",           headerName: "firstName",                     width: 150 },
		{ field: "lastName",            headerName: "lastName",                      width: 150 },
		{ field: "institutionId",       headerName: "institutionId",                 width: 100 },
		{ field: "permission_customer", headerName: "edit customer permission",      width: 150, renderCell: (params) => <RenderCheckbox permissionName={"customer"} hasPermission={params.row && params.row.permissions && params.row.permissions.indexOf("customer") > -1} id={params.row.id} />},
		{ field: "permission_district", headerName: "edit district permission",      width: 150, renderCell: (params) => <RenderCheckbox permissionName={"district"} hasPermission={params.row && params.row.permissions && params.row.permissions.indexOf("district") > -1} id={params.row.id} />},
		{ field: "permission_school",   headerName: "edit school permission",        width: 150, renderCell: (params) => <RenderCheckbox permissionName={"school"} hasPermission={params.row && params.row.permissions && params.row.permissions.indexOf("school") > -1} id={params.row.id} />},
		{ field: "permission_teacher",  headerName: "edit teacher permission",       width: 150, renderCell: (params) => <RenderCheckbox permissionName={"teacher"} hasPermission={params.row && params.row.permissions && params.row.permissions.indexOf("teacher") > -1} id={params.row.id} />},
		{ field: "schools",             headerName: "schools",                       width: 200 },
		{ field: "district",            headerName: "sis_other_data.district",       width: 200, valueGetter: (value, row) => row && row.sis_other_data && row.sis_other_data.district ? row.sis_other_data.district : null },
		{ field: "email",               headerName: "sis_other_data.email",          width: 200, valueGetter: (value, row) => row && row.sis_other_data && row.sis_other_data.email ? row.sis_other_data.email : null },
		{ field: "last_modified",       headerName: "sis_other_data.last_modified",  width: 200, valueGetter: (value, row) => row && row.sis_other_data && row.sis_other_data.last_modified ? row.sis_other_data.last_modified : null },
		{ field: "school",              headerName: "sis_other_data.school",         width: 200, valueGetter: (value, row) => row && row.sis_other_data && row.sis_other_data.school ? row.sis_other_data.school : null },
		{ field: "sis_id",              headerName: "sis_other_data.sis_id",         width: 200, valueGetter: (value, row) => row && row.sis_other_data && row.sis_other_data.sis_id ? row.sis_other_data.sis_id : null },
		{ field: "sso_id",              headerName: "sis_other_data.sso_id",         width: 200, valueGetter: (value, row) => row && row.sis_other_data && row.sis_other_data.sso_id ? row.sis_other_data.sso_id : null },
		{ field: "state_id",            headerName: "sis_other_data.state_id",       width: 200, valueGetter: (value, row) => row && row.sis_other_data && row.sis_other_data.state_id ? row.sis_other_data.state_id : null },
		{ field: "teacher_number",      headerName: "sis_other_data.teacher_number", width: 200, valueGetter: (value, row) => row && row.sis_other_data && row.sis_other_data.teacher_number ? row.sis_other_data.teacher_number : null },
		{ field: "title",               headerName: "sis_other_data.title",          width: 200, valueGetter: (value, row) => row && row.sis_other_data && row.sis_other_data.title ? row.sis_other_data.title : null },
		{ field: "updated",             headerName: "updated",                       width: 100, type: "date", valueGetter: (value) => new Date(value) },
		{ field: "created",             headerName: "created",                       width: 100, type: "date", valueGetter: (value) => new Date(value) },
	]
	const columnsToHideInitially = {
		districtSsoId: false,
		staffSsoId: false,
		permission_customer: false,
		permission_district: false,
		permission_school: false,
		permission_teacher: false,
		schools: false,
		district: false,
		last_modified: false,
		school: false,
		sis_id: false,
		sso_id: false,
		state_id: false,
		teacher_number: false,
		updated: false,
	}

	const getUrlParameterValue = (paramName: string): string | string[] | null => {
		// Initialize the return variable:
		let paramValue: string | string[] | null = null

		// Get the query string:
		const qString = window.location.search

		// Parse the query string:
		const queryParams = queryString.parse(qString.replace("?", ""))

		// If the query parameter exists...
		if (queryParams.hasOwnProperty(paramName)) {
			// Get the value of that query parameter.
			paramValue = queryParams[paramName]
		}

		return paramValue
	}

	const getSsoSystemIdSearchTermFromURL = (): string => {
		// Get the parameter value:
		const ssosystemidParamVal = getUrlParameterValue(ssoSystemIdParamName)

		// If the parameter value is a string...
		if (ssosystemidParamVal && typeof(ssosystemidParamVal) === "string") {
			retrieveSsoTeacherStaffListBySsoSystemId(ssosystemidParamVal)

			return ssosystemidParamVal.trim()
		}

		// Fallthrough:
		return ""
	}

	const ssoSystemIdSearchTermFieldOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSsoSystemIdUserInput(event.target.value.trim())
	}

	const ssoSystemIdSearchTermFieldOnSubmit = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key !== "Enter" || ssoSystemIdUserInput.trim() === "") {
			return
		}

		setSsoEntityName(null)
		setSsoSystemIdSearchTerm(ssoSystemIdUserInput)
		setSingleQueryParam(ssoSystemIdParamName, ssoSystemIdUserInput)
		setSsoSystemIdUserInput("")
		retrieveSsoTeacherStaffListBySsoSystemId(ssoSystemIdUserInput)
	}

	const retrieveSsoTeacherStaffListBySsoSystemId = (ssoSystemId: string): void => {
		setIsQueryInProgress(true)

		SSOEntityToolApi.getSSOEntityNameBySsoId(ssoSystemId)
			.then(response => {
				setSsoEntityName(response)
			})
			.catch(error => {
				// Quietly ignore the error; it's not critical to get the SSO entity name here.
			})

		SSOTeacherStaffApi.getAllByDistrictSsoId(ssoSystemId)
			.then(response => {
				setIsQueryInProgress(false)
				setSsoTeacherStaffList(response)
			})
			.catch(error => {
				setIsQueryInProgress(false)
				messageStore.setErrorMessage("API exception: " + axiosErrorHumanReadable(error))
			})
	}

	const setSingleQueryParam = (key: string, value: string) => {
		setURL(window.location.href.split("?")[0]+"?"+key+"="+value)
	}

	const setURL = (newURL: string) => {
		window.history.replaceState({}, document.title, newURL)
	}

	useEffect(() => {
		setSsoSystemIdSearchTerm(getSsoSystemIdSearchTermFromURL())
	}, [])

	return(
		<>
			<Typography variant="h1">
				SSO Teacher/Staff
			</Typography>
			<Box style={{marginBottom: theme.spacing(3)}}></Box>

			<Box>
				<Tooltip
					title={ssoSystemIdSearchTerm.trim().length > 0 ? "Press enter to submit." : "Search by SSO System's ID."}>
					<TextField
						fullWidth={false}
						style = {{width: 500}}
						label={"Search by SSO System's ID"}
						value={ssoSystemIdUserInput}
						variant={"outlined"}
						onKeyUp={ssoSystemIdSearchTermFieldOnSubmit}
						onChange={ssoSystemIdSearchTermFieldOnChange}
					/>
				</Tooltip>
			</Box>
			<Box style={{marginBottom: theme.spacing(3)}}></Box>

			{ssoSystemIdSearchTerm &&
			<>
				{ssoEntityName &&
				<Typography variant={"h2"}>
					SSO Entity: {ssoEntityName}
				</Typography>
				}
				<Typography variant={"h2"}>
					SSO System's ID: {ssoSystemIdSearchTerm}
				</Typography>
                <Box style={{marginBottom: theme.spacing(3)}}></Box>
			</>}

			{isQueryInProgress &&
			<AvantCircularProgress/>}
			{!isQueryInProgress && ssoTeacherStaffList && ssoTeacherStaffList.length === 0 &&
			<Typography>
				Found 0 SSO Teacher/Staff rows linked to this SSO System's ID.
			</Typography>
			}
			{!isQueryInProgress && ssoTeacherStaffList && ssoTeacherStaffList.length > 0 &&
			<div>
                <DataGridPro
                    rows={ssoTeacherStaffList}
                    columns={columns}
                    getRowId={(row) => row.id}
                    slots={{ toolbar: GridToolbar }}
                    initialState={{ columns: { columnVisibilityModel: columnsToHideInitially } }}
                />
			</div>
			}
		</>
	)

}

export default React.memo(SSOTeacherStaffTool)
