import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid} from "@material-ui/core"
import * as React from "react"
import {LoginDTO} from "../../../../types/types";
import {reportsStore} from "../../common/stores/ReportStore";
import {TakeSearchResult} from "../../../common/take/models/TakeSearchResult";
import ApiService from "../../../../services/ApiService";
import {messageStore} from "../../../common/messages/MessageStore";
import {AxiosResponse} from "axios";
import { contentAreaStore } from "../../../common/contentArea/ContentAreaStore";

interface EmailRemindersDialogProps {
    login: LoginDTO | undefined,
    open: boolean,
    handleEmailRemindersClose: () => void
}
export const EmailRemindersDialog: React.FC<EmailRemindersDialogProps> = props => {
    const getSelectedInstancesData = () => {
        const selectedTakeIds = reportsStore.selectedTakeIds
        const instancesData = reportsStore.instancesData

        let selectedInstancesData: TakeSearchResult[]
        if (typeof instancesData === 'undefined') {
            selectedInstancesData = []
        } else {
            selectedInstancesData = instancesData
                .filter(instance => selectedTakeIds.includes(instance.takeId))
                .sort((a, b) => (a.code > b.code) ? 1 : -1)
        }

        return selectedInstancesData
    }

    const handleSendEmailReminders = async () => {
        ApiService.proctor360EmailReminders(reportsStore.selectedTakeIds).then((response: AxiosResponse) => {
            messageStore.setInfoMessage('Email reminders sent!')

            // TODO: Output the response data to the user instead of just logging it in the console.

            props.handleEmailRemindersClose()
        })
    }

    const testCode = props.login && props.login.username ? props.login.username : ""
    const language = props.login && props.login.contentAreaId ? contentAreaStore.convertContentAreaIdToLanguageName(props.login.contentAreaId) : ""
    const selectedInstancesData = getSelectedInstancesData()

    return (
        <Dialog
            aria-labelledby={"roster-students"}
            aria-describedby={"roster-new-students"}
            open={props.open}
            onClose={props.handleEmailRemindersClose}
        >
            <DialogTitle>Email Reminders - {testCode}: {language} </DialogTitle>
            <DialogContent>
            { selectedInstancesData.length > 0
                ? <>
                    <DialogContentText>
                        You have selected the following test takers to receive email reminders:
                    </DialogContentText>
                    <Grid container={true} spacing={2}>
                    {selectedInstancesData.map((selectedInstance, key) => (
                        <Grid item={true} xs={12} key={key}>
                            {selectedInstance.firstName} {selectedInstance.lastName} ({selectedInstance.code})
                        </Grid>
                    ))}
                    </Grid>
                    <Grid container={true} spacing={2}>
                        <Grid item={true}>
                            <Button variant="contained" color="primary" onClick={handleSendEmailReminders}>Email Reminders</Button>
                        </Grid>
                        <Grid item={true}>
                            <Button variant="contained" color="secondary" onClick={props.handleEmailRemindersClose}>Cancel</Button>
                        </Grid>
                    </Grid>
                </>
                : <>
                    <DialogContentText>
                        Please click the checkboxes to select test instances from the list.
                    </DialogContentText>
                </>
            }
            </DialogContent>
        </Dialog>
    )
}

