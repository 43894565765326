/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import ApiService from "../../../services/ApiService"
import * as React from "react"
import MaterialTable, {Column} from "material-table"
import {useEffect, useRef, useState} from "react"
import {messageStore} from "../../../app/common/messages/MessageStore"
import {Checkbox, MenuItem, Select} from "@material-ui/core"
import {EditableTextInputWithControls} from "../../EditableTextInputWithControls/EditableTextInputWithControls"
import {observer} from "mobx-react"
import productPackageStore from "../../../app/common/ProductPackage/ProductPackageStore"
import {ProductPackage} from "../../../types/types"
import { contentAreaStore } from "../../../app/common/contentArea/ContentAreaStore"

function buildSort(orderByField: string | unknown, orderDirection: string): string {
    let sortString = ""
    if (orderByField) {
        sortString = `&sort=${orderByField},${orderDirection}`
    }
    return sortString
}

function buildPredicate(filters: any): string {
    const predicateArray: string[] = []
    filters.forEach((filter: any) => {
        if (filter.column.field === "name") {
            predicateArray.push(`name=contains(${filter.value})`)
        }
        if (filter.column.field === "friendlyname") {
            predicateArray.push(`friendlyname=contains(${filter.value})`)
        }
        if (filter.column.field === "product_package") {
            predicateArray.push(`product_package=contains(${filter.value})`)
        }

        if (filter.column.field === "deprecated") {
            const value = (filter.value === "checked")
            predicateArray.push(`deprecated=eq(${value})`)
        }

        if (filter.column.field === "contentareaid") {
            // Filter languages based on what the user is typing in the language field.
            const contentAreaIds: string[] = []

            // Language in the foreach is an array containing a contentareaid and it's language name.
            Object.entries(contentAreaStore.convertContentAreaIdToLanguageName).forEach((language: [string, string]) => {
                if (language[1].toLowerCase().includes(filter.value.toLowerCase())) {
                    contentAreaIds.push(language[0])
                }
            })

            contentAreaIds.forEach((contentAreaId: string) => predicateArray.push(`contentareaid=${contentAreaId}`))
        }

    })
    if (predicateArray.length === 0) {
        return ""
    }
    return `&${predicateArray.join("&")}`
}

async function fetchPanelGraphData(query: any) {
    const sort = (query.orderBy) ? buildSort(query.orderBy.field, query.orderDirection) : ""
    const predicate = (query.filters) ? buildPredicate(query.filters) : ""

    const response = await ApiService.get(`${ApiService.API_URL}rest/panelGraphs?page=${query.page}&size=${query.pageSize}${sort}${predicate}`)
    const panelGraphs = response.data._embedded.panelGraphs
    return {
        data: panelGraphs,
        page: response.data.page.number,
        totalCount: response.data.page.totalElements
    }
}

export const PanelGraphAdminBase: React.FC = observer(() => {
    const tableRef = React.createRef()
    const isCanceled = useRef(false)

    useEffect(() => {
        if (productPackageStore.productPackages.length < 1) {
            productPackageStore.getListOfProductPackages()
        }
    }, [])

    const patchRow = (patchLink: string , data: {}) => {
        ApiService.patch(patchLink, data)
            .then(_ => {if (!isCanceled.current) {
                // @ts-ignore
                if (tableRef.current) { tableRef.current.onQueryChange() }
                messageStore.setInfoMessage("Updated Row")}
            })
            .catch(e => {if (!isCanceled.current) {messageStore.setErrorMessage(e.toString())}})
    }

    const getProductPackageCode = (product_package: string): string => {
        const findProductPackage = productPackageStore.productPackages.find((pp) => {
            return pp.code === product_package
        })

        if (findProductPackage) {
            return findProductPackage.code
        } else {
            return "0"
        }

    }

    let initialColumns: Column<any>[]

    initialColumns = [
        {
            title: "Name",
            field: "name",
            sorting: true,
            searchable: true,
            removable: false,
            defaultSort: "asc"
        },
        {
            title: "Presentation Sequence",
            field: "presentationsequence",
            hidden: true,
            sorting: false,
            searchable: false,
            removable: true,
            editable: "never",
        },
        {
            title: "Last Touched",
            field: "lasttouched",
            hidden: true,
            sorting: true,
            filtering: false,
            searchable: false,
            removable: true,
            editable: "never",
        },
        {
            title: "uuid",
            field: "uuid",
            hidden: true,
            sorting: false,
            searchable: false,
            removable: true,
            editable: "never",
        },
        {
            title: "FriendlyName",
            field: "friendlyname",
            sorting: false,
            searchable: false,
            removable: true,
            type: "string",
            render: (rowData: any) =>
                <EditableTextInputWithControls
                    inputValue={rowData.friendlyname ? rowData.friendlyname : ""}
                    selfLink={rowData._links.self.href}
                    fieldName={"friendlyname"}
                    patchMethod={patchRow}
                    labelText={"Friendly Name"}
                    showControlLabel={false}
                    toolTipText={
                        {
                            save: "Save Link",
                            copy: "Copy Link",
                            edit: "Edit Link",
                            cancel: "Cancel Link Edit",
                            input: "Friendly Name"
                        }
                    }
                    metaData={{
                        inputId: `editableInputWithAdornments-${rowData.uuid}`,
                        dataTestId: `EditableTextInputWithControls-Input-${rowData.uuid}`,
                        inputLabelHtmlFor: `registrationLinkWithAdornment-${rowData.uuid}`
                    }}
                />
        },
        {
            title: "Language",
            field: "contentareaid",
            sorting: true,
            searchable: true,
            removable: false,
            editable: "never",
            render: (rowData: any) => contentAreaStore.convertContentAreaIdToLanguageName(rowData.contentareaid),
        },
        {
            title: "Content Area Id",
            field: "contentareaid",
            removable: true,
            editable: "never"
        },
        {
            title: "Grade Band",
            field: "gradeband",
            sorting: false,
            searchable: false,
            removable: true,
            editable: "never"
        },
        {
            title: "Deprecated",
            field: "deprecated",
            sorting: false,
            searchable: false,
            removable: true,
            type: "boolean",
            render: rowData =>
                <Checkbox
                    checked={rowData.deprecated}
                    onChange={(e) => {
                        patchRow(rowData._links.self.href, {deprecated: e.target.checked})
                    }}
                />
        },
        {
            title: "Product Package",
            field: "product_package",
            sorting: true,
            searchable: true,
            filtering:true,
            removable: true,
            render: rowData =>
                <>
                    <Select
                        value={getProductPackageCode(rowData.product_package)}
                        onChange={(e) => {
                            const cleanData = e.target.value === "0" ? null : e.target.value
                            patchRow(rowData._links.self.href, {product_package: cleanData})
                        }}
                    >
                        <MenuItem key={0} value="0">Select a Product Package</MenuItem>
                        {
                            productPackageStore.productPackages.map((productPackage: ProductPackage) => (
                                <MenuItem key={productPackage.id} value={productPackage.code}>
                                    {productPackage.code} - {productPackage.shortName}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </>
        },
        {
            title: "Sample Test",
            field: "is_sample",
            sorting: false,
            searchable: false,
            removable: true,
            type: "boolean",
            render: rowData =>
                <Checkbox
                    checked={rowData.is_sample ? rowData.is_sample : false}
                    onChange={(e) => {
                        patchRow(rowData._links.self.href, {is_sample: e.target.checked})
                    }}
                />
        }
    ]
    const [columns] = useState(initialColumns)



    return (
        <>
            <MaterialTable
                tableRef={tableRef}
                title="Panel Graphs"
                columns={columns}
                data={fetchPanelGraphData}
                options={{
                    filtering: true,
                    search: true,
                    showTitle: true,
                    toolbar: true,
                    padding: "dense",
                    pageSize: 100,
                    pageSizeOptions: [100, 200, 500, 1000],
                    exportButton: true,
                    columnsButton: true,
                    debounceInterval: 500,
                    exportFileName: "Panel Graphs",
                    tableLayout: "auto",
                }}
            />
        </>
    )
})

export const PanelGraphAdmin = React.memo(PanelGraphAdminBase)
