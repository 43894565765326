import {log} from "./Logging"
import { contentAreaStore } from "../app/common/contentArea/ContentAreaStore"

export default class LanguageUtils {

    static removeTilde = (langName?: string | null): string => {

        if (langName != null && langName.includes("~")) {
            return langName.split("~")[0]
        } else if (langName == null) {
            log.error("Language name is null or undefined and it should not be!")
            return "Language Not Found"
        } else {
            return langName
        }
    }

    static removeMonolingual = (langName?: string | null): string => {
        if (langName == null) {
            return "Language Not Found"
        }

        if (langName != contentAreaStore.convertContentAreaIdToLanguageName(119)) {
            return langName
        }

        return langName.split(" ")[0]
    }

}
