import {FormControl, MenuItem, TextField} from "@material-ui/core"
import React from "react"
import {TEvent} from "../../../types/types"
import {ContentAreaId} from "../../../validation/ValidPrimaryKey"
import { contentAreaStore } from "../../../app/common/contentArea/ContentAreaStore"

interface IComponentProps {
    onLanguageChanged: (selectedLanguage?: ContentAreaId) => void
    currentLanguage?: ContentAreaId
    disabled?: boolean
}

interface IComponentState {
    currentLanguage?: ContentAreaId
    errors: string[]
}

export default class LanguageSelectDropDown extends React.Component<IComponentProps, IComponentState> {
    state: IComponentState

    constructor(props: IComponentProps) {
        super(props)
        this.state = {
            currentLanguage: props.currentLanguage === null ? undefined : props.currentLanguage,
            errors: []
        }
    }

    componentDidUpdate(prevProps: IComponentProps) {
        if (this.props.currentLanguage !== prevProps.currentLanguage) {
            this.setState({
                currentLanguage: this.props.currentLanguage === null ? undefined : this.props.currentLanguage
            })
        }
    }


    handleChange = (event: TEvent) => {
        const currentLanguage = event.target.value !== "" ? new ContentAreaId(Number(event.target.value)) : undefined
        this.setState({currentLanguage})
        this.props.onLanguageChanged(currentLanguage)
    }

    render() {
        const errors = this.state.errors
        const currentLanguage = this.state.currentLanguage ? this.state.currentLanguage.value() : ""

        const selectLabelId = "language-dropdown"

        return (
            <FormControl>
                <div className="error" hidden={errors.length === 0}>
                    {errors.join(" ") || "placeholder"}
                </div>
                <TextField
                    id={selectLabelId}
                    select={true}
                    onChange={this.handleChange}
                    value={currentLanguage}
                    helperText="Language"
                    style={{width: 200}}
                    disabled={this.props.disabled}
                >
                    <MenuItem value={""}/>
                    {contentAreaStore?.contentAreas?.map((k) => {
                        return (
                            <MenuItem key={`contentareaid-${k.contentAreaId}`} value={Number(k.contentAreaId)} id={`contentareaid-${k.contentAreaId}`}>
                                {k.longDisplayName}
                            </MenuItem>
                        )
                    })}
                </TextField>
            </FormControl>
        )
    }
}
