/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {
    Box,
    Grid,
    makeStyles,
} from "@material-ui/core"
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import * as Sentry from "@sentry/browser"
import * as React from "react"
import { useEffect, useState } from "react"
import ProctorApi from "../../../../services/ProctorApi"
import { H2Text, H5Text, H6Text } from "../../../../styles/AvantTypography"
import { LoginDTO } from "../../../../types/types"
import { log } from "../../../../util/Logging"
import { TimeUtil } from "../../../../util/TimeUtil"
import { GroupInfo } from "../../../common/group/models/GroupInfo"
import { messageStore } from "../../../common/messages/MessageStore"
import { AvantButton } from "../../../generic-components/AvantButton"
import { AvantExpansionPanel } from "../../../generic-components/AvantExpansionPanel"
import { VerificientLogin } from "../../../roster/RosterSelectTake"
import { RosterDialog } from "./rostering/RosterDialog"
import { EmailRemindersDialog } from "./EmailRemindersDialog"
import { ReportGraphFilters } from "../../common/models/ReportGraphFilters"
import { reportsStore } from "../../common/stores/ReportStore"
import { PLACE_PRODUCT_ID, ProctorTypes } from "../../../../util/Constants";
import { UserType } from "../../../common/authentication/models/UserType"
import { productStore } from "../../../common/products/ProductStore"
import { STAMP_SIGN_LANGUAGE_PRODUCT_ID, APT_PRODUCT_ID } from "../../../../util/Constants"
import { PrintOptions } from "../../common/components/PrintViewContainer"
import { contentAreaStore } from "../../../common/contentArea/ContentAreaStore";
import {TeacherPermissions} from "../../../../components/admin-portal/DevTools/LoginTool";
import {authStore} from "../../../common/authentication/AuthStore";


const useStyles = makeStyles(theme => ({
    label: {
        marginBottom: 4
    }
}))

interface ProctorControlsProps {
    groupInfo?: GroupInfo
    reportQuery?: ReportGraphFilters
}

// The below array comes from the RemoteProctorType found in Common Constants.kt, string must match
// TODO: Make sure the below array doesnt have issues since I changed it to use the enums
const DIY_PROCTOR_TYPES_ARRAY: string[] = [
    // "eduproctor", "avantproctor", "proctor-edu", "avant-proctor-edu"
    ProctorTypes.P360.valueOf(), ProctorTypes.EDUPROCTOR.valueOf(), ProctorTypes.AVANTPROCTOR.valueOf(), ProctorTypes.EDUPROCTOR.valueOf()
]

export interface TestControlsContainerProps extends ProctorControlsProps {
    printOptions?: PrintOptions
}

export const TestControlsContainer = ({ printOptions, groupInfo, reportQuery }: TestControlsContainerProps) => {
    if (printOptions) return <></>

    return (
        <Grid item xs={12}>
            <TestControls groupInfo={groupInfo} reportQuery={reportQuery} />
        </Grid>
    )
}

function buildRegistraionLink(loginId: number | undefined): string {
    if (loginId) {
        const url = new URL(`/avantproctor-registration/${loginId}`, window.location.href)
        return url.href
    } else {
        return "-"
    }
}

function loginNotExpired(login: LoginDTO): boolean {
    const expirationDate = login.loginExpires ? TimeUtil.formatMilliToMonthDayYearLong(Number(login.loginExpires)) : null
    return expirationDate === null || new Date(expirationDate) > new Date() // login has not expired -- null means forever?
}
function passwordNotExpired(login: LoginDTO): boolean {
    const passwordExpirationDate = login.passwordExpires ? TimeUtil.formatMilliToMonthDayYearLong(Number(login.passwordExpires)) : null
    return passwordExpirationDate === null || new Date(passwordExpirationDate) > new Date() // password has not expired -- null means forever?
}

function loginActive(login: LoginDTO): boolean {
    return (login.id !== null && login.id !== undefined) && (login.active === true)
}

// TODO: This function should ideally use ContentAreaId to compare languages.
// Currently ContentAreaId for a district user returns incompatible results with student codes.
// It is actually more stable at present to parse the test codes as strings.
function languageMatches(login: LoginDTO) {
    const studentTestLanguageCode = login.username?.split("-")[2]
    const currentUserLanguageCode = authStore.auth?.userName?.split("-")[2]

    // If the user does not have a login language then it is probably a district (non-teacher) login. Show all languages rather than showing none.
    // Note: A district or admin account can masquerade as a teacher account, otherwise it would be safer to check UserType directly.
    // Also: UserType is currently not included in GroupInfo.
    return currentUserLanguageCode === null || currentUserLanguageCode === undefined || currentUserLanguageCode === studentTestLanguageCode
}

function permissionsToRoster() {
    return productStore.loginProduct?.permissions?.includes(TeacherPermissions.ROSTER.valueOf()) ?? false
}

function isProctored(login: LoginDTO) {
    return login.proctorType !== null && login.proctorType !== undefined
}

function isRostered(login: LoginDTO) {
    return login.rostered === true
}

function isRosteredWithPermissionsOrProctored(login: LoginDTO) {
    return (permissionsToRoster() && isRostered(login)) || isProctored(login)
}

// Sanity check since username is nullable
function hasUsername(login: LoginDTO) {
    return login.username !== null && login.username !== undefined && login.username.trim().length > 0
}

// TODO: Update findGroupInfo API call to include usertypes.
// Filter out non-student usertypes on the backend.
// Currently a report viewer can see data on every username associated with their group in the network tab.
// This is a security issue that needs to be resolved: https://avant-it.atlassian.net/browse/AVANT-3803
function isNotTeacherCode(login: LoginDTO) {
    // A code that ends in "-t" should correspond to a teacher code.
    // This is the most tried and true way we have right now of filtering out a teacher code.
    // Note: We cannot use UserType because it is nullable and not dependable.
    return !login.username?.trim().endsWith('-t')
}

export const TestControls: React.FC<ProctorControlsProps> = (props) => {
    const classes = useStyles()
    const [rosteredOrProctoredLogins, setRosteredOrProctoredLogins] = useState<LoginDTO[]>([])
    const [rosterOpen, setRosterOpen] = useState(false)
    const [emailRemindersOpen, setEmailRemindersOpen] = useState(false)
    const [proctorURL, setProctorURL] = useState<string | undefined>(undefined)
    const [selectedLogin, setSelectedLogin] = useState<LoginDTO | undefined>(undefined)

    useEffect(() => {

        // Only add rosteredOrProctoredLogins for editing to an admin, district, teacher or school account.
        switch (productStore.loginProduct?.userType) {
            case UserType.A.valueOf():
            case UserType.D.valueOf():
            case UserType.T.valueOf():
            case UserType.SC.valueOf():
                break
            default:
                setRosteredOrProctoredLogins([])
                return
        }

        // Get every login associated with the login group, and then filter to include only proctored or rostered logins that meet certain criteria.
        const allLoginsInTheGroup = props.groupInfo ? props.groupInfo.loginList : []
        const getRosteredOrProctoredLogins: LoginDTO[] = allLoginsInTheGroup.filter((login: LoginDTO) => {
            return hasUsername(login) &&
            loginNotExpired(login) &&
            passwordNotExpired(login) &&
            loginActive(login) &&
            isRosteredWithPermissionsOrProctored(login) &&
            languageMatches(login) &&
            isNotTeacherCode(login)
        }).sort((a, b) => {
            const aSortBy = (a.username) ? a.username.toLocaleUpperCase() : ""
            const bSortBy = (b.username) ? b.username.toLocaleUpperCase() : ""
            return aSortBy.localeCompare(bSortBy)
        })
        setRosteredOrProctoredLogins([...getRosteredOrProctoredLogins])
    }, [])

    // Used to generate and access proctor track proctoring
    // tslint:disable-next-line:max-line-length
    const verificientInstructorLogin = async (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>, loginId: number | undefined) => {
        event.preventDefault()
        if (!loginId) {
            return
        }

        // Below is what to do if avantproctor or eduproctor
        const currentState = [...rosteredOrProctoredLogins]
        const index = currentState.findIndex(login => login.id === loginId)
        if (index > -1) { // found
            const payload: VerificientLogin = {
                username: "",
                password: "",
                loginId,
                takeId: null
            }

            const login = currentState[index]

            log.info(`Login Proctor Type: ${login.proctorType}`)

            if (isProctored(login) && DIY_PROCTOR_TYPES_ARRAY.includes(login.proctorType ?? "")) {
                await ProctorApi.verificientProctorLogin(payload)
                    .then((proctorLoginReturn) => {
                        const redirectUrl = proctorLoginReturn.redirectUrl
                        const studentRosterErrors = proctorLoginReturn.studentRosterErrors
                        currentState[index].allowTest = true
                        setRosteredOrProctoredLogins([...currentState])
                        setProctorURL(redirectUrl)
                        if (studentRosterErrors.length > 0) {
                            const errorMessage = `The following people could not be rostered:\n${studentRosterErrors.join("\n")}`
                            messageStore.setErrorMessage(errorMessage)
                        } else {
                            messageStore.setInfoMessage("Testing Ready for Remote Proctoring")
                        }
                    })
                    .catch((e) => {
                        messageStore.setErrorMessage(`${e.message} - Please contact Avant Support if this error continues.`)
                        Sentry.captureException(`TestControls|verificientInstructorLogin: ${e.message}`)
                    })
            } else if (login.proctorType === DIY_PROCTOR_TYPES_ARRAY[2] || login.proctorType === DIY_PROCTOR_TYPES_ARRAY[3]) {
                // TODO: This is where init and set up teacher proctor JWT and set up session with Proctor EDU
                log.info(`I am in the if for the call to the endpoint`)
            }

        }

    }

    const handleRosterStudents = (login: LoginDTO | undefined) => {
        setSelectedLogin(login)
        setRosterOpen(true)
    }

    const handleRosterClose = () => {
        setRosterOpen(false)
        reportsStore.findReportTableData(props.reportQuery!)
    }

    const handleEmailReminders = (login: LoginDTO | undefined) => {
        setSelectedLogin(login)
        setEmailRemindersOpen(true)
    }

    const handleEmailRemindersClose = () => {
        setEmailRemindersOpen(false)
        reportsStore.findReportTableData(props.reportQuery!)
    }
    const linkCancelOrCopy = (linkStr: string | null) => {
        if (linkStr !== null) {
            navigator.clipboard.writeText(linkStr).then(() => {
                messageStore.setMessage({ message: "Copied....", type: 1 })
            }
            )
        }
    }
    const renderSchedulingLink = (login: LoginDTO) => {
        const { schedulingLink } = login
        if (schedulingLink) {
            return (
                <>
                    <Grid style={{ margin: 5, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} item={true} sm={12} md={3}>
                        <H6Text className={classes.label}>Scheduling Link:</H6Text>
                        <a href={schedulingLink}>{schedulingLink} </a>
                    </Grid>
                    <Tooltip title="Copy link">
                        <FileCopyIcon style={{ color: "#007bff" }}
                            onClick={() => {
                                linkCancelOrCopy(schedulingLink)
                            }}
                        />
                    </Tooltip>
                </>
            )
        } else {
            return (
                <Grid item={true} xs={12}>
                    <H5Text style={{ lineHeight: "58px" }}>Pending Proctor Setup</H5Text>
                </Grid>
            )
        }
    }
    const renderRegistrationLink = (login: LoginDTO) => {
        const RegistrationLink = buildRegistraionLink(login.id)
        if (RegistrationLink) {
            return (
                <>
                    <Grid style={{ margin: 5, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} item={true} sm={12} md={3}>
                        <H6Text className={classes.label}>Self-Register Link:</H6Text>
                        <a href={RegistrationLink}>{RegistrationLink} </a>
                    </Grid>
                    <Tooltip title="Copy link">
                        <FileCopyIcon style={{ color: "#007bff", marginRight: "30px" }}
                            onClick={() => {
                                linkCancelOrCopy(RegistrationLink)
                            }}
                        />
                    </Tooltip>
                </>
            )
        } else {
            return (
                <Grid item={true} xs={12}>
                    <H5Text style={{ lineHeight: "58px" }}>Pending Proctor Setup</H5Text>
                </Grid>
            )
        }
    }

    return (
        <>
            {(rosteredOrProctoredLogins.length > 0) && (
                <>
                    <AvantExpansionPanel title={"Test Controls"} >
                        <div style={{ padding: "20px" }}>

                            {rosteredOrProctoredLogins.map((login, key) => (
                                <Grid container={true} key={login.id} style={{ height: "100%" }} alignItems={"center"} spacing={2}>
                                    <Grid item={true} sm={4} md={3}>
                                        <H2Text>
                                            {login.username}
                                            {(login.contentAreaId && contentAreaStore.convertContentAreaIdToLanguageName(login.contentAreaId)) ? ` (${contentAreaStore.convertContentAreaIdToLanguageName(login.contentAreaId)})` : 'content area not found'}
                                        </H2Text>
                                    </Grid>

                                    {
                                        (login.proctorType === ProctorTypes.P360.valueOf() && productStore && productStore.driver && productStore.driver.productId.value() === PLACE_PRODUCT_ID.value())
                                            ?
                                            (!login.anyProctorScheduleIsAttached && login.proctorType === ProctorTypes.P360.valueOf() &&
                                                <Grid item={true} sm={4} md={3}>
                                                    <Box
                                                        display={"flex"}
                                                        justifyContent={"center"}
                                                        alignItems={"center"}
                                                    >
                                                        PENDING PROCTOR SETUP
                                                    </Box>
                                                </Grid>
                                            )
                                            :
                                            (login.proctorType !== ProctorTypes.P360.valueOf() ||
                                                (productStore && productStore.driver && productStore.driver.productId.value() !== STAMP_SIGN_LANGUAGE_PRODUCT_ID.value() &&
                                                    productStore && productStore.driver && productStore.driver.productId.value() !== APT_PRODUCT_ID.value())) &&
                                            (!login.anyProctorScheduleIsAttached && login.proctorType === ProctorTypes.P360.valueOf() &&
                                                <Grid item={true} sm={4} md={3}>
                                                    <Box
                                                        display={"flex"}
                                                        justifyContent={"center"}
                                                        alignItems={"center"}
                                                    >
                                                        PENDING PROCTOR SETUP
                                                    </Box>
                                                </Grid>
                                            )
                                    }
                                    <Grid item={true} sm={login.anyProctorScheduleIsAttached ? 8 : 4} md={login.anyProctorScheduleIsAttached ? 9 : 6} key={login.id}>
                                        <Grid container={true} alignItems={"center"}>
                                            {/*<Grid item={true} xs={3}>*/}
                                            {/*    <H5Text className={classes.label}>TEST ENTRY ACCESS:</H5Text>*/}
                                            {/*    <FormControlLabel*/}
                                            {/*        control={*/}
                                            {/*            <Switch*/}
                                            {/*                size="small"*/}
                                            {/*                checked={login.allowTest}*/}
                                            {/*                onChange={() => handleToggleLock(login.id)}*/}
                                            {/*            />*/}
                                            {/*        }*/}
                                            {/*        label={lockLabel(login.allowTest)}*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}

                                            {proctorURL &&
                                                <Grid item={true} xs={2}>
                                                    <Grid container={true} direction="row">
                                                        <Grid item={true} xs={12}>
                                                            <a href={proctorURL} target="_blank">Open Proctor System</a>
                                                        </Grid>
                                                        <Grid item={true} xs={12}>
                                                            <a
                                                                href="#"
                                                                onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                                                                    verificientInstructorLogin(e, login.id)}
                                                            >
                                                                Reload Students for Proctoring
                                                            </a>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                            {!(login.proctorType === ProctorTypes.P360.valueOf() && (
                                                productStore.driver?.productId.value() === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value() ||
                                                productStore.driver?.productId.value() === APT_PRODUCT_ID.value() ||
                                                productStore.driver?.productId.value() === PLACE_PRODUCT_ID.value())) && (

                                                    <Grid item={true} sm={12} md={6} style={{ maxWidth: 350, margin: 5 }}>
                                                        <Grid container={true} alignItems={"center"}>
                                                            {permissionsToRoster() && isRostered(login) &&
                                                                <AvantButton
                                                                    style={{ marginRight: 10 }}
                                                                    onClick={() => handleRosterStudents(login)}
                                                                >
                                                                    Roster Test Takers
                                                                </AvantButton>
                                                            }

                                                            {login.schedulingLink && login.anyProctorScheduleIsAttached &&
                                                                <AvantButton
                                                                    style={{ marginRight: 10 }}
                                                                    onClick={() => handleEmailReminders(login)}
                                                                >
                                                                    Email Reminders
                                                                </AvantButton>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            {login.proctorType == ProctorTypes.P360.valueOf() && renderRegistrationLink(login)}
                                            {login.anyProctorScheduleIsAttached && login.proctorType == ProctorTypes.P360.valueOf() && renderSchedulingLink(login)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </div>
                    </AvantExpansionPanel>
                    <RosterDialog
                        login={selectedLogin}
                        open={rosterOpen}
                        handleRosterClose={handleRosterClose}
                    />
                    <EmailRemindersDialog
                        login={selectedLogin}
                        open={emailRemindersOpen}
                        handleEmailRemindersClose={handleEmailRemindersClose}
                    />
                </>
            )}
        </>
    )
}
