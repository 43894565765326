/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid, IconButton, Paper, Table, TableContainer,
    TextField,
    TableCell, TableRow, TableBody, Typography, Toolbar, Tooltip, TableHead,
} from "@material-ui/core"
import * as React from "react"
import {ChangeEvent, Fragment, useState} from "react"
import RosterApi, {RosterStudentInfo} from "../../../../../services/RosterApi"
import {ErrorText, H4Text} from "../../../../../styles/AvantTypography"
import {LoginDTO} from "../../../../../types/types"
import {messageStore} from "../../../../common/messages/MessageStore"
import Button from "../../../../../components/Button/Button";
import {theme} from "../../../../../styles/MuiThemes";
import {AvantColors} from "../../../../../styles/AvantColors";
import {Add, Delete} from "@material-ui/icons";
import DeleteSweep from '@material-ui/icons/DeleteSweep';
import { contentAreaStore } from "../../../../common/contentArea/ContentAreaStore"

interface RosterDialogProps {
    login: LoginDTO | undefined,
    open: boolean,
    handleRosterClose: () => void
}
export const RosterDialog: React.FC<RosterDialogProps> = props => {
    const emptyStudent: RosterStudentInfo = {index: 0, firstName: "", lastName: "", loginName: "", errors: []}
    const [students, setStudents] = useState<RosterStudentInfo[]>([emptyStudent])
    const [index, setIndex] = useState(1)

    const handleOnClose = () => {
        setStudents([emptyStudent])
        props.handleRosterClose()
    }

    const handleAddStudent = () => {
        addStudent([emptyStudent])
    }

    const handleStudentTextOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const idx = event.target.dataset.idx
        const fieldName = event.target.dataset.field
        if (idx == null || fieldName == null) { return }

        const currentState = [...students]
        const foundIndex = currentState.findIndex(student => student.index === parseInt(idx, 10))
        if (foundIndex > -1) {
            currentState[foundIndex][fieldName] = event.target.value
            setStudents([...currentState])
        }
    }

    const handleProcessStudents = async () => {
        if (props.login && props.login.id) {
            const currentState = [...students]
            const results = await RosterApi.rosterGroup(props.login.id, currentState)
            setStudents([...results.students])
            // returns true if any of the students has errors
            const hasErrors = results.students.some(student => student.errors.length)
            if (!hasErrors) {
                messageStore.setInfoMessage(`${results.students.length} student(s) have been rostered.`)
                handleOnClose()
            }
        }
    }

    const handleOnClear = () => {
        setStudents([emptyStudent])
    }

    const handleStudentOnDelete =  (event: any) => {
        // All Material UI should support extra props sent to the base objects to the same place
        // the onClick and onPaste events are generated, BUT Icons do not yet support that.  The information we need is
        // on the parent node.
        if (event.target.parentNode == null) { return }
        const idx = event.target.parentNode.dataset.idx
        if (idx == null) { return }

        const currentState = [...students]
        const foundIndex = currentState.findIndex(student => student.index === parseInt(idx, 10))
        if (foundIndex > -1) {
            // filter for all but the one we are removing
            const filtered = currentState.filter((value, idx) => (idx !== foundIndex))
            if (filtered.length == 0) {
                setStudents([emptyStudent])
            } else {
                setStudents([...filtered])
            }
        }
    }

    // ANY is needed here because we are passing both an OnPaste from the DIV and from the InputElements, And the
    // combined signature will not pass the lint where the onPaste is used.
    // tslint:disable-next-line:no-any
    const handleOnPaste = (event: any) => {
        event.preventDefault()
        let processed = false

        if (event.clipboardData) {
            // Removing /n from the html to avoid multi regex which is slow.
            const htmlData = cleanField(event.clipboardData.getData("text/html"))

            // handle HTML based pastes first
            if (!processed && htmlData.match(/<tr>/gi)) {
                processed = processHtmlTrPaste(htmlData)
            }

            if (!processed && event.target && htmlData.match(/<td>/gi)) {
                // this can only be used on a html input element
                processed = processHtmlTdPaste(event, htmlData)
            }

            // handle text based pastes
            const copiedData = event.clipboardData.getData("text").trim()

            if (!processed && copiedData.match(/\t/)) {
                processed = processTabDelimitedPaste(copiedData)
            }

            if (!processed && event.target && copiedData && copiedData.length > 0) {
                // this can only be used on a html input element
                processPlainText(event.target, copiedData)
            }
        }
    }


    const addStudent  = (newStudents: RosterStudentInfo[], replaceBlank: boolean = false) => {
        const currentState = [...students]
        let currentIndex = index

        const lastRow = currentIndex - 1
        // Should we replace the preview blank row?
        if (replaceBlank && currentState[lastRow]
            && currentState[lastRow].firstName === ""
            && currentState[lastRow].lastName === ""
            && currentState[lastRow].loginName === "") {
            currentIndex = lastRow
            currentState.pop() // remove the last entry
        }

        // Add students to the new state
        for (const newStudentKey in newStudents) {
            const newStudentWithIndex = {...newStudents[newStudentKey], ...{index: currentIndex}}
            currentState.push(newStudentWithIndex)
            currentIndex++
        }
        setStudents([...currentState])
        setIndex(currentIndex)
    }

    const cleanField = (field: string): string => {
        return field.split("\n").join("").trim()
    }

    const clipboardToElements = (html: string): Element | null => {
        const template = document.createElement("template")
        // remove all newlines and other whitespace from the front and end
        template.innerHTML = cleanField(html)
        return template.content.firstElementChild
    }

    const processTabDelimitedPaste = (tabData: string): boolean => {
        const newStudents: RosterStudentInfo[] = []
        const lines = tabData.split("\n")
        for (const linesKey in lines) {
            const fields = lines[linesKey].split("\t")
            let fieldCount = 0
            const student = {...emptyStudent}
            for (const fieldsKey in fields) {
                const cleaned = cleanField(fields[fieldsKey])
                if (fieldCount === 0) {
                    student.firstName = cleaned
                } else if (fieldCount === 1) {
                    student.lastName = cleaned
                } else if (fieldCount === 2) {
                    student.loginName = cleaned
                }
                fieldCount++
            }
            newStudents.push(student)
        }
        if (newStudents.length > 0) {
            addStudent(newStudents, true)
            return true
        }
        return false

    }

    const processHtmlTrPaste = (htmlData: string): boolean => {
        // Is this a google sheet -- need to remove two bad elements so it can be parsed
        if (htmlData.match(/google-sheets-html-origin/gi)) {
            htmlData = htmlData.replace(/^<meta[^>]*>/gi, "")
            htmlData = htmlData.replace(/^google-sheets-html-origin/gi, "")
        }

        const newStudents: RosterStudentInfo[] = []
        const e = clipboardToElements(htmlData)
        if (e == null) { return false}

        const trs = e.getElementsByTagName("tr")
        if (trs.length > 0) {
            // We have 1 or more rows information to handle in the from of TRs
            for (const trsKey in trs) {
                if (trs[trsKey].getElementsByTagName("td").length > 0) {
                    const tds = trs[trsKey].getElementsByTagName("td")
                    let columnCount = 0
                    const student = {...emptyStudent}
                    for (const tdsKey in tds) {
                        const tdTest = tds[tdsKey].innerText || tds[tdsKey].textContent
                        if (tdTest && columnCount < 3) {
                            if (columnCount === 0) {
                                student.firstName = tdTest
                            } else if (columnCount === 1) {
                                student.lastName = tdTest
                            } else if (columnCount === 2) {
                                student.loginName = tdTest
                            }
                            columnCount++
                        }
                    }
                    newStudents.push(student)
                }
            }
            addStudent(newStudents, true)
            return true // successfully processed information
        }
        return false // we could not process

    }

    const processHtmlTdPaste = (target: HTMLInputElement, htmlData: string): boolean => {
        // We have a single cell and need to paste in the correct place
        const e = clipboardToElements(htmlData)
        if (e == null) {
            return false
        }

        const tds = e.getElementsByTagName("td")
        if (tds.length > 0) {
            for (const tdsKey in tds) {
                const textData = tds[tdsKey].innerText || tds[tdsKey].textContent
                if (textData && textData.length > 0) {
                    const idx = target.dataset.idx
                    const fieldName = target.dataset.field
                    if (idx == null || fieldName == null) { return false}
                    const currentState = [...students]
                    const foundIndex = currentState.findIndex(student => student.index === parseInt(idx, 10))
                    if (foundIndex > -1) {
                        currentState[foundIndex][fieldName] = cleanField(textData)
                        setStudents([...currentState])
                    }
                    return true // we only take the first one to past into the HTMLInputElement
                }
            }
        }
        return false
    }

    const processPlainText = (target: HTMLInputElement, textData: string) => {
        if (target) {
            const idx = target.dataset.idx
            const fieldName = target.dataset.field
            if (idx == null || fieldName == null) { return }
            const currentState = [...students]
            const foundIndex = currentState.findIndex(student => student.index === parseInt(idx, 10))
            if (foundIndex > -1) {
                currentState[foundIndex][fieldName] = cleanField(textData)
                setStudents([...currentState])
            }
        }
    }

    const testCode = props.login && props.login.username ? props.login.username : ""
    const language = props.login && props.login.contentAreaId ? contentAreaStore.convertContentAreaIdToLanguageName(props.login.contentAreaId) : "content area not found"

    return (
        <Dialog
            aria-labelledby={"roster-students"}
            aria-describedby={"roster-new-students"}
            open={props.open}
            onClose={handleOnClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            onPaste={handleOnPaste}
        >
            <DialogTitle disableTypography={true} style={{padding: theme.spacing(2), textAlign: "center"}}>
                <H4Text style={{fontWeight: 900, color: AvantColors.REPORT_FONT_COLOR_BLACK}}>Group Rostering - {testCode}: {language}</H4Text>
            </DialogTitle>
            <Divider style={{width: "100%", marginBottom: 10}}/>
            <DialogContent>
                <DialogContentText>
                    For each Test Taker, enter a First Name, Last Name, and Login Name that the Test Taker
                    will use to log into the test. Each field must be filled out and each Login Name must be unique.
                    Click the plus button (+) to add additional lines. Click PROCESS TEST TAKERS when you are ready
                    to roster the test takers and close the window. Click CANCEL to close the window without any changes.
                </DialogContentText>
                <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                    </Grid>
                </Grid>
                <Grid container={true} justifyContent="space-evenly" spacing={1} style={{marginTop: 10}}>
                    <Paper style={{borderRadius: "6px", border: "1px solid #CDCDCD", boxShadow: "none"}}>
                        <Toolbar style={{
                            paddingLeft: theme.spacing(2),
                            paddingRight: theme.spacing(1),
                            backgroundColor: "#F3F3F3",
                            borderRadius: "6px 6px 0 0"
                        }}>
                            <Typography style={{flex: '1 1 100%'}} color="inherit" variant="subtitle1" component="div">
                                Test Takers to Roster
                            </Typography>

                            <Tooltip title="Add Test Taker">
                                <IconButton
                                    aria-label="add"
                                    data-tst-id="add-student"
                                    onClick={handleAddStudent}
                                >
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                        <TableContainer style={{maxHeight: 375, borderRadius: 6}}>
                            <Table stickyHeader aria-label="simple table" style={{marginBottom: -1}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">First Name</TableCell>
                                        <TableCell align="left">Last Name</TableCell>
                                        <TableCell align="left">Login Name</TableCell>
                                        <TableCell align={"right"} padding="checkbox">
                                            <Tooltip title="Delete All Test Takers">
                                                <IconButton
                                                    data-tst-id="clear-all"
                                                    onClick={handleOnClear}>
                                                    <DeleteSweep/>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            <TableBody>
                            {students.map((student, key) => (
                                <Fragment key={key}>
                                    <RosterLine
                                        student={student}
                                        onChange={handleStudentTextOnChange}
                                        onDelete={handleStudentOnDelete}
                                        onPaste={handleOnPaste}
                                    />
                                </Fragment>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Paper>
                    <Grid item={true} xs={12}/>
                </Grid>
                <Grid container={true} justifyContent="center" spacing={2} style={{marginBottom: 10, marginTop: 25}}>
                    <Grid item={true} xs={7}>
                        <Button
                            className="avant-button--primary avant-button--block avant-button--default"
                            block={true}
                            testId="process-students"
                            onClick={handleProcessStudents}
                        >
                            PROCESS TEST TAKERS
                        </Button>
                    </Grid>
                    <Grid item={true} xs={4}>
                        <Button
                            className="avant-button--secondary avant-button--block avant-button--default"
                            block={false}
                            testId="cancel-students"
                            onClick={handleOnClose}
                        >
                            CANCEL
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

interface RosterLineProps {
    student: RosterStudentInfo
    onChange: (event: any) => void
    onDelete: (event: any) => void
    onPaste: (event: any) => void
}
export const RosterLine: React.FC<RosterLineProps> = props => (
    <>
        <TableRow key={props.student.index}>
            <TableCell component="th" scope="row">
                <TextField
                    type="text"
                    placeholder="First Name"
                    value={props.student.firstName}
                    onChange={props.onChange}
                    onPaste={props.onPaste}
                    inputProps={{
                        "data-idx": props.student.index,
                        "data-field" : "firstName"
                    }}
                />
                <ErrorText aria-label={"Errors"}>{props.student.errors[0]}</ErrorText>
            </TableCell>
            <TableCell align="right">
                <TextField
                    type="text"
                    placeholder="Last Name"
                    value={props.student.lastName}
                    onChange={props.onChange}
                    onPaste={props.onPaste}
                    inputProps={{
                        "data-idx": props.student.index,
                        "data-field" : "lastName"
                    }}
                />
                <ErrorText aria-label={"Errors"}>{props.student.errors[1]}</ErrorText>
            </TableCell>
            <TableCell align="right">
                <TextField
                type="text"
                placeholder="Login Name"
                value={props.student.loginName}
                onChange={props.onChange}
                onPaste={props.onPaste}
                inputProps={{
                    "data-idx": props.student.index,
                    "data-field" : "loginName"
                }}
            />
            <ErrorText aria-label={"Errors"}>{props.student.errors[2]}</ErrorText>
            </TableCell>
            <TableCell align={"right"} padding="checkbox">
                <Tooltip title="Delete Test Taker">
                    <IconButton onClick={props.onDelete}>
                        <Delete
                            role={"button"}
                            aria-label={"Delete Row"}
                            aria-hidden={"false"}
                            data-idx={props.student.index}
                        />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    </>
)
