import {AxiosError, AxiosResponse} from "axios";

export function axiosErrorHumanReadable(error: AxiosError): string {
    let errorNumberMessageComponent = ""
    let errorTextMessageComponent = ""

    if (error.response) {
        const errorResponse: AxiosResponse = error.response

        errorNumberMessageComponent = `${errorResponse.status}: `

        if (errorResponse.data.error) {
            errorTextMessageComponent = errorResponse.data.error
        } else if (errorResponse.data.message) {
            errorTextMessageComponent = errorResponse.data.message
        } else if (errorResponse.data.errors) {
            errorTextMessageComponent = errorResponse.data.errors.join("; ")
        } else if (errorResponse.data.detail) {
            errorTextMessageComponent = errorResponse.data.detail
        } else if (errorResponse.data.kind) {
            errorTextMessageComponent = errorResponse.data.kind
        }
    }
    else if (error.message) {
        errorTextMessageComponent = error.message
    }

    return `${errorNumberMessageComponent}${errorTextMessageComponent}`
}
