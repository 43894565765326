export enum Env {
    LOCAL_DEV = "local-dev",
    DEV = "dev",
    STAGING = "staging",
    PROD = "prod"
}

export class EnvUtils {

    /**
     * Returns PROD if the environment is not dev. If in the future we need special behavior based on environments other than dev
     * we may want to create additional options.
     */
    static current = () => {
        if (window.location.host.includes("localhost")) {
            return Env.LOCAL_DEV
        } else if (window.location.host.includes("appv2") || window.location.host.includes("dev")) {
            return Env.DEV
        } else if (window.location.host.includes("app-stg") || window.location.host.includes("staging")) {
            return Env.STAGING
        } else {
            return Env.PROD
        }
    }

    static isDev = () => EnvUtils.current() === Env.DEV || EnvUtils.current() === Env.LOCAL_DEV
    static isProd = () => EnvUtils.current() === Env.PROD
}
