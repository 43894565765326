/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {Button, Checkbox, Link, List, ListItem, ListItemText, Typography,} from "@mui/material"
import MaterialTable, {Column} from "material-table"
import moment from "moment"
import * as React from "react"
import {RefObject, useRef, useState} from "react"
import {messageStore} from "../../../app/common/messages/MessageStore"
import {VerificientLogin} from "../../../app/roster/RosterSelectTake"
import ApiService from "../../../services/ApiService"
import ProctorApi from "../../../services/ProctorApi"
import {ApiErrorResponse, ApiLogin, P360Scheduling} from "../../../types/types"
import {ProctorTypes} from "../../../util/Constants"
import AvantDatePicker from "../../form-components/AvantDatePicker/AvantDatePicker"
import {TeacherPermissions, TestTakerPermissions} from "./LoginTool"
import {UserType} from "../../../app/common/authentication/models/UserType"
import { contentAreaStore } from "../../../app/common/contentArea/ContentAreaStore"

interface LoginRowData extends ApiLogin {
    _links: { self: { href: string } }
    tableData: { id: number }
    ecwidSku: string
    proctorLink: string
    diyLink: string
    scheduleNames: string[]
}

export interface LoginExamData {
    schedules: string[]
}

function buildSort(orderByField: string | unknown, orderDirection: string): string {
    let sortString = ""
    if (orderByField) {
        sortString = `&sort=${orderByField},${orderDirection}`
    }
    return sortString
}

function buildPredicate(filters: any): string {
    const predicateArray: string[] = []
    filters.forEach((filter: any) => {
        if (filter.column.field === "username") {
            predicateArray.push(`username=contains(${filter.value})`)
        }
        if (filter.column.field === "userType") {
            const types = filter.value.split(",")

            types.forEach((type: string) => {
                predicateArray.push(`userType=${type}`)
            })
        }
        if (filter.column.field === "proctorType") {
            predicateArray.push(`proctorType=contains(${filter.value})`)
        }
        if (filter.column.field === "rostered") {
            const value = (filter.value === "checked")
            predicateArray.push(`rostered=${value}`)
        }
        if (filter.column.field === "handwritten") {
            const value = (filter.value === "checked")
            predicateArray.push(`handwritten=${value}`)
        }
        if (filter.column.field === "active") {
            const value = (filter.value === "checked")
            predicateArray.push(`isActive=${value}`)
        }
        if (filter.column.field === "allowTest") {
            const value = (filter.value === "checked")
            predicateArray.push(`allowTest=${value}`)
        }
        if (filter.column.field === "contentAreaId") {
            // Filter languages based on what the user is typing in the language field.
            const contentAreaIds: string[] = []

            // Language in the foreach is an array containing a contentareaid and it's language name.
            Object.entries(contentAreaStore.convertContentAreaIdToLanguageName).forEach((language: [string, string]) => {
                if (language[1].toLowerCase().includes(filter.value.toLowerCase())) {
                    contentAreaIds.push(language[0])
                }
            })

            contentAreaIds.forEach((contentAreaId: string) => predicateArray.push(`contentAreaId=${contentAreaId}`))
        }
    })
    if (predicateArray.length === 0) {
        return ""
    }
    return `&${predicateArray.join("&")}`
}

function replaceToInsertOppCode(match: string, p1: string, offset: number, s: string): string {
    return `vpp-${p1}`
}
function replaceToInsertOppCodeForAvantProctor(match: string, p1: string, offset: number, s: string): string {
    return `vpr-${p1}`
}
function isProctored(proctorType: string): boolean {
    if (proctorType === ProctorTypes.PROCTORTRACK.valueOf()
        || proctorType === ProctorTypes.AVANTPROCTOR.valueOf()
        || proctorType === ProctorTypes.EDUPROCTOR.valueOf()
        || proctorType === ProctorTypes.AP_24_7.valueOf()
        || proctorType === ProctorTypes.P360.valueOf()
        
        ) {
        return true
    } else {
        return false
    }
}

function isDIY(proctorType: string): boolean {
    if (proctorType === ProctorTypes.AVANTPROCTOR.valueOf()
        || proctorType === ProctorTypes.EDUPROCTOR.valueOf()) {
        return true
    } else {
        return false
    }
}
function isProctors(proctorType: string): boolean {
    if (proctorType === ProctorTypes.PROCTORTRACK.valueOf()
        || proctorType === ProctorTypes.EDUPROCTOR.valueOf()
        || proctorType === ProctorTypes.AP_24_7.valueOf()
        ) {
        return true
    } else {
        return false
    }
}

function buildSKU(rowData: LoginRowData): string {
    const proctortype: string = rowData.proctorType!
    const testCode: string = rowData.username
    const rowId: string = getIdFromSelf(rowData._links.self.href)
    if  (isProctored(proctortype) && testCode && rowId) {
       let oppCode:string=""
        if(proctortype=="P360"){
             oppCode = testCode.replace(/^([^-]*).*/, replaceToInsertOppCodeForAvantProctor)
            return `${oppCode}-${rowId}`
        }else{
             oppCode = testCode.replace(/^([^-]*).*/, replaceToInsertOppCode)
            return `${oppCode}-${rowId}`
        }
    } else {
        return "-"
    }
}

function buildRegistraionLink(rowData: LoginRowData): string {
    const proctortype: string = rowData.proctorType!
    const rowId: string = getIdFromSelf(rowData._links.self.href)
    if  (proctortype === ProctorTypes.AVANTPROCTOR.valueOf() || proctortype === ProctorTypes.P360.valueOf()){
        const url = new URL(`/avantproctor-registration/${rowId}`, window.location.href)
        return url.href
    } else if (isProctors(proctortype) && rowId) {
        const url = new URL(`/proctor-registration/${rowId}`, window.location.href)
        return url.href
    } else {
        return "-"
    }
}

function buildDIYLink(rowData: LoginRowData): string {
    const proctortype: string = rowData.proctorType!
    const rowId: string = getIdFromSelf(rowData._links.self.href)
    if (isDIY(proctortype) && rowId) {
        const url = new URL(`/proctored/${rowId}`, window.location.href)
        return url.href
    } else {
        return "-"
    }
}

async function verificientInstructorLogin(rowData: LoginRowData) {
    const loginId = Number(getIdFromSelf(rowData._links.self.href))
    const payload: VerificientLogin = {
        username: rowData.username,
        password: rowData.password!,
        loginId: loginId
    }
    const { redirectUrl } = await ProctorApi.verificientInstructorLogin(payload)
    window.open(redirectUrl, "_blank")
}

function getIdFromSelf(selfLink: string): string {
    if (selfLink) {
        const parts = selfLink.split("/")
        return parts[parts.length - 1]
    } else {
        return ""
    }
}


function getEncryptedPassword(rowData: LoginRowData, tableRef: RefObject<MaterialTable<ApiLogin>>): void {
    ApiService.getEncryptedPassword(rowData.loginUuid!).then((encryptedPassword: string) => {
        // Update the encrypted password for this specific row
        if (tableRef.current) {
            const tableRefCurrent: any = tableRef.current as any
            const data = tableRefCurrent.dataManager.getRenderState().data as LoginRowData[]
            data[rowData.tableData.id].encPassword = encryptedPassword

            tableRefCurrent.onQueryChange()
        }
    }).catch((error: ApiErrorResponse) => error.message && messageStore.setErrorMessage(error.message))
}

interface Props {
    handleSelectionChange: (rows: {}[]) => void,
    tableRef: RefObject<MaterialTable<ApiLogin>>
    schedules: P360Scheduling[]
}

const LoginTableBase: React.FC<Props> = ({handleSelectionChange, tableRef, schedules}) => {
    const [queryHistory, setQueryHistory] = useState({})

    async function fetchLogins(query: any) {
        const queryParams = {
            filters: query.filters,
            orderBy: query.orderBy,
            orderDirection: query.orderDirection,
            page: query.page,
            pageSize: query.pageSize,
            search: query.search,
        }

        // Return the same table data without hitting the API if the query hasn't changed
        // This happens when the user wants to update a single row
        // Solution found here - https://stackoverflow.com/a/63735924
        if (JSON.stringify(queryParams) === JSON.stringify(queryHistory)) {
            if (tableRef.current) {
                const tableRefCurrent = tableRef.current as any
                const data = tableRefCurrent.dataManager.getRenderState().data as LoginRowData[]

                return {
                    data: data,
                    page: tableRefCurrent.state.query.page,
                    totalCount: tableRefCurrent.state.query.totalCount,
                }
            }

        }

        setQueryHistory(queryParams)

        // Need to add data to the table, you need to do it here if it is not directly in the Login table, like scheduls....
        const sort = (queryParams.orderBy) ? buildSort(queryParams.orderBy.field, queryParams.orderDirection) : ""
        const predicate = (queryParams.filters) ? buildPredicate(queryParams.filters) : ""

        const response = await ApiService.get(`${ApiService.API_URL}rest/logins?page=${queryParams.page}&size=${queryParams.pageSize}${sort}${predicate}`)
        const addExtraFields: LoginRowData[] = response.data._embedded.logins.map((value: LoginRowData) => {
            let hasSchedule: boolean = false
            if (value.loginExamData && value.loginExamData.schedules !== null) {
                hasSchedule = true
            }
            const ecwidSku = buildSKU(value)
            const proctorLink = buildRegistraionLink(value)
            const diyLink = buildDIYLink(value)
            const scheduleNames = hasSchedule ? createScheduleList(value.loginExamData!.schedules, [...schedules]) : ["none"]
            return {...value, ecwidSku, proctorLink, diyLink, scheduleNames}
        })

        // console.log("addExtraFields",addExtraFields)

        return {
            data: addExtraFields,
            page: response.data.page.number,
            totalCount: response.data.page.totalElements
        }
    }

    const isCanceled = useRef(false)

    const patchRow = (patchLink: string, data: {}, id?: number) => {
        ApiService.patch(patchLink, data)
            .then(_ => {
                if (!isCanceled.current) {
                    if (tableRef.current) {
                        const tableRefCurrent = tableRef.current as any

                        // Update the row in the current table data, so we don't have to call the API when re-rendering the table
                        const tableData = tableRefCurrent.dataManager.getRenderState().data as LoginRowData[]

                        Object.entries(data).forEach(([key, value]) => {
                            tableData[id!][key] = value
                        })

                        tableRefCurrent.onQueryChange()
                        messageStore.setInfoMessage("Updated Row")
                    }
                }
            })
            .catch(e => {if (!isCanceled.current) {messageStore.setErrorMessage(e.toString())}})
    }

    const setPermissions = (rowData: LoginRowData, permission: string): void => {
        const permissions = rowData.permissions ? rowData.permissions : []
        const index = permissions.indexOf(permission)

        // "Toggles" a permission. If the passed permission is not in the permissions array then add it,
        // If it is in the array then remove it.
        if (index === -1) {
            permissions.push(permission)
        } else {
            permissions.splice(index, 1)
        }

        patchRow(rowData._links.self.href, {permissions: permissions.length ? permissions : null}, rowData.tableData.id)
    }


    const initialColumns: Column<any>[] = [
        { title: "User Name/Test Code", field: "username", defaultSort: "asc", removable: false},
        {
            title: "Language",
            field: "contentAreaId",
            width: "200px",
            align: "center",
            render: (rowData: LoginRowData) => contentAreaStore.convertContentAreaIdToLanguageName(rowData.contentAreaId),
        },
        {
            title: "User Type",
            field: "userType",
            hiddenByColumnsButton: true,
            hidden: false,
            removable: true,
            defaultFilter: "S",
            width: "100px",
            align: "center"
        },
        {
            title: "Active",
            field: "active",
            type: "boolean" ,
            removable: true,
            hiddenByColumnsButton: true,
            hidden: false,
        },
        {
            title: "Password",
            removable: true,
            hiddenByColumnsButton: true,
            hidden: false,
            render: (rowData: LoginRowData) => {
                const { encPassword } = rowData
                if (encPassword) {
                    return encPassword
                }

                return <Button variant={"contained"} color={"primary"} onClick={() => getEncryptedPassword(rowData, tableRef)}>Show Password</Button>
            }
        },
        { title: "Rostered",
            field: "rostered" ,
            type: "boolean",
            removable: true,
            hiddenByColumnsButton: true,
            hidden: false,
            render: (rowData: LoginRowData) =>
                <Checkbox
                    checked={rowData.rostered}
                    onChange={(e) => {
                        patchRow(
                            rowData._links.self.href,
                            {rostered: e.target.checked},
                            rowData.tableData.id
                        )
                    }}
                />
        },
        { title: "VoiceRecorder", field: "voiceRecorder" , removable: true, hiddenByColumnsButton: true, hidden: true },
        {
            title: "Handwritten",
            field: "handwritten" ,
            type: "boolean",
            removable: true,
            hiddenByColumnsButton: true,
            hidden: false,
            render: (rowData: LoginRowData) =>
                <Checkbox
                    checked={rowData.handwritten}
                    onChange={(e) => {
                        patchRow(rowData._links.self.href, {handwritten: e.target.checked}, rowData.tableData.id)
                    }}
                />

        },
        {
            title: "Allow Test",
            field: "allowTest",
            type: "boolean",
            removable: true,
            hiddenByColumnsButton: true,
            hidden: true,
            render: (rowData: LoginRowData) =>
                <Checkbox
                    defaultChecked={rowData.allowTest}
                    onChange={(e) => {
                        patchRow(rowData._links.self.href, {allowTest: e.target.checked}, rowData.tableData.id)
                    }}
                />
        },
        {
            title: "Proctor",
            field: "proctorType",
        },
        { title: "ECWID SKU", field: "ecwidSku" , removable: true, hiddenByColumnsButton: true, hidden: false},
        // { title: "Registration URL", field: "proctorLink" , removable: true, hiddenByColumnsButton: true, hidden: true},
        { title: "Registration URL", field: "proctorLink"},
        { title: "Student DIY URL", field: "diyLink" , removable: true, hiddenByColumnsButton: true, hidden: false},
        {
            title: "Instructor Login",
            removable: true,
            hiddenByColumnsButton: true,
            hidden: false,
            render: (rowData: LoginRowData) => {
                if (isProctored(rowData.proctorType!)) {
                    return <Button variant="contained" color="primary" onClick={() => verificientInstructorLogin(rowData)}>Login</Button>
                } else {
                    return ""
                }
            }
        },
        {
            title: "Scheduling Link",
            field: "schedulingLink",
            hiddenByColumnsButton: true,
            hidden: false,
            removable: true,
            render: (rowData: LoginRowData) => {
                if (rowData.schedulingLink) {
                    return <Link href={rowData.schedulingLink} target={"_blank"}>{rowData.schedulingLink}</Link>
                } else {
                    return ""
                }
            }

        },
        { title: "CreationDate", field: "createDate" , type: "date", removable: true, hidden: true},
        { title: "Last Touched", field: "lasttouched" , type: "date", removable: true, hidden: true},
        { title: "Password Expires", field: "passwordexpires" , type: "date", removable: true, hiddenByColumnsButton: true, hidden: true},
        {
            title: "Login Expires",
            field: "loginexpires" ,
            type: "date",
            removable: true,
            hiddenByColumnsButton: true,
            hidden: false,
            searchable: false
        },
        {
            title: "Test Open Date",
            field: "testOpenDate",
            removable: true,
            hiddenByColumnsButton: false,
            hidden: false,
            editable: "always",
            render: (rowData: LoginRowData) => {
                return(
                    <AvantDatePicker
                        pickerLabel={"Test Open Date"}
                        currentDate={rowData.testOpenDate ? moment(rowData.testOpenDate) : undefined}
                        onDateChanged={(e) => patchRow(rowData._links.self.href, {testOpenDate: e.toDate()}, rowData.tableData.id)}
                    />
                )
            }
        },
        {
            title: "Test Close Date",
            field: "testCloseDate",
            removable: true,
            hiddenByColumnsButton: true,
            hidden: false,
            editable: "always",
            render: (rowData: LoginRowData) => {
                return(
                    <AvantDatePicker
                        pickerLabel={"Test Close Date"}
                        currentDate={rowData.testCloseDate ? moment(rowData.testCloseDate) : undefined}
                        onDateChanged={(e) => patchRow(rowData._links.self.href, {testCloseDate: e.toDate()}, rowData.tableData.id)}
                    />
                )
            }
        },
        {
            title: "defaultGroup",
            field: "defaultGroup.name" ,
            removable: true,
            hiddenByColumnsButton: true,
            hidden: false
        },
        {
            title: "Assigned Schedules",
            field: "scheduleNames",
            searchable: false,
            removable: true,
            hiddenByColumnsButton: true,
            hidden: false,
            render: (rowData: LoginRowData) => {
                return (
                    rowData.scheduleNames.map((schedName: string, index: number) => {
                        if (schedName != null) {
                            return (<Typography display={"block"} key={`${index}-${schedName}-key`}>{schedName}</Typography>)
                        } else {
                            return ""
                        }

                    })
                )
            }
        },
        {
            title: "Permissions",
            field: "permissions",
            removable: true,
            hiddenByColumnsButton: true,
            hidden: false,
            render: (rowData: LoginRowData) => {
                const listItems: JSX.Element[] = []
                const rowPermissions: string[] = rowData.permissions ? rowData.permissions : []

                if (rowData.userType === UserType.S) {
                    // Cycle through the TestTakerPermissions and determine if the current row has each permission
                    Object.values(TestTakerPermissions).map((permission: string) => {
                        listItems.push(
                            <ListItem
                                key={permission}
                                button={true}
                                onClick={() => setPermissions(rowData, permission)}
                            >
                                <Checkbox
                                    checked={rowPermissions.indexOf(permission) > -1}
                                />
                                <ListItemText primary={permission} />
                            </ListItem>
                        )
                    })
                }

                if (rowData.userType === UserType.T) {
                    // Cycle through the TeacherPermissions and determine if the current row has each permission
                    Object.values(TeacherPermissions).map((permission: string) => {
                        listItems.push(
                            <ListItem
                                key={permission}
                                button={true}
                                onClick={() => setPermissions(rowData, permission)}
                            >
                                <Checkbox
                                    checked={rowPermissions.indexOf(permission) > -1}
                                />
                                <ListItemText primary={permission}/>
                            </ListItem>
                        )
                    })
                }

                return <List>{listItems}</List>
            }
        },
    ]

    const createScheduleList = (scheduleIds: string[] | null, dataArray: P360Scheduling[]): string[] => {

        const resultArray: string[] = []


        if (scheduleIds != null) {
            scheduleIds.forEach(schedId => {
                let curSched: P360Scheduling | undefined = dataArray.find(i => i.p360SchedulingId === schedId)
                if (curSched != null) {
                    resultArray.push(curSched.title)
                }
                // Why? THis is beacuse on the forEach loop the curSched was still pointing at old value
                curSched = undefined
            })
        }

        return resultArray
    }

    const [columns] = useState(initialColumns)

    return (
        <MaterialTable
            tableRef={tableRef}
            title="Logins"
            columns={columns}
            data={fetchLogins}
            options={{
                // fixedColumns: {
                //     left: 1,
                //     right: 1
                // },
                doubleHorizontalScroll: true,
                selection: true,
                filtering: true,
                // filterCellStyle: {},
                search: false,
                showTitle: true,
                toolbar: true,
                padding: "dense",
                pageSize: 100,
                pageSizeOptions: [100, 200, 500, 1000],
                exportButton: true,
                columnsButton: true,
                debounceInterval: 500,
                exportFileName: "LoginData",
            }}
            onSelectionChange={handleSelectionChange}
        />
    )
}

export const LoginTable = React.memo(LoginTableBase)
